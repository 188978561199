/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Inputs from "../../../components/Inputs";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { addArticle, getArticle, editArticle, deleteArticle, getUserAutoComplete } from "../../../actions";
import { history } from "../../../config/stores";
import './styles.scss';

const customStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#0083E5", // Custom colour
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#0083E5" : "white",
    padding: 10,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: (window.innerWidth / 100) * 94.2,
    height: 40,
    display: "flex",
    border: "1px solid rgb(165, 164, 164)",
    borderRadius: "7px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const GroupOptions = [
  {
    label: "Owners",
    value: "Owners",
  },
  {
    label: "Landlords With Tenant",
    value: "Landlords With Tenant",
  },
  {
    label: "Landlords Without Tenant",
    value: "Landlords Without Tenant",
  },
  {
    label: "Tenants With Landlord",
    value: "Tenants With Landlord",
  },
  {
    label: "Tenants Without Landlord",
    value: "Tenants Without Landlord",
  },
  {
    label: "Users Without Property",
    value: "Users Without Property",
  },
];

const defaultEditorStyles = {
  margin: "10px 0",
  borderColor: "rgb(165, 164, 164)",
  borderRadius: "6px",
  padding: "1px",
  fontSize:"18px",
  height: "95%"
};

const AddArticle = ({
  article,
  addArticle,
  getArticle,
  editArticle,
  deleteArticle,
  getUserAutoComplete,
  autoCompleteUsers,
}) => {
  const { articleId } = useParams()
  const [selLanguage, setSelLanguage] = useState("bg");
  const [firstLoad, setFirstLoad] = useState(false);
  const [edit, setEdit] = useState(false);
  const { register, control, watch, getValues, handleSubmit, setValue, formState: { errors } } = useForm({
    defaultValues: {
      title: {
        en: "",
        bg: "",
        de: "",
        it: ""
      },
      text: {
        en: "",
        bg: "",
        de: "",
        it: ""
      },
      coverPhoto: "",
      //leadToAction: false,
    },
  });

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast.error("Missing info", {
        autoClose: 3000,
      });
    }
  }, [errors]);

  useEffect(() => {
    getUserAutoComplete();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (articleId) {
      getArticle({ articleId })
      setEdit(true)
    }
  }, [articleId]);

  useEffect(() => {
    if (articleId && article && Object.keys(article)?.length) {
      setValue('title', article?.title, { shouldValidate: true })
      setValue('text', article?.text, { shouldValidate: true })
      setValue('coverPhoto', article?.coverPhoto, { shouldValidate: true })
    }
  }, [article]);

  console.log(getValues())

  return (
    <div className="main-container article">
      <div className="inner-header-container">
        <div className="left-part">
          <div className="close-icon" onClick={() => history.push("/website")} />
          {edit ?
          <h2 className="inner-title">Edit article</h2> :
          <h2 className="inner-title">Add new article</h2>}
        </div>
        {edit ?
        <>
            <Inputs.Button
              text="DELETE"
              style={{ width: "10%", marginRight: "10px" }}
              className={"delete"}
              onClick={handleSubmit((e) => {
                const payload = { articleId };

                console.log(payload)
                deleteArticle({
                  ...payload,
                  onSuccess: () => {
                    toast.success("Article deleted successfully.");
                    history.push("/website");
                  },
                });
                 // deleteArticle({ articleId })
                // history.push('/website')
              })}
            />
          <Inputs.Button
              text="SAVE"
              style={{ width: "10%", marginRight: "10px" }}
              selected
              onClick={handleSubmit((e) => {
                const payload = {
                  ...article,
                  title: { ...e.title },
                  text: { ...e.text },
                  coverPhoto: e.coverPhoto
                };
                console.log(payload)

                editArticle({
                  ...payload,
                  onSuccess: () => {
                    toast.success("Article edited successfully.");
                    history.push("/website");
                  },
                });
              })}
            />
        </> :
        <Inputs.Button
          text="ADD"
          style={{ width: "8%" }}
          selected
          onClick={handleSubmit((e) => {
            const payload = {
              title: { ...e.title },
              text: { ...e.text },
              coverPhoto: e.coverPhoto
            };
            console.log(payload)
            addArticle({
              ...payload,
              onSuccess: () => {
                toast.success("New article added successfully.");
                history.push("/website");
              },
            });
          })}
        />}
      </div>

      <div className="body-container">
        <div className="languages-container">
          <Inputs.Button
            text="ENGLISH"
            style={{ width: "23%" }}
            selected={selLanguage === "en"}
            onClick={() => setSelLanguage("en")}
          />
          <Inputs.Button
            text="BULGARIAN"
            style={{ width: "23%" }}
            selected={selLanguage === "bg"}
            onClick={() => {
              setSelLanguage("bg");
            }}
          />
          <Inputs.Button
            text="GERMAN"
            style={{ width: "23%" }}
            selected={selLanguage === "de"}
            onClick={() => {
              setSelLanguage("de");
            }}
          />
          <Inputs.Button
            text="ITALIAN"
            style={{ width: "23%" }}
            selected={selLanguage === "it"}
            onClick={() => {
              setSelLanguage("it");
            }}
          />
        </div>

        <div className="flex-container">
            <Inputs.TextInput
              label={"Title"}
              style={{ display: selLanguage === "en" ? "block" : "none" }}
              compulsory
              className={errors.title?.en && "failed"}
              {...register(`title.en`, { required: true })}
            />
            <Inputs.TextInput
              label={"Title"}
              style={{ display: selLanguage === "bg" ? "block" : "none" }}
              compulsory
              className={errors.title?.bg && "failed"}
              {...register(`title.bg`, { required: true })}
            />
            <Inputs.TextInput
              label={"Title"}
              style={{ display: selLanguage === "de" ? "block" : "none" }}
              compulsory
              className={errors.title?.de && "failed"}
              {...register(`title.de`, { required: true })}
            />
            <Inputs.TextInput
              label={"Title"}
              style={{ display: selLanguage === "it" ? "block" : "none" }}
              compulsory
              className={errors.title?.it && "failed"}
              {...register(`title.it`, { required: true })}
            />
    
            <Controller
                control={control}
                name={"coverPhoto"}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Inputs.ImageInput
                    label={"Article photo"}
                    compulsory
                    innerWidth="100%"
                    value={value}
                    onChange={onChange}
                    setValue={setValue}
                    className={error ? "failed" : value ? "photo" : ""}
                    photo
                  />
                )}
                rules={{ required: true }}
              />
        </div>

        {(edit && selLanguage === 'bg' && (firstLoad || getValues('text.bg'))) && 
        <Controller
          control={control}
          name={"text.bg"}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className="input-container" style={{ display: selLanguage === "bg" ? "block" : "none", height: "75%" }}>
            <label style={{ display: "flex", width: "100%", fontSize: "1rem" }} className="light">
              Article<span style={{ paddingLeft: "5px", color: "black" }}>*</span>
            </label>
            <ReactQuill
            value={getValues('text.bg')}
            style={{ ...defaultEditorStyles, borderColor: error ? "red" : "rgb(165, 164, 164)" }}
            className={error && "failed"}
            compulsory
            onChange={(e) => {
              setValue("text.bg", e)
              if (!firstLoad) setFirstLoad(true)
              }} />
            </div>
          )}
          rules={{ required: true }}
        />}

        {!edit && 
        <Controller
          control={control}
          name={"text.bg"}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Inputs.RichText
              label={"Article"}
              style={{ display: selLanguage === "bg" ? "block" : "none", height: "77%" }}
              value={value}
              onChange={(e) => {
                setValue("text.bg", e);
              }}
              className={error && "failed"}
              compulsory
            />
          )}
          rules={{ required: true }}
        />}

      {(edit && selLanguage === 'en' && getValues('text.en')) && 
        <Controller
          control={control}
          name={"text.en"}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className="input-container" style={{ display: selLanguage === "en" ? "block" : "none", height: "75%" }}>
            <label style={{ display: "flex", width: "100%", fontSize: "1rem" }} className="light">
              Article<span style={{ paddingLeft: "5px", color: "black" }}>*</span>
            </label>
            <ReactQuill
            value={getValues('text.en')}
            style={{ ...defaultEditorStyles, borderColor: error ? "red" : "rgb(165, 164, 164)" }}
            className={error && "failed"}
            compulsory
            onChange={(e) => {
              setValue("text.en", e)
              if (!firstLoad) setFirstLoad(true)
              }} />
            </div>
          )}
          rules={{ required: true }}
        />}

        {!edit &&
        <Controller
          control={control}
          name={"text.en"}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Inputs.RichText
              label={"Article"}
              style={{ display: selLanguage === "en" ? "block" : "none", height: "77%" }}
              value={value}
              onChange={(e) => {
                setValue("text.en", e);
              }}
              className={error && "failed"}
              compulsory
            />
          )}
          rules={{ required: true }}
        />}

      {(edit && selLanguage === 'de' && getValues('text.de')) && 
        <Controller
          control={control}
          name={"text.de"}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className="input-container" style={{ display: selLanguage === "de" ? "block" : "none", height: "75%" }}>
            <label style={{ display: "flex", width: "100%", fontSize: "1rem" }} className="light">
              Article<span style={{ paddingLeft: "5px", color: "black" }}>*</span>
            </label>
            <ReactQuill
            value={getValues('text.de')}
            style={{ ...defaultEditorStyles, borderColor: error ? "red" : "rgb(165, 164, 164)" }}
            className={error && "failed"}
            compulsory
            onChange={(e) => {
              setValue("text.de", e)
              if (!firstLoad) setFirstLoad(true)
              }} />
            </div>
          )}
          rules={{ required: true }}
        />}

        {!edit &&
        <Controller
          control={control}
          name={"text.de"}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Inputs.RichText
              label={"Article"}
              style={{ display: selLanguage === "de" ? "block" : "none", height: "77%" }}
              value={value}
              onChange={(e) => {
                setValue("text.de", e);
              }}
              className={error && "failed"}
              compulsory
            />
          )}
          rules={{ required: true }}
        />}

      {(edit && selLanguage === 'it' && getValues('text.it')) && 
        <Controller
          control={control}
          name={"text.it"}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className="input-container" style={{ display: selLanguage === "it" ? "block" : "none", height: "75%" }}>
            <label style={{ display: "flex", width: "100%", fontSize: "1rem" }} className="light">
              Article<span style={{ paddingLeft: "5px", color: "black" }}>*</span>
            </label>
            <ReactQuill
            value={getValues('text.it')}
            style={{ ...defaultEditorStyles, borderColor: error ? "red" : "rgb(165, 164, 164)" }}
            className={error && "failed"}
            compulsory
            onChange={(e) => {
              setValue("text.it", e)
              if (!firstLoad) setFirstLoad(true)
              }} />
            </div>
          )}
          rules={{ required: true }}
        />}

        {!edit && 
        <Controller
          control={control}
          name={"text.it"}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Inputs.RichText
              label={"Article"}
              style={{ display: selLanguage === "it" ? "block" : "none", height: "77%" }}
              inputStyle={{ height: "95%" }}
              value={value}
              onChange={(e) => {
                setValue("text.it", e);
              }}
              className={error && "failed"}
              compulsory
            />
          )}
          rules={{ required: true }}
        />}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  autoCompleteUsers: state.dashboard.autoCompleteUsers,
  article: state.website.article
});

const mapDispatchToProps = (dispatch) => ({
  addArticle: (payload) => dispatch(addArticle(payload)),
  getArticle: (payload) => dispatch(getArticle(payload)),
  editArticle: (payload) => dispatch(editArticle(payload)),
  deleteArticle: (payload) => dispatch(deleteArticle(payload)),
  getUserAutoComplete: (payload) => dispatch(getUserAutoComplete(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddArticle);

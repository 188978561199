import { UsersStats } from "../actions";

const initialState = {
  users: { docs: [], totalPages: 0 },
};

const handleNewDocs = (state, payload, key) => {
  if (payload.page === 1) return payload.docs;
  const _docs = payload.docs.filter(
    (newDoc) => !state[key].docs.find((oldDoc) => newDoc._id === oldDoc._id)
  );
  return [...state?.[key]?.docs, ..._docs];
};

const users = (state = initialState, { type, payload, key } = {}) => {
  switch (type) {
    case UsersStats.GET_USERS_SUCCESS:
      return {
        ...state,
        users: {
          docs: handleNewDocs(state, payload, key),
          totalPages: payload.totalPages,
        },
      };
    default:
      return state;
  }
};

export default users;

/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { getGeneratedIncome, getPotentialIncome } from "../../actions";
import "./styles.scss";
import Inputs from "../../components/Inputs";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";

const headerElements = [
  {
    name: "Date",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Time",
    query: { filter: "Time", options: [] },
  },
  {
    name: "Paid amount",
    query: { filter: "Invitation sent by", options: [] },
  },
  {
    name: "Type",
    query: { filter: "Role", options: [] },
  },
  {
    name: "User",
    query: { filter: "Invitation sent to", options: [] },
  },
  {
    name: "Phone number",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Email",
    query: { filter: "---", options: [] },
  },
];

const inputStyle = {
  border: "none",
  borderRadius: "0px",
  borderBottom: "1px solid black",
  width: "100%",
};

const Finances = ({
  getGeneratedIncome,
  getPotentialIncome,
  payments,
  potential,
}) => {
  const [financeStats, setFinanceStats] = useState([
    {
      header: "Subscription renewal - monthly",
      percent: 0,
      usersCount: 0,
      price: 0,
      total: 0,
    },
    {
      header: "Subscription renewal - annual",
      percent: 0,
      usersCount: 0,
      price: 0,
      total: 0,
    },
    {
      header: "First subscription - monthly",
      percent: 0,
      usersCount: 0,
      price: 0,
      total: 0,
    },
    {
      header: "First subscription - annual",
      percent: 0,
      usersCount: 0,
      price: 0,
      total: 0,
    },
  ]);
  const [selTab, setSelTab] = useState("created");
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && coInvitations.totalPages >= curPage) {
      tableRef.current.scrollTop(30);
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    getGeneratedIncome({
      page: 1,
      limit: 20,
    });
    getPotentialIncome({
      onSuccess: (res) => {
        setFinanceStats([
          {
            header: "Subscription renewal - monthly",
            percent: 0,
            usersCount: res.monthlySubscriptions,
            price: res.monthlyPrice,
            total: 0,
          },
          {
            header: "Subscription renewal - annual",
            percent: 0,
            usersCount: res.yearlySubscriptions,
            price: res.yearlyPrice,
            total: 0,
          },
          {
            header: "First subscription - monthly",
            percent: 0,
            usersCount: res.unsubscribedUsers,
            price: res.monthlyPrice,
            total: 0,
          },
          {
            header: "First subscription - annual",
            percent: 0,
            usersCount: res.unsubscribedUsers,
            price: res.yearlyPrice,
            total: 0,
          },
        ]);
      },
    });
  }, []);


  return (
    <div className="main-container">
      <div className="body-container finances">
        <div className="button-flex-container">
          <div style={{ width: "98%", display: "flex" }}>
            <Inputs.Button
              text={"CREATED INCOME"}
              onClick={() => setSelTab("created")}
              selected={selTab === "created"}
            />
            <Inputs.Button
              text={"POTENTIAL INCOME"}
              style={{ marginLeft: "2%" }}
              onClick={() => setSelTab("potential")}
              selected={selTab === "potential"}
            />
          </div>
          <div className="export-icon" />
        </div>
        {selTab === "potential" ? (
          <div className="potential-container">
            {financeStats.map((stat, i) => {
              return (
                <div className="potential-row" key={i}>
                  <div className="row-title">{stat.header}</div>
                  <div className="users-count">
                    <div className="indicator blue" />
                    <p className="user-text">
                      <b style={{ fontSize: "19px" }}>{stat.usersCount}</b>{" "}
                      {i > 1 ? "FREE USERS" : "SUBSCRIBED USERS"}
                    </p>
                  </div>
                  <div className="multiply-icon" />
                  <div className="users-count" style={{ width: "24%" }}>
                    <Inputs.TextInput
                      symbolSuffix
                      number
                      inputStyle={inputStyle}
                      value={financeStats[i].percent || null}
                      style={{
                        width: "12%",
                        padding: "0px",
                        marginLeft: "1%",
                        height: "55px",
                      }}
                      onChange={(e) => {
                        const newStats = financeStats.slice(0);
                        newStats[i].total =
                          (
                            (newStats[i].usersCount / 100) *
                            Number(e.target.value)
                          ).toFixed(0) * newStats[i].price;
                        setFinanceStats(newStats);
                      }}
                    />
                    <p className="user-text">
                      {i > 1
                        ? "CONVERTED USERS"
                        : "RENEWED AT THE CERTAIN PERIOD"}
                    </p>
                  </div>
                  <div className="equal-icon" />
                  <div className="users-count" style={{ width: "24%" }}>
                    <div className="indicator blue" />
                    <p className="user-text">
                      <b style={{ fontSize: "18px" }}>
                        {stat.total.toFixed(2)} BGN
                      </b>{" "}
                      POTENTIAL INCOME
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <Scrollbars
            style={{ height: "85%", width: "100%", marginTop: "2%" }}
            onUpdate={handleUpdate}
            ref={tableRef}
            id={"scrollbar"}
            renderView={(props) => (
              <div {...props} style={{ ...props.style, overflowX: "hidden" }} />
            )}
          >
            <div className="table-header">
              {headerElements?.map((header, i) => {
                return (
                  <div
                    className="header-cont"
                    key={i}
                    style={{
                      width: "12%",
                    }}
                  >
                    <div className="header">{header.name}</div>
                  </div>
                );
              })}
            </div>
            {payments?.docs?.map((payment, i) => {
              return (
                <div className="table-body-row" key={i}>
                  <div className="row-data" style={{ width: "12%" }}>
                    {moment(payment?.createdAt).format("DD.MM.YYYY")}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {moment(payment?.createdAt).format("HH:mm")}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {payment?.amount || "---"} BGN
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {payment?.subscriptionType || "---"}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {payment?.user?.fullName || "---"}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {payment?.user?.phoneNumber || "---"}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {payment?.user?.email || "---"}
                  </div>
                </div>
              );
            })}
          </Scrollbars>
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Inputs.Button
            text={`Total Income:  ${payments?.total} BGN`}
            onClick={() => setSelTab("created")}
            selected={selTab === "created"}
            style={{
              width: "15%",
              marginTop: "1%",
              marginLeft: "-1%",
              height: "45px",
              backgroundColor: "#002C78",
              color: "white",
            }}
          />
          {selTab === "potential" ? (
            <p style={{ marginTop: "1%" }}>
              Period: <b>Next 30 days</b>
            </p>
          ) : (
            <p style={{ marginTop: "1%" }}>
              Last update:{" "}
              <b>
                {moment(payments?.lastUpdate).format("DD.MM.YYYY") +
                  " | " +
                  moment(payments?.lastUpdate).format("HH:mm")}
              </b>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  payments: state.finances.payments,
  potential: state.finances.potential,
});
const mapDispatchToProps = (dispatch) => ({
  getGeneratedIncome: (payload) => dispatch(getGeneratedIncome(payload)),
  getPotentialIncome: (payload) => dispatch(getPotentialIncome(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Finances);

/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import { toast } from "react-toastify";
import Inputs from "../Inputs";

const PayUtilityBillModal = ({
  handleClose,
  payUtilityBill,
  uploadReceipt,
  id,
  getUtilityBillsPayments,
  setCurPage,
  isPaid
}) => {
  const [receipt, setReceipt] = useState("");

  return (
    <div
      className={`delete-modal-container`}
      style={{ height: "250px", paddingTop: "5px" }}
    >
      <div style={{ display: "flex", width: "100%" }}>
        <h4 style={{ width: "95%", textAlign: "center" }}>
          PAY SELECTED UTILITY BILL
        </h4>
        <div className="close-icon" onClick={() => handleClose()} />
      </div>
      <p
        style={{
          textAlign: "center",
          width: "90%",
          paddingBottom: "0px",
          marginTop: "1%",
        }}
      >
        Do you really want to pay selected utility bill. You can't reverse this
        action.
      </p>
      <Inputs.ImageInput
        label={"Receipt"}
        compulsory
        value={receipt}
        files
        innerWidth
        style={{ marginLeft: "1%", marginTop: "0px" }}
        onChange={(e) => {
          setReceipt(e);
        }}
        setValue={setReceipt}
      />
      <div style={{ display: "flex", width: "93%", marginLeft: "2%" }}>
        <Inputs.Button
          text={"Confirm"}
          selected
          style={{ width: "48%", marginLeft: "1%", marginRight: "1%" }}
          onClick={() => {
            if (receipt.length < 1) {
              toast.error("No receipt attached!");
            } else
              if (isPaid === "paid") {
                uploadReceipt({
                  paymentId: id,
                  receiptUrl: receipt,
                  onSuccess: () => {
                    toast.success("Receipt uploaded successfully");
                    handleClose();
                    setReceipt("");
                    getUtilityBillsPayments({
                      limit: 20,
                      page: 1,
                    });
                    setCurPage(2);
                  },
                });
              }
              else {
                payUtilityBill({
                  paymentId: id,
                  receiptUrl: receipt,
                  onSuccess: () => {
                    toast.success("Bill paid successfully");
                    handleClose();
                    setReceipt("");
                    getUtilityBillsPayments({
                      limit: 20,
                      page: 1,
                    });
                    setCurPage(2);
                  },
                });
              }
          }}
        />
        <Inputs.Button
          text={"Cancel"}
          cancel
          style={{ width: "48%" }}
          onClick={() => handleClose()}
        />
      </div>
    </div>
  );
};
export default PayUtilityBillModal;

/* eslint-disable */
import React, { useEffect } from "react";
import {
  getWalletsStats,
  getStatisticsSuccess,
  getBankAccountsStats,
} from "../../../actions";
import "./styles.scss";
import { connect } from "react-redux";

const PaymentTypes = ({
  getStatisticsSuccess,
  getWalletsStats,
  getBankAccountsStats,
  wallets,
  bankAccounts,
}) => {
  useEffect(() => {
    getStatisticsSuccess({ type: "wallets", loading: true });
    getStatisticsSuccess({ type: "bankAccounts", loading: true });
    getWalletsStats();
    getBankAccountsStats();
  }, []);

  return (
    <div
      className="statistics-sessions-container col"
      style={{ height: "20%" }}
    >
      <div className="statistics-sessions-section col">
        <div
          className="statistics-sessions-header row"
          style={{
            backgroundColor: "#002C78",
            borderColor: "#002C78",
            margin: "10px 0px",
            justifyContent: "flex-start",
          }}
        >
          <div className="indicator" />
          <h2 style={{ paddingBottom: "0px", marginLeft: "10px" }}>
            <span>{wallets.walletsCount}</span> <b>CREATED WALLETS</b>
          </h2>
        </div>
        <div
          className="statistics-sessions-header row"
          style={{
            backgroundColor: "#002C78",
            borderColor: "#002C78",
            margin: "10px 0px",
            justifyContent: "flex-start",
          }}
        >
          <div className="indicator" />
          <h2 style={{ paddingBottom: "0px", marginLeft: "10px" }}>
            <span>{bankAccounts.bankAccountsCount}</span>{" "}
            <b>CONNECTED BANK ACCOUNTS</b>
          </h2>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  wallets: state.dashboard.wallets,
  bankAccounts: state.dashboard.bankAccounts,
});
const mapDispatchToProps = (dispatch) => ({
  getWalletsStats: (payload) => dispatch(getWalletsStats(payload)),
  getBankAccountsStats: (payload) => dispatch(getBankAccountsStats(payload)),
  getStatisticsSuccess: (payload) => dispatch(getStatisticsSuccess(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTypes);

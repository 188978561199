import React from "react";
import "./styles.scss";
import { forwardRef } from "react";
import ReactQuill from "react-quill"
const RichText = forwardRef(
  ({ label, compulsory, className, inputStyle, disabled, style, ...props }, ref) => {
    const defaultEditorStyles = {
      margin: "10px 0",
      borderColor: "rgb(165, 164, 164)",
      borderRadius: "6px",
      padding: "1px",
      fontSize: "18px",
      height: "95%"
    };

    return (
      <div className="input-container" style={style}>
        <label style={{ display: "flex", width: "100%", fontSize: "1rem" }} className={props?.light ? 'light' : ''}>
          {label}{" "}
          {compulsory && (
            <span style={{ paddingLeft: "5px", color: "black" }}>*</span>
          )}
        </label>
        {disabled ?
          <div className="text-wrapper">
            <p
              className="article-text"
              style={{ height: "100%" }}
              dangerouslySetInnerHTML={{ __html: props?.value ? props?.value : "" }}
            ></p>
          </div>
          : <ReactQuill
            style={
              className
                ? { ...defaultEditorStyles, borderColor: "red" }
                : defaultEditorStyles
            }
            {...props}
            ref={ref}
          />}
      </div>
    );
  }
);
export default RichText;

/* eslint-disable */
import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { StatisticsStats } from "../actions";
import { API_URL } from "../config/settings";
import { Headers } from "../utilites/Headers";
import { history } from "../config/stores";
import moment from "moment";
import { toast } from "react-toastify";

export const getStatisticsSessionsEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_STATISTICS_SESSIONS),
    switchMap(({ onSuccess }) =>
      ajax({
        url: `${API_URL}/admin/stats/users`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "sessions",
                loading: false,
                ...response,
              },
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error(err?.response?.error)
            obs.complete();
          })
        )
      )
    )
  );

export const getActiveUsersEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_ACTIVE_USERS),
    switchMap(({ onSuccess }) =>
      ajax({
        url: `${API_URL}/admin/active-users`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "activeUsers",
                loading: false,
                ...response,
              },
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error(err?.response?.error)
            obs.complete();
          })
        )
      )
    )
  );

export const getDownloadsEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_APP_DOWNLOADS),
    switchMap(({ onSuccess }) =>
      ajax({
        url: `${API_URL}/admin/active-users`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "activeUsers",
                loading: false,
                ...response,
              },
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error(err?.response?.error)
            obs.complete();
          })
        )
      )
    )
  );
export const getPropertiesStatsEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_PROPERTIES_STATS),
    switchMap(({ onSuccess }) =>
      ajax({
        url: `${API_URL}/admin/stats/properties`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "properties",
                loading: false,
                ...response,
              },
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getCommunitiesStatsEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_COMMUNITIES_STATS),
    switchMap(({ onSuccess }) =>
      ajax({
        url: `${API_URL}/admin/stats/communities`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "communities",
                loading: false,
                ...response,
              },
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getWalletsStatsEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_WALLETS_STATS),
    switchMap(({ onSuccess }) =>
      ajax({
        url: `${API_URL}/admin/stats/wallets`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "wallets",
                loading: false,
                ...response,
              },
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );

export const getBankAccountStatsEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_COMMUNITIES_STATS),
    switchMap(({ onSuccess }) =>
      ajax({
        url: `${API_URL}/admin/stats/bank-accounts`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "bankAccounts",
                loading: false,
                ...response,
              },
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getAppSessionsEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.APP_SESSIONS),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/stats/sessions`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            console.log(payload);
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "appSessions",
                loading: false,
                ...response,
              },
            });
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getAppDownloadsEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_APP_DOWNLOADS),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/stats/downloads`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
        //here without body = from the first registered user
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            console.log(payload);
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "appDownloads",
                loading: false,
                ...response,
              },
            });
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getAppGrowthStatsEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.APP_GROWTH_STATS),
    switchMap(({}) =>
      ajax({
        url: `${API_URL}/admin/stats/monthly`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "growth",
                loading: false,
                ...response,
              },
            });
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getNotificationsEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_NOTIFICATIONS),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/notifications/list`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StatisticsStats.GET_NOTIFICATIONS_SUCCESS,
              payload: response,
              totalPages: response.totalPages,
            });
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const createNotificationEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.CREATE_NOTIFICATION),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/notifications/send`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            console.log(response);
            payload.onSuccess();
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error(err?.response?.error)
            obs.complete();
          })
        )
      )
    )
  );
export const getActiveUsersListEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_ACTIVE_USERS_LIST),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/active-users/list`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StatisticsStats.GET_ACTIVE_USERS_LIST_SUCCESS,
              payload: response.users,
            });
            if (payload.onSuccess) payload?.onSuccess(response.users);

            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getReceiversEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_RECEIVERS),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/notifications/${payload.id}/receivers`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify({
          page: payload.page,
          limit: payload.limit,
        }),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            console.log(response);
            obs.next({
              type: StatisticsStats.GET_RECEIVERS_SUCCESS,
              payload: response,
            });
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getUserAutoCompleteEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.USER_AUTOCOMPLETE),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/users-autocomplete?pattern=${payload}`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StatisticsStats.USER_AUTOCOMPLETE_SUCCESS,
              payload: response.users,
            });
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getPropertiesEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_PROPERTIES),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/properties/list`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (payload.onSuccess) payload.onSuccess(response.properties);
            obs.next({
              type: StatisticsStats.GET_PROPERTIES_SUCCESS,
              payload: response.properties,
              locationsWithCoordinates: response.locations,
              totalPagesProperties: response.properties.totalPages,
            });
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );

export const WebsiteArticles = {
    GET_ARTICLES: "website/GET_ARTICLES",
    GET_ARTICLES_SUCCESS: "website/GET_ARTICLES_SUCCESS",
    ADD_ARTICLE: "website/ADD_ARTICLE",
    ADD_ARTICLE_SUCCESS: "website/ADD_ARTICLE_SUCCESS",
    EDIT_ARTICLE: "website/EDIT_ARTICLE",
    EDIT_ARTICLE_SUCCESS: "website/EDIT_ARTICLE_SUCCESS",
    DELETE_ARTICLE: "website/DELETE_ARTICLE",
    DELETE_ARTICLE_SUCCESS: "website/DELETE_ARTICLE_SUCCESS",
    GET_ARTICLE: "website/GET_ARTICLE",
    GET_ARTICLE_SUCCESS: "website/GET_ARTICLE_SUCCESS",
  };

  export const getArticles = (payload) => ({
    type: WebsiteArticles.GET_ARTICLES,
    payload,
  });
  
  export const addArticle = (payload) => ({
    type: WebsiteArticles.ADD_ARTICLE,
    payload,
  });

  export const editArticle = (payload) => ({
    type: WebsiteArticles.EDIT_ARTICLE,
    payload,
  });

  export const deleteArticle = (payload) => ({
    type: WebsiteArticles.DELETE_ARTICLE,
    payload,
  });

  export const getArticle = (payload) => ({
    type: WebsiteArticles.GET_ARTICLE,
    payload,
  });
  
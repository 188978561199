/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import { getActiveUsersList } from "../../../actions";
import { history } from "../../../config/stores";

const headerElements = [
  {
    name: "Name",
    query: { filter: "name", options: [] },
  },
  {
    name: "Country",
    query: { filter: "Country", options: [] },
  },
  {
    name: "City",
    query: { filter: "City", options: [] },
  },
  {
    name: "OS",
    query: { filter: "OS", options: [] },
  },
  {
    name: "Number of properties",
    query: { filter: "_properties", options: [] },
  },
  {
    name: "Role property #1",
    query: { filter: "---", options: [] },
  },
  {
    name: "Role property #2",
    query: { filter: "---", options: [] },
  },
  {
    name: "Role property #3",
    query: { filter: "---", options: [] },
  },
  {
    name: "Role property #4",
    query: { filter: "---", options: [] },
  },
  {
    name: "Role property #5",
    query: { filter: "---", options: [] },
  },
];

const ActiveUsersGrid = ({
  getActiveUsersList,
  totalPagesUsers,
  activeUsersList,
}) => {
  const [curPage, setCurPage] = useState(1);
  const tableRef = useRef();

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && totalPages >= curPage) {
      tableRef.current.scrollTop(30);
      //list
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    getActiveUsersList({
      page: 1,
      limit: 100,
    });
  }, []);

  return (
    <div className="main-container">
      <div className="inner-header-container">
        <div className="left-part" style={{ width: "96%" }}>
          <div className="close-icon" onClick={() => history.push("/")} />
          <h2 className="inner-title">active users right now</h2>
        </div>
        <div className="export-icon" />
      </div>
      <div className="body-container">
        <Scrollbars
          style={{ height: "100%", width: "100%" }}
          onUpdate={handleUpdate}
          ref={tableRef}
          id={"scrollbar"}
        >
          <div className="table-header">
            {headerElements?.map((header, i) => {
              return (
                <div
                  className="header-cont"
                  key={i}
                  style={{ width: i > 3 ? "11%" : i === 2 && "7%" }}
                >
                  <div className="header">{header.name}</div>
                </div>
              );
            })}
          </div>
          {activeUsersList.map((user, i) => {
            return (
              <div className="table-body-row" key={i}>
                <div className="row-data">{user?.fullName}</div>
                <div className="row-data">{user?.country}</div>
                <div className="row-data">{user?.city}</div>
                <div className="row-data">{user?.os}</div>
                <div className="row-data bigger">{user?.roles?.length}</div>
                <div className="row-data bigger">
                  {user?.roles?.[0] ? user?.roles?.[0] : "---"}
                </div>
                <div className="row-data bigger">
                  {user?.roles?.[1] ? user?.roles?.[1] : "---"}
                </div>
                <div className="row-data bigger">
                  {user?.roles?.[2] ? user?.roles?.[2] : "---"}
                </div>
                <div className="row-data bigger">
                  {user?.roles?.[3] ? user?.roles?.[3] : "---"}
                </div>
                <div className="row-data bigger">
                  {user?.roles?.[4] ? user?.roles?.[4] : "---"}
                </div>
              </div>
            );
          })}
        </Scrollbars>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  activeUsersList: state.dashboard.activeUsersList,
  totalPagesUsers: state.dashboard.totalPages,
});
const mapDispatchToProps = (dispatch) => ({
  getActiveUsersList: (payload) => dispatch(getActiveUsersList(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ActiveUsersGrid);

/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { getArticles } from "../../actions";
import "./styles.scss";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";
import Inputs from "../../components/Inputs";
import { history } from "../../config/stores";

const Website = ({ getArticles, articles }) => {
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && articles.totalPages >= curPage) {
      tableRef.current.scrollTop(30);
      getArticles({
        page: curPage,
        limit: 20,
      });
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    getArticles({
      page: 1,
      limit: 20,
    });
  }, []);

  console.log(articles)

  return (
    <div className="main-container website">
      <div
        className="body-container"
        style={{ height: "99%", marginTop: "0%" }}
      >
        <div className="inner-header-container" style={{ boxShadow: "none" }}>
          <h2>ARTICLES</h2>
          <Inputs.Button
            text="ADD"
            selected
            onClick={() => history.push("/website/add-article")}
          />
        </div>
        <Scrollbars
          style={{ height: "92%", width: "100%", marginTop: "1%" }}
          onUpdate={handleUpdate}
          ref={tableRef}
          renderView={(props) => (
            <div {...props} style={{ ...props.style, overflowX: "hidden" }} />
          )}
          id={"scrollbar"}
        >
          {articles?.docs?.map((article, i) => {
            return (
              <div className="card-row" key={i} onClick={() => history.push(`/website/article/${article?._id}`)}>
                <div className="data">
                  {article?.title?.bg}
                </div>
                <div className="data">
                  {moment(article?.createdAt).format("DD.MM.YYYY")}
                </div>
              </div>
            );
          })}
        </Scrollbars>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  articles: state.website.articles,
});
const mapDispatchToProps = (dispatch) => ({
  getArticles: (payload) => dispatch(getArticles(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Website);

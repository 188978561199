import { combineEpics } from "redux-observable";
import * as starter from "./starter";
import * as auth from "./auth";
import * as dashboard from "./dashboard";
import * as app from "./app";
import * as finances from "./finances";
import * as users from "./users";
import * as merchants from "./merchants";
import * as website from "./website";
export const rootEpic = combineEpics(
  starter.loadingEpic,
  auth.loginEpic,
  auth.logOutEpic,
  dashboard.getActiveUsersEpic,
  dashboard.getStatisticsSessionsEpic,
  dashboard.getPropertiesStatsEpic,
  dashboard.getCommunitiesStatsEpic,
  dashboard.getBankAccountStatsEpic,
  dashboard.getWalletsStatsEpic,
  dashboard.getAppSessionsEpic,
  dashboard.getAppGrowthStatsEpic,
  dashboard.getNotificationsEpic,
  dashboard.createNotificationEpic,
  dashboard.getActiveUsersListEpic,
  dashboard.getReceiversEpic,
  dashboard.getUserAutoCompleteEpic,
  dashboard.getPropertiesEpic,
  dashboard.getAppDownloadsEpic,
  app.getStatsEpic,
  app.getMutualManagementsEpic,
  app.coManagementEpic,
  app.getRentPayentsEpic,
  app.getUtilityBillsPaymentsEpic,
  app.getFilesEpic,
  app.getServicesEpic,
  app.getCalendarEventsEpic,
  app.getCasesEpic,
  app.getWalletsEpic,
  app.getNeighborsNoticesEpic,
  app.getNeighborsCalendarEpic,
  app.getNeighborsPollsEpic,
  app.getNeighborsPaymentsEpic,
  app.payUtilityBillEpic,
  app.getNeighborsFilesEpic,
  app.getNeighborsServicesEpic,
  app.getNeighborsMeetingsEpic,
  app.getNeighborsWalletsEpic,
  app.uploadReceiptEpic,
  finances.getGeneratedFinancesEpic,
  finances.getPotentialIncomeEpic,
  users.getUsersEpic,
  users.addUsersEpic,
  merchants.getMerchantsListEpic,
  merchants.getPendingListEpic,
  merchants.addPendingEpic,
  merchants.deletePendingEpic,
  merchants.getCurrentMerchantEpic,
  merchants.editMerchantEpic,
  merchants.deleteMerchantEpic,
  merchants.searchMerchantsEpic,
  website.getArticlesEpic,
  website.addArticleEpic,
  website.getSingleArticleEpic,
  website.editArticleEpic,
  website.deleteArticleEpic,
);

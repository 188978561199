export const UsersStats = {
  GET_USERS: "app/GET_USERS",
  GET_USERS_SUCCESS: "app/GET_USERS_SUCCESS",
  ADD_USER: "app/ADD_USER",
};

export const getUsers = (payload) => ({
  type: UsersStats.GET_USERS,
  payload,
});
export const addUser = (payload) => ({
  type: UsersStats.ADD_USER,
  payload,
});

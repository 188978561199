import { FinancesStats } from "../actions";

const initialState = {
  payments: { docs: [], totalPages: 0, totalIncome: 0, lastUpdate: "" },
  potential: {},
};

const handleNewDocs = (state, payload, key) => {
  if (payload.page === 1) return payload.docs;
  const _docs = payload.docs.filter(
    (newDoc) => !state[key].docs.find((oldDoc) => newDoc._id === oldDoc._id)
  );
  return [...state?.[key]?.docs, ..._docs];
};

const finances = (
  state = initialState,
  { type, payload, key, total, lastUpdate } = {}
) => {
  switch (type) {
    case FinancesStats.GET_GENERATED_INCOME_SUCCESS:
      return {
        ...state,
        payments: {
          docs: handleNewDocs(state, payload, key),
          totalPages: payload.totalPages,
          total: total,
          lastUpdate: lastUpdate,
        },
      };
    case FinancesStats.GET_POTENTIAL_INCOME_SUCCESS:
      return {
        ...state,
        potential: { ...payload },
      };
    default:
      return state;
  }
};

export default finances;

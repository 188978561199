/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { getServices } from "../../../actions";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";
import { connect } from "react-redux";
import Statistics from "../../Statistics";

const headerElements = [
  {
    name: "Date",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Time",
    query: { filter: "Time", options: [] },
  },
  {
    name: "User",
    query: { filter: "Invitation sent by", options: [] },
  },
  {
    name: "Requested service",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Received offers",
    query: { filter: "Invitation sent to", options: [] },
  },
  {
    name: "Sent emails",
    query: { filter: "---", options: [] },
  },
  {
    name: "Received answers",
    query: { filter: "City", options: [] },
  },
  {
    name: "Country",
    query: { filter: "Address", options: [] },
  },
  {
    name: "City",
    query: { filter: "Type", options: [] },
  },
  {
    name: "Address",
    query: { filter: "Status", options: [] },
  },
  {
    name: "Type",
    query: { filter: "Status", options: [] },
  },
  {
    name: "Status",
    query: { filter: "Status", options: [] },
  },
];

const Services = ({ getServices, services, period }) => {
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();
  const [innerLoading, setInnerLoading] = useState(false);

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && services.totalPages >= curPage && !innerLoading) {
      setInnerLoading(true)
      getServices({
        page: curPage,
        limit: 20,
        onSuccess:(response) => setInnerLoading(false)
      });
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    getServices({
      page: 1,
      limit: 20,
    });
  }, []);

  useEffect(() => {
    if (period.startDate !== "") {
      getServices({
        page: 1,
        limit: 20,
        ...period,
      });
      setCurPage(2);
    }
  }, [period]);

  return (
    <Scrollbars
      style={{ height: "100%", width: "100%" }}
      onUpdate={handleUpdate}
      ref={tableRef}
      id={"scrollbar"}
    >
      <div className="table-header" style={{ width: "120%" }}>
        {headerElements?.map((header, i) => {
          return (
            <div
              className="header-cont"
              key={i}
              style={{
                width: "10%",
              }}
            >
              <div className="header">{header.name}</div>
            </div>
          );
        })}
      </div>
      {services?.docs?.map((service, i) => {
        return (
          <div className="table-body-row" key={i} style={{ width: "120%" }}>
            <div className="row-data" style={{ width: "10%" }}>
              {service?.createdAt
                ? moment(service?.createdAt).format("DD.MM.YYYY")
                : "---"}
            </div>
            <div className="row-data" style={{ width: "10%" }}>
              {service?.createdAt
                ? moment(service?.createdAt).format("HH:mm")
                : "---"}
            </div>
            <div className="row-data" style={{ width: "10%" }}>
              {service?.requestedFrom || "---"}
            </div>
            <div className="row-data" style={{ width: "10%" }}>
              {service?.requestText.length > 30
                ? service?.requestText.slice(0, 22) + "..."
                : service?.requestText}
            </div>
            <div className="row-data" style={{ width: "10%" }}>
              {service?.searchResults || "---"}
            </div>
            <div className="row-data" style={{ width: "10%" }}>
              {service?.requests}
            </div>
            <div className="row-data" style={{ width: "10%" }}>
              {service?.responses}
            </div>
            <div className="row-data" style={{ width: "10%" }}>
              {service?.property?.country || "---"}
            </div>
            <div className="row-data" style={{ width: "10%" }}>
              {service?.property?.city || "---"}
            </div>
            <div className="row-data" style={{ width: "10%" }}>
              {service?.property?.address || "---"}
            </div>
            <div className="row-data" style={{ width: "10%" }}>
              {service?.property?.propertyType || "---"}
            </div>
            <div className="row-data" style={{ width: "10%" }}>
              {service?.property?.isRented === true ? "Rented" : "Not Rented"}
            </div>
          </div>
        );
      })}
       {innerLoading && (
        <div className="inner-loader-container">
          <Statistics.LoaderInline center />
        </div>
      )}
    </Scrollbars>
  );
};
const mapStateToProps = (state) => ({
  services: state.app.services,
});
const mapDispatchToProps = (dispatch) => ({
  getServices: (payload) => dispatch(getServices(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Services);

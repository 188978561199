import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { UsersStats } from "../actions";
import { API_URL } from "../config/settings";
import { Headers } from "../utilites/Headers";
import { toast } from "react-toastify";

export const getUsersEpic = (action$) =>
  action$.pipe(
    ofType(UsersStats.GET_USERS),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/users/list`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: UsersStats.GET_USERS_SUCCESS,
              payload: response.admins,
              key: "users",
            });
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );

export const addUsersEpic = (action$) =>
  action$.pipe(
    ofType(UsersStats.ADD_USER),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/users`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            toast.success("User created successfully!");
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error(err?.response?.error)
            obs.complete();
          })
        )
      )
    )
  );

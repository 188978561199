/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { getUtilityBillsPayments, payUtilityBill, uploadReceipt } from "../../../actions";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";
import { connect } from "react-redux";
import Inputs from "../../Inputs";
import ModalComponent from "../../ModalComponent/ModalComponent";
import PayUtilityBillModal from "../../ModalComponent/PayUtilityBillModal";
import Statistics from "../../Statistics";

const headerElements = [
  {
    name: "Date",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Time",
    query: { filter: "Time", options: [] },
  },
  {
    name: "Paid by",
    query: { filter: "Invitation sent by", options: [] },
  },
  {
    name: "Provider",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Total amount",
    query: { filter: "Invitation sent to", options: [] },
  },
  {
    name: "Country",
    query: { filter: "---", options: [] },
  },
  {
    name: "City",
    query: { filter: "City", options: [] },
  },
  {
    name: "Address",
    query: { filter: "Address", options: [] },
  },
  {
    name: "Type",
    query: { filter: "Type", options: [] },
  },
  {
    name: "Status",
    query: { filter: "Status", options: [] },
  },
  {
    name: "Еpay status",
    query: { filter: "Status", options: [] },
  },
  {
    name: "Stripe status",
    query: { filter: "Status", options: [] },
  },
  {
    name: "",
    query: { filter: "Status", options: [] },
  },
];

const UtilityBills = ({
  getUtilityBillsPayments,
  payUtilityBill,
  uploadReceipt,
  utilityBills,
  period,
}) => {
  const [curPage, setCurPage] = useState(2);
  const [openModal, setOpenModal] = useState(false);
  const [selBill, setSelBill] = useState("");
  const [isPaid, setIsPaid] = useState("");
  const tableRef = useRef();
  const [innerLoading, setInnerLoading] = useState(false);

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && utilityBills.totalPages >= curPage && !innerLoading) {
      setInnerLoading(true)
      getUtilityBillsPayments({
        page: curPage,
        limit: 20,
        onSuccess:(res) => setInnerLoading(false)
      });
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    getUtilityBillsPayments({
      page: 1,
      limit: 20,
    });
  }, []);

  useEffect(() => {
    if (period.startDate !== "") {
      getUtilityBillsPayments({
        page: 1,
        limit: 20,
        ...period,
      });
      setCurPage(2);
    }
  }, [period]);

  return (
    <>
      <Scrollbars
        style={{ height: "100%", width: "100%" }}
        onUpdate={handleUpdate}
        ref={tableRef}
        id={"scrollbar"}
      >
        <div className="table-header" style={{ width: "130%" }}>
          {headerElements?.map((header, i) => {
            return (
              <div
                className="header-cont"
                key={i}
                style={{
                  width: i === 7 ? "15%" : i === 3 ? "14%" : "8%",
                }}
              >
                <div className="header">{header.name}</div>
              </div>
            );
          })}
        </div>
        {utilityBills?.docs?.map((bill, i) => {
          return (
            <div className="table-body-row" key={i} style={{ width: "130%" }}>
              <div className="row-data" style={{ width: "8%" }}>
                {moment(bill?.paymentDate).format("DD.MM.YYYY")}
              </div>
              <div className="row-data" style={{ width: "8%" }}>
                {moment(bill?.paymentDate).format("HH:mm")}
              </div>
              <div className="row-data" style={{ width: "10%" }}>
                {bill?.paidBy || "---"}
              </div>
              <div className="row-data" style={{ width: "12%" }}>
                {bill?.provider || "---"}
              </div>
              <div className="row-data" style={{ width: "8%" }}>
                {bill?.amount || "---"}
              </div>
              <div className="row-data" style={{ width: "8%" }}>
                {bill?.property?.country || "---"}
              </div>
              <div className="row-data" style={{ width: "8%" }}>
                {bill?.property?.city || "---"}
              </div>
              <div className="row-data" style={{ width: "15%" }}>
                {bill?.property?.address || "---"}
              </div>
              <div className="row-data" style={{ width: "8%" }}>
                {bill?.property?.propertyType || "---"}
              </div>
              <div className="row-data" style={{ width: "8%" }}>
                {bill?.property?.isRented === true ? "Rented" : "Not Rented"}
              </div>
              <div className="row-data" style={{ width: "8%" }}>
                {bill?.epayStatus || "---"}
              </div>
              <div
                className="row-data"
                style={{ width: "8%", borderRight: "none" }}
              >
                {bill?.stripeStatus || "---"}
              </div>
              <div
                className="row-data"
                style={{ width: "8%", justifyContent: "center" }}
              >
                {/* {bill?.epayStatus === "failed" && ( */}
                {(bill?.epayStatus === "failed" ||
                bill?.receiptUrl?.includes("stripe")) && (
                  <Inputs.Button
                    text="Pay"
                    selected
                    onClick={() => {
                      setOpenModal(true);
                      setSelBill(bill?._id);
                      setIsPaid(bill?.epayStatus)
                    }}
                  />
                )}
              </div>
            </div>
          );
        })}
         {innerLoading && (
        <div className="inner-loader-container">
          <Statistics.LoaderInline center />
        </div>
      )}
      </Scrollbars>
      <ModalComponent
        open={openModal}
        children={
          <PayUtilityBillModal
            payUtilityBill={payUtilityBill}
            uploadReceipt={uploadReceipt}
            getUtilityBillsPayments={getUtilityBillsPayments}
            setCurPage={setCurPage}
            id={selBill}
            isPaid={isPaid}
            handleClose={() => {
              setOpenModal(false);
            }}
          />
        }
      />
    </>
  );
};
const mapStateToProps = (state) => ({
  utilityBills: state.app.utilityBills,
});
const mapDispatchToProps = (dispatch) => ({
  getUtilityBillsPayments: (payload) =>
    dispatch(getUtilityBillsPayments(payload)),
  payUtilityBill: (payload) => dispatch(payUtilityBill(payload)),
  uploadReceipt: (payload) => dispatch(uploadReceipt(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UtilityBills);

import { WebsiteArticles } from "../actions";

const initialState = {
  articles: { docs: [], totalPages: 0 },
  article: {}
};

const handleNewDocs = (state, payload, key) => {
  if (payload.page === 1) return payload.docs;
  const _docs = payload.docs.filter(
    (newDoc) => !state[key].docs.find((oldDoc) => newDoc._id === oldDoc._id)
  );
  return [...state?.[key]?.docs, ..._docs];
};

const editArticle = (state, payload, key) => {
  if (payload) {
    // let article = state.articles.find(article => article._id === payload._id)
    let articleIndex = state.articles.map(article => article._id).indexOf(payload.updatedArticle._id)
    return state.articles.splice(articleIndex, 1, payload.updatedArticle)
  }
};

const deleteArticle = (state, payload) => {
  if (payload) {
    let newArticles = state.articles.filter(article => article._id !== payload._id)
    return newArticles
  }
};

const website = (state = initialState, { type, payload, key } = {}) => {
  switch (type) {
    case WebsiteArticles.GET_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: {
          docs: handleNewDocs(state, payload, key),
          totalPages: payload.totalPages,
        },
      };
    case WebsiteArticles.GET_ARTICLE_SUCCESS:
      return {
        ...state,
        article: {...payload},
      };
    case WebsiteArticles.EDIT_ARTICLE_SUCCESS:
      return {
        ...state,
        article: editArticle(state, payload),
      };
    case WebsiteArticles.DELETE_ARTICLE_SUCCESS:
      return {
        ...state,
        articles: deleteArticle(state, payload),
      };
    default:
      return state;
  }
};

export default website;

/* eslint-disable */
import React, { useState } from "react";
import { connect } from "react-redux";
import Statistics from "../../components/Statistics";
import "./styles.scss";

const Dashboard = () => {
  return (
    <div className="screen-statistics-container row">
      <div className="col">
        <Statistics.Sessions />
      </div>
      <div className="col">
        <Statistics.Registrations />
      </div>
      <div className="col">
        <Statistics.Properties />
      </div>
      <div className="col">
        <Statistics.Communities />
      </div>
      <div className="col" style={{ height: "20%" }}>
        <Statistics.PaymentTypes />
      </div>
      <div className="col">
        <Statistics.AppSessions />
      </div>
      <div className="col">
        <Statistics.AppGrowth />
      </div>
      <div className="col" style={{minWidth:"30vw"}}>
        <Statistics.Notifications />
      </div>
    </div>
  );
};

export default Dashboard;

import { default as Button } from "./Button";
import { default as TextInput } from "./TextInput";
import { default as RadioButtons } from "./RadioButtons";
import { default as Switch } from "./Switch";
import { default as RichText } from "./RichText";
import { default as AutoCompleteDropdown } from "./AutoCompleteDropdown";
import { default as Dropdown } from "./Dropdown";
import { default as ImageInput } from "./ImageInput";
const Inputs = {
  Button,
  TextInput,
  ImageInput,
  RadioButtons,
  Switch,
  RichText,
  Dropdown,
  AutoCompleteDropdown,
};

export default Inputs;

/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import { getReceivers } from "../../../actions";
import { history } from "../../../config/stores";

const headerElements = [
  {
    name: "Name",
    query: { filter: "name", options: [] },
  },
  {
    name: "Country",
    query: { filter: "Country", options: [] },
  },
  {
    name: "City",
    query: { filter: "City", options: [] },
  },
  {
    name: "OS",
    query: { filter: "OS", options: [] },
  },
  {
    name: "Role",
    query: { filter: "_properties", options: [] },
  },
];

const Receivers = ({ getReceivers, receivers }) => {
  const customId = window.location.href.split("/")[4];
  const [curPage, setCurPage] = useState(1);
  const tableRef = useRef();

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
  };

  useEffect(() => {
    getReceivers({
      page: 1,
      limit: 100,
      id: customId,
    });
  }, []);

  return (
    <div className="main-container">
      <div className="inner-header-container">
        <div className="left-part" style={{ width: "96%" }}>
          <div className="close-icon" onClick={() => history.push("/")} />
          <h2 className="inner-title">Sent to users</h2>
        </div>
      </div>
      <div className="body-container">
        <Scrollbars
          style={{ height: "100%", width: "100%" }}
          onUpdate={handleUpdate}
          ref={tableRef}
          id={"scrollbar"}
          renderView={(props) => (
            <div {...props} style={{ ...props.style, overflowX: "hidden" }} />
          )}
        >
          <div className="table-header">
            {headerElements?.map((header, i) => {
              return (
                <div className="header-cont" key={i} style={{ width: "12%" }}>
                  <div className="header">{header.name}</div>
                </div>
              );
            })}
          </div>
          {receivers?.map((receiver, i) => {
            return (
              <div className="table-body-row" key={i}>
                <div className="row-data" style={{ width: "12%" }}>
                  {receiver?.fullName}
                </div>
                <div className="row-data" style={{ width: "12%" }}>
                  {receiver?.country ? receiver?.country : "---"}
                </div>
                <div className="row-data" style={{ width: "12%" }}>
                  {receiver?.city ? receiver?.city : "---"}
                </div>
                <div className="row-data" style={{ width: "12%" }}>
                  {receiver?.os ? receiver?.os : "---"}
                </div>
                <div className="row-data bigger" style={{ width: "40%" }}>
                  {receiver?.roles.map((role, i) =>
                    i === receiver?.roles?.length - 1 ? role : role + ", "
                  ) || "---"}
                </div>
              </div>
            );
          })}
        </Scrollbars>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  receivers: state.dashboard.receivers,
});
const mapDispatchToProps = (dispatch) => ({
  getReceivers: (payload) => dispatch(getReceivers(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Receivers);

import { default as Mutual } from "./Mutual";
import { default as CoInvitations } from "./CoInvitations";
import { default as RentPayments } from "./RentPayments";
import { default as UtilityBills } from "./UtilityBills";
import { default as Files } from "./Files";
import { default as Services } from "./Services";
import { default as CalendarEventsProperties } from "./CalendarEventsProperties";
import { default as Cases } from "./Cases";
import { default as Wallets } from "./Wallets";
import { default as Notices } from "./Neighbors/Notices";
import { default as CalendarCommunities } from "./Neighbors/CalendarCommunities";
import { default as FilesCommunities } from "./Neighbors/FilesCommunities";
import { default as ServicesCommunities } from "./Neighbors/ServicesCommunities";
import { default as MeetingsCommunities } from "./Neighbors/MeetingsCommunities";
import { default as WalletsCommunities } from "./Neighbors/WalletsCommunities";
import { default as Polls } from "./Neighbors/Polls";
import { default as Payments } from "./Neighbors/Payments";
const AppGrids = {
  Mutual,
  CoInvitations,
  RentPayments,
  UtilityBills,
  Files,
  CalendarEventsProperties,
  Cases,
  Services,
  Wallets,
  Notices,
  CalendarCommunities,
  FilesCommunities,
  ServicesCommunities,
  MeetingsCommunities,
  WalletsCommunities,
  Polls,
  Payments,
};

export default AppGrids;

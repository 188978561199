const servers = {
  PROD: "https://immotech-2-prod.herokuapp.com",
  DEV: "https://immotech-api.herokuapp.com",
};

export const API_URL = process.env.REACT_APP_API_URL || servers.DEV;
export const MAPBOX_API_KEY =
  "pk.eyJ1IjoiaW9ua28iLCJhIjoiY2wwcnQzd205MDUxZDNqczBtZ2YxeXdhNSJ9.obIklcld90kwyuwcNNSWjA";

export const s3Credentials = {
  bucketName: "immotech-2",
  region: "eu-central-1",
  dirName:"merchant-logos",
  accessKeyId: "AKIA5L7PZ7W5KVMMSWWO",
  secretAccessKey: "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",
  successActionStatus: 201,
};

/* eslint-disable */
import React, { useEffect, useState } from "react";
import Inputs from "../../Inputs";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createNotification, getUserAutoComplete } from "../../../actions";
import { history } from "../../../config/stores";

const customStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#0083E5", // Custom colour
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#0083E5" : "white",
    padding: 10,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: (window.innerWidth / 100) * 94.2,
    height: 40,
    display: "flex",
    border: "1px solid rgb(165, 164, 164)",
    borderRadius: "7px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const GroupOptions = [
  {
    label: "Owners",
    value: "Owners",
  },
  {
    label: "Landlords With Tenant",
    value: "Landlords With Tenant",
  },
  {
    label: "Landlords Without Tenant",
    value: "Landlords Without Tenant",
  },
  {
    label: "Tenants With Landlord",
    value: "Tenants With Landlord",
  },
  {
    label: "Tenants Without Landlord",
    value: "Tenants Without Landlord",
  },
  {
    label: "Users Without Property",
    value: "Users Without Property",
  },
];

const AddNotification = ({
  createNotification,
  getUserAutoComplete,
  autoCompleteUsers,
}) => {
  const [selLanguage, setSelLanguage] = useState("bg");
  const [selReceiver, setSelReceiver] = useState("all");
  const {
    register,
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: {
        en: "",
        bg: "",
        de: "",
        it: ""
      },
      text: {
        en: "",
        bg: "",
        de: "",
        it: ""
      },
      sendToAll: "",
      //leadToAction: false,
    },
  });

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast.error("Missing info", {
        autoClose: 3000,
      });
    }
  }, [errors]);

  useEffect(() => {
    getUserAutoComplete();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="main-container">
      <div className="inner-header-container">
        <div className="left-part">
          <div className="close-icon" onClick={() => history.push("/")} />
          <h2 className="inner-title">send out custom notifiaction</h2>
        </div>
        <Inputs.Button
          text="SEND"
          style={{ width: "8%" }}
          selected
          onClick={handleSubmit((e) => {
            const payload = {
              title: { ...e.title },
              text: { ...e.text },
              sendToAll: e.sendToAll === true ? true : false,
              groups:
                e.groups && selReceiver === "group"
                  ? e.groups.map((group) => group.value)
                  : undefined,
              userIds:
                e.users && selReceiver === "user"
                  ? e.users.map((user) => user.value._id)
                  : null,
            };
            createNotification({
              ...payload,
              onSuccess: () => {
                toast.success("Notification sent successfully.");
                history.push("/");
              },
            });
          })}
        />
      </div>
      <div className="body-container">
        <div className="languages-container">
          <Inputs.Button
            text="ENGLISH"
            style={{ width: "23%" }}
            selected={selLanguage === "en"}
            onClick={() => setSelLanguage("en")}
          />
          <Inputs.Button
            text="BULGARIAN"
            style={{ width: "23%" }}
            selected={selLanguage === "bg"}
            onClick={() => {
              setSelLanguage("bg");
            }}
          />
          <Inputs.Button
            text="GERMAN"
            style={{ width: "23%" }}
            selected={selLanguage === "de"}
            onClick={() => {
              setSelLanguage("de");
            }}
          />
          <Inputs.Button
            text="ITALIAN"
            style={{ width: "23%" }}
            selected={selLanguage === "it"}
            onClick={() => {
              setSelLanguage("it");
            }}
          />
        </div>
        <Inputs.TextInput
          label={"Title"}
          style={{ display: selLanguage === "en" ? "block" : "none" }}
          compulsory
          className={errors.title?.en && "failed"}
          {...register(`title.en`, { required: true })}
        />
        <Inputs.TextInput
          label={"Title"}
          style={{ display: selLanguage === "bg" ? "block" : "none" }}
          compulsory
          className={errors.title?.bg && "failed"}
          {...register(`title.bg`, { required: true })}
        />
        <Inputs.TextInput
          label={"Title"}
          style={{ display: selLanguage === "de" ? "block" : "none" }}
          compulsory
          className={errors.title?.de && "failed"}
          {...register(`title.de`, { required: true })}
        />
        <Inputs.TextInput
          label={"Title"}
          style={{ display: selLanguage === "it" ? "block" : "none" }}
          compulsory
          className={errors.title?.it && "failed"}
          {...register(`title.it`, { required: true })}
        />
        <Controller
          control={control}
          name={"text.bg"}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Inputs.RichText
              label={"Text"}
              style={{ display: selLanguage === "bg" ? "block" : "none" }}
              value={value}
              onChange={(e) => {
                setValue("text.bg", e);
              }}
              className={error && "failed"}
              compulsory
            />
          )}
          rules={{ required: true }}
        />
        <Controller
          control={control}
          name={"text.en"}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Inputs.RichText
              label={"Text"}
              style={{ display: selLanguage === "en" ? "block" : "none" }}
              value={value}
              onChange={(e) => {
                setValue("text.en", e);
              }}
              className={error && "failed"}
              compulsory
            />
          )}
          rules={{ required: true }}
        />
        <Controller
          control={control}
          name={"text.de"}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Inputs.RichText
              label={"Text"}
              style={{ display: selLanguage === "de" ? "block" : "none" }}
              value={value}
              onChange={(e) => {
                setValue("text.de", e);
              }}
              className={error && "failed"}
              compulsory
            />
          )}
          rules={{ required: true }}
        />
        <Controller
          control={control}
          name={"text.it"}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Inputs.RichText
              label={"Text"}
              style={{ display: selLanguage === "it" ? "block" : "none" }}
              value={value}
              onChange={(e) => {
                setValue("text.it", e);
              }}
              className={error && "failed"}
              compulsory
            />
          )}
          rules={{ required: true }}
        />

        <Inputs.RadioButtons
          value={selReceiver}
          onChange={(e) => {
            setSelReceiver(e);
            setValue("sendToAll", e);
          }}
          label={"Receiver"}
          compulsory
          options={[
            { label: "All", value: "all" },
            { label: "User", value: "user" },
            { label: "Group", value: "group" },
          ]}
        />
        {selReceiver === "user" ? (
          <Controller
            control={control}
            name={"users"}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Inputs.AutoCompleteDropdown
                optionsArray={autoCompleteUsers}
                getAutocomplete={getUserAutoComplete}
                value={value}
                onChange={(e) => {
                  setValue("users", e);
                }}
              />
            )}
            rules={{ required: true }}
          />
        ) : (
          selReceiver === "group" && (
            <Controller
              control={control}
              name={"groups"}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Inputs.Dropdown
                  optionsArray={GroupOptions}
                  value={value}
                  className={error && "failed"}
                  customStyles={customStyles}
                  onChange={(e) => {
                    setValue("groups", e);
                  }}
                />
              )}
              rules={{ required: true }}
            />
          )
        )}
        <Controller
          control={control}
          name={"leadToAction"}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Inputs.Switch
              value={value}
              onChange={() => {
                onChange(!value);
              }}
              className={errors.leadToAction && "failed"}
              label={"Lead to action"}
              labelOff="No"
              labelOn="Yes"
              compulsory
            />
          )}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  autoCompleteUsers: state.dashboard.autoCompleteUsers,
});

const mapDispatchToProps = (dispatch) => ({
  createNotification: (payload) => dispatch(createNotification(payload)),
  getUserAutoComplete: (payload) => dispatch(getUserAutoComplete(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNotification);

/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { getWallets } from "../../../actions";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";
import { connect } from "react-redux";
import Statistics from "../../Statistics";

const headerElements = [
  {
    name: "Date",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Time",
    query: { filter: "Time", options: [] },
  },
  {
    name: "User",
    query: { filter: "Invitation sent by", options: [] },
  },
  {
    name: "Entry",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Type",
    query: { filter: "Invitation sent to", options: [] },
  },
  {
    name: "Amount",
    query: { filter: "---", options: [] },
  },
  {
    name: "Income sources",
    query: { filter: "---", options: [] },
  },
  {
    name: "Expense sources",
    query: { filter: "City", options: [] },
  },
  {
    name: "Synced bank account",
    query: { filter: "Address", options: [] },
  },
  {
    name: "Shared wallet",
    query: { filter: "Type", options: [] },
  },
  {
    name: "Other wallets",
    query: { filter: "Status", options: [] },
  },
];

const Wallets = ({ getWallets, wallets, period }) => {
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();
  const [innerLoading, setInnerLoading] = useState(false);

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.99 && wallets.totalPages >= curPage && !innerLoading) {
      setInnerLoading(true);
      getWallets({
        page: curPage,
        limit: 20,
        onSuccess: (res) => setInnerLoading(false),
      });
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    getWallets({
      page: 1,
      limit: 20,
    });
  }, []);

  useEffect(() => {
    if (period.startDate !== "") {
      getWallets({
        page: 1,
        limit: 20,
        ...period,
      });
      setCurPage(2);
    }
  }, [period]);

  return (
    <Scrollbars
      style={{ height: "100%", width: "100%" }}
      onUpdate={handleUpdate}
      ref={tableRef}
      id={"scrollbar"}
    >
      <div className="table-header">
        {headerElements?.map((header, i) => {
          return (
            <div
              className="header-cont"
              key={i}
              style={{
                width: i === 8 ? "15%" : i === 3 ? "15%" : "9%",
              }}
            >
              <div className="header">{header.name}</div>
            </div>
          );
        })}
      </div>
      {wallets?.docs?.map((cas, i) => {
        return (
          <div className="table-body-row" key={i}>
            <div className="row-data" style={{ width: "9%" }}>
              {moment(cas?.transactionDate).format("DD.MM.YYYY")}
            </div>
            <div className="row-data" style={{ width: "9%" }}>
              {moment(cas?.transactionDate).format("HH:mm")}
            </div>
            <div className="row-data" style={{ width: "9.3%" }}>
              {cas?.user?.fullName || "---"}
            </div>
            <div className="row-data" style={{ width: "15%" }}>
              {cas?.entry}
            </div>
            <div className="row-data" style={{ width: "9%" }}>
              {cas?.type || "---"}
            </div>
            <div className="row-data" style={{ width: "9%" }}>
              {cas?.amount || "---"}
            </div>
            <div className="row-data" style={{ width: "9%" }}>
              {cas?.wallet?.incomeCategories || "---"}
            </div>
            <div className="row-data" style={{ width: "9%" }}>
              {cas?.wallet?.outcomeCategories || "---"}
            </div>
            <div className="row-data" style={{ width: "15%" }}>
              {cas?.wallet?.bankAccounts || "---"}
            </div>
            <div className="row-data" style={{ width: "9%" }}>
              {cas?.wallet?.sharedWith || "---"}
            </div>
            <div className="row-data" style={{ width: "9%" }}>
              {cas?.user?.otherWallets || "---"}
            </div>
          </div>
        );
      })}
      {innerLoading && (
        <div className="inner-loader-container">
          <Statistics.LoaderInline center />
        </div>
      )}
    </Scrollbars>
  );
};
const mapStateToProps = (state) => ({
  wallets: state.app.wallets,
});
const mapDispatchToProps = (dispatch) => ({
  getWallets: (payload) => dispatch(getWallets(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Wallets);

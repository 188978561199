/* eslint-disable */
import React, { useEffect, useState } from "react";
import Statistics from "..";
import { getAppGrowthStats, getStatisticsSuccess } from "../../../actions";
import "./styles.scss";
import { connect } from "react-redux";

const growthShortcuts = [
  {
    label: "APP DOWNLOADS TOTAL",
    value: "monthlyDownloads",
  },
  {
    label: "USER SIGNUPS",
    value: "monthlyUsers",
  },
  {
    label: "NEWLY ADDED PROPERTIES",
    value: "monthlyProperties",
  },
  {
    label: "NEWLY ADDED NEIGHBOURHOOD COMMUNITIES",
    value: "monthlyCommunities",
  },
  {
    label: "NEWLY ADDED FINANCIAL WALLETS",
    value: "monthlyWallets",
  },
];

const AppGrowth = ({ getAppGrowthStats, growth }) => {
  useEffect(() => {
    getStatisticsSuccess({ type: "growth", loading: true });
    getAppGrowthStats();
  }, []);

  return (
    <div
      className="statistics-sessions-container col"
      style={{ overflow: "hidden auto" }}
    >
      <div className="indicator blue" />
      <h2
        style={{
          paddingBottom: "0px",
          marginLeft: "10px",
          color: "black",
          fontSize: "1.5rem",
          marginTop: "10px",
          marginBottom: "20px",
        }}
      >
        <b>APP GROWTH</b>
      </h2>
      {growthShortcuts.map((pair, i) => {
        return (
          <div
            className="statistics-info-box bigger full-width"
            key={i}
            style={{ height: "300px", marginBottom: "20px" }}
          >
            <div
              style={{
                display: "flex",
                height: "18%",
                border: "1px solid #b0d1fa",
                borderRadius: "6px",
                padding: "7px",
              }}
            >
              <div className="indicator blue" />
              <h4
                className="statistics-info-box-text"
                style={{ fontSize: "1.25rem" }}
              >
                {growth?.[pair.value] &&
                  growth[pair.value]?.reduce((accumulator, object) => {
                    return accumulator + object.count;
                  }, 0)}{" "}
                {pair.label}
              </h4>
            </div>
            <div style={{ padding: "10px" }}>
              <h4 style={{ margin: "0.5rem 0" }}>BY DAY</h4>
              {growth.loading ? (
                <Statistics.LoaderInline />
              ) : (
                <Statistics.BarChart
                  color={"#00D297"}
                  data={Object.fromEntries(
                    growth?.[pair.value]?.map((el) => [el.day, el.count]) || []
                  )}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  growth: state.dashboard.growth,
});
const mapDispatchToProps = (dispatch) => ({
  getAppGrowthStats: (payload) => dispatch(getAppGrowthStats(payload)),
  getStatisticsSuccess: (payload) => dispatch(getStatisticsSuccess(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppGrowth);

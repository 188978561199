/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { getNeighboursMeetings } from "../../../../actions";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";
import { connect } from "react-redux";
import Statistics from "../../../Statistics";

const headerElements = [
  {
    name: "Date",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Time",
    query: { filter: "Time", options: [] },
  },
  {
    name: "Start",
    query: { filter: "Start", options: [] },
  },
  {
    name: "End",
    query: { filter: "End", options: [] },
  },
  {
    name: "Duration",
    query: { filter: "Duration", options: [] },
  },
  {
    name: "Title",
    query: { filter: "Title", options: [] },
    width: "10%"
  },
  {
    name: "Meeting creator",
    query: { filter: "Meeting creator", options: [] },
    width: "10%"
  },
  {
    name: "Number of participants",
    query: { filter: "Number of participants", options: [] },
  },
  {
    name: "Recording URL",
    query: { filter: "Recording URL", options: [] },
    width: "15%"
  },
  {
    name: "Meeting URL",
    query: { filter: "Meeting URL", options: [] },
    width: "15%"
  },
  {
    name: "Country",
    query: { filter: "Address", options: [] },
    width: "10%"
  },
  {
    name: "City",
    query: { filter: "Address", options: [] },
    width: "10%"
  },
  {
    name: "Address",
    query: { filter: "Address", options: [] },
    width: "15%"
  },
  {
    name: "Status",
    query: { filter: "Status", options: [] },
  },
];

const MeetingsCommunities = ({ getNeighboursMeetings, meetings, period }) => {
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();
  const [innerLoading, setInnerLoading] = useState(false);

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && meetings.totalPages >= curPage && !innerLoading) {
      setInnerLoading(true)
      getNeighboursMeetings({
        page: curPage,
        limit: 20,
        onSuccess:(response) => setInnerLoading(false)
      });
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    getNeighboursMeetings({
      page: 1,
      limit: 20,
    });
  }, []);

  useEffect(() => {
    if (period.startDate !== "") {
      getNeighboursMeetings({
        page: 1,
        limit: 20,
        ...period,
      });
      setCurPage(2);
    }
  }, [period]);

  return (
    <Scrollbars
      style={{ height: "100%", width: "100%" }}
      onUpdate={handleUpdate}
      ref={tableRef}
      id={"scrollbar"}
    >
      <div className="table-header" style={{ width: "120%" }}>
        {headerElements?.map((header, i) => {
          return (
            <div
              className="header-cont"
              key={i}
              style={{ width: header?.width ? header?.width : "8%" }}
            >
              <div className="header">{header.name}</div>
            </div>
          );
        })}
      </div>
      {meetings?.docs?.map((meeting, i) => {
        return (
          <div className="table-body-row" key={i} style={{ width: "120%" }}>
            <div className="row-data" style={{ width: "8%" }}>
              {meeting?.createdAt
                ? moment(meeting?.createdAt).format("DD.MM.YYYY")
                : "---"}
            </div>
            <div className="row-data" style={{ width: "8%" }}>
              {meeting?.createdAt
                ? moment(meeting?.createdAt).format("HH:mm")
                : "---"}
            </div>
            <div className="row-data" style={{ width: "8%" }}>
              {meeting?.startedAt
                ? moment(meeting?.startedAt).format("HH:mm")
                : "---"}
            </div>
            <div className="row-data" style={{ width: "8%" }}>
              {meeting?.endedAt
                ? moment(meeting?.endedAt).format("HH:mm")
                : "---"}
            </div>
            <div className="row-data" style={{ width: "8%" }}>
              {meeting?.duration ? `${meeting?.duration} min` : "---"}
            </div>
            <div className="row-data overflow width-8" style={{ width: "10%" }}>
              {meeting?.title || "---"}
            </div>
            <div className="row-data" style={{ width: "10%" }}>
              {meeting?.user?.fullName || "---"}
            </div>
            <div className="row-data overflow width-8">
              {/* {meeting?.participants?.length ?
              meetings.participants?.map(el => el.user).join(", ") : "---"} */}
              {meeting?.participants?.length || "---"}
            </div>
            <div className="row-data href overflow width-8" style={{ width: "15%" }} onClick={() => window.open(meeting?.recordingUrl)}>
              {meeting?.recordingUrl || "---"}
            </div>
            <div className="row-data href overflow width-8" style={{ width: "15%" }} onClick={() => window.open(meeting?.joinUrl)}>
              {meeting?.joinUrl || "---"}
            </div>
            <div className="row-data" style={{ width: "10%" }}>
              {meeting?.community?.country || "---"}
            </div>
            <div className="row-data" style={{ width: "10%" }}>
              {meeting?.community?.city || "---"}
            </div>
            <div className="row-data" style={{ width: "15%" }}>
              {meeting?.community?.address || "---"}
            </div>
            <div className="row-data overflow width-8" style={{ width: "8%" }}>
              {meeting?.status || "---"}
            </div>
          </div>
        );
      })}
       {innerLoading && (
        <div className="inner-loader-container">
          <Statistics.LoaderInline center />
        </div>
      )}
    </Scrollbars>
  );
};
const mapStateToProps = (state) => ({
  meetings: state.app.communityMeetings,
});
const mapDispatchToProps = (dispatch) => ({
  getNeighboursMeetings: (payload) => dispatch(getNeighboursMeetings(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MeetingsCommunities);

/* eslint-disable */
import React, { useEffect, useState } from "react";
import Inputs from "../../components/Inputs";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import {
  addPending,
  getCurrentMerchant,
  editMerchant,
  deleteMerchant,
} from "../../actions";
import Swal from "sweetalert2";
import { history } from "../../config/stores";

const MerchantOperation = ({
  addPending,
  getCurrentMerchant,
  editMerchant,
  deleteMerchant,
}) => {
  const merch_id = window.location.href.split("/")[5];
  const isPending = window.location.href.split("/")[4];
  const {
    register,
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    name: "",
    logo: "",
    keywords: "",
  });

  useEffect(() => {
    getCurrentMerchant({
      id: merch_id,
      isPending: isPending === "pending" ? true : false,
      onSuccess: (res) => {
        setValue("name", res?.name);
        setValue("keywords", res?.keywords?.join(","));
        setValue("logo", res?.logo);
      },
    });
  }, []);

  return (
    <div className="main-container">
      <div className="inner-header-container">
        <div className="left-part">
          <div
            className="close-icon"
            onClick={() => history.push("/merchants")}
          />
          <h2 className="inner-title">
            {isPending === "pending" ? "ADD" : "EDIT"} MERCHANT
          </h2>
        </div>
        {isPending === "pending" ? (
          <Inputs.Button
            text="ADD"
            style={{ width: "8%" }}
            selected
            onClick={handleSubmit((e) => {
              console.log(e);
              const payload = {
                name: e.name,
                keywords: e.keywords.split(","),
                logo: e.logo,
                onSuccess: (res) => {
                  toast.success("Merchant added successfully");
                  history.push("/merchants", { from: "add" });
                },
              };
              addPending(payload);
            })}
          />
        ) : (
          <>
            <Inputs.Button
              text="DELETE"
              style={{ width: "8%" }}
              className={"delete"}
              onClick={() => {
                Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#00d297",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Confirm",
                }).then((result) => {
                  if (result.isConfirmed) {
                    deleteMerchant({
                      id: merch_id,
                      onSuccess: (res) => {
                        toast.success(
                          `Merchant with {id: ${merch_id}} deleted successfully`
                        );
                        history.push("/merchants");
                      },
                    });
                  }
                });
              }}
            />
            <Inputs.Button
              text="EDIT"
              style={{ width: "8%", marginLeft: "1%", marginRight: "1%" }}
              selected
              onClick={handleSubmit((e) => {
                const payload = {
                  id: merch_id,
                  name: e.name,
                  keywords: e.keywords.split(","),
                  logo: e.logo,
                  onSuccess: (res) => {
                    toast.success("Merchant edited successfully");
                    history.push("/merchants");
                  },
                };
                editMerchant(payload);
              })}
            />
          </>
        )}
      </div>
      <div className="body-container" style={{ height: "20%" }}>
        <div className="form-container">
          <Inputs.TextInput
            label={"Name of the merchant"}
            compulsory
            className={errors.name && "failed"}
            {...register(`name`, { required: true })}
          />
          <Inputs.TextInput
            label={"Keywords"}
            compulsory
            className={errors.keywords && "failed"}
            {...register(`keywords`, { required: true })}
          />
          <Controller
            control={control}
            name={"logo"}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Inputs.ImageInput
                label={"Logo"}
                compulsory
                value={value}
                onChange={onChange}
                setValue={setValue}
                className={error && "failed"}
              />
            )}
            rules={{ required: true }}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  currentMerchant: state.merchants.currentMerchant,
});
const mapDispatchToProps = (dispatch) => ({
  addPending: (payload) => dispatch(addPending(payload)),
  editMerchant: (payload) => dispatch(editMerchant(payload)),
  deleteMerchant: (payload) => dispatch(deleteMerchant(payload)),
  getCurrentMerchant: (payload) => dispatch(getCurrentMerchant(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MerchantOperation);

import { MerchantsStats } from "../actions";

const initialState = {
  merchants: {
    totalPages: 0,
    merchants: [],
  },
  pending: {
    totalPages: 0,
    pending: [],
  },
  currentMerchant: {},
};

const handleNewDocs = (state, payload, page, totalPages, key) => {
  if (page === 1)
    return {
      totalPages: totalPages,
      [key]: payload.docs,
    };
  return {
    totalPages: totalPages,
    [key]: [...state[key], ...payload.docs],
  };
};

const merchants = (state = initialState, { type, payload, search } = {}) => {
  switch (type) {
    case MerchantsStats.GET_MERCHANTS_LIST_SUCCESS:
      return {
        ...state,
        merchants: handleNewDocs(
          state.merchants,
          payload.merchants,
          payload.merchants.page,
          payload.merchants.totalPages,
          "merchants"
        ),
      };
    case MerchantsStats.GET_CURRENT_MERCHANT_SUCCESS:
      return {
        ...state,
        currentMerchant: payload.merchant,
      };
    case MerchantsStats.GET_PENDING_SUCCESS:
      return {
        ...state,
        pending: search
          ? handleNewDocs(
              state.merchants,
              payload.merchants,
              payload.merchants.page,
              payload.merchants.totalPages,
              "pending"
            )
          : handleNewDocs(
              state.pending,
              payload.pending,
              payload.pending.page,
              payload.pending.totalPages,
              "pending"
            ),
      };
    default:
      return state;
  }
};

export default merchants;

import React from "react";
import "./styles.scss";

const Switch = ({
  value,
  className,
  label,
  labelOn,
  labelOff,
  onChange,
  compulsory,
}) => {
  return (
    <>
      <label
        style={{
          display: "flex",
          width: "100%",
          fontSize: "1rem",
          marginTop: "3%",
          color: className && "red",
        }}
      >
        {label}{" "}
        {compulsory && (
          <span style={{ paddingLeft: "5px", color: "black" }}>*</span>
        )}
      </label>
      <div
        className={`inputs-switch-container ${value ? "switch-on" : ""}`}
        onClick={onChange}
      >
        <div className={"inputs-switch-inner-container"}>
          <span className={`label-on ${value ? "fade-in" : "fade-out"}`}>
            {labelOn || "Да"}
          </span>
          <div className="inputs-switch-circle" />
          <span className={`label-off ${!value ? "fade-in" : "fade-out"}`}>
            {labelOff || "Не"}
          </span>
        </div>
      </div>
    </>
  );
};

export default Switch;

/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
  getMerchantsList,
  getPendingList,
  deletePending,
  setCurrentMerchant,
  searchMerchants,
} from "../../actions";
import "./styles.scss";
import Inputs from "../../components/Inputs";
import Scrollbars from "react-custom-scrollbars";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { history } from "../../config/stores";
import Statistics from "../../components/Statistics";

const merchantsHeaderElements = [
  {
    name: "Name of the merchant",
    query: { filter: "Name", options: [] },
  },
  {
    name: "Logo",
    query: { filter: "Logo", options: [] },
  },
  {
    name: "Keywords",
    query: { filter: "Keywords", options: [] },
  },
];

const customStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#0083E5", // Custom colour
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#0083E5" : "white",
    padding: 10,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: (window.innerWidth / 100) * 15,
    height: 40,
    display: "flex",
    border: "1px solid rgb(165, 164, 164)",
    borderRadius: "7px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const Merchants = ({
  getMerchantsList,
  merchants,
  getPendingList,
  deletePending,
  pending,
  searchMerchants,
}) => {
  const [selTab, setSelTab] = useState("existing");
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();
  const [query, setQuery] = useState({
    field: "",
    pattern: "",
    isPending: false,
    page: 1,
    limit: 100,
  });
  const [innerLoading, setInnerLoading] = useState(false);

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97) {
      if (
        selTab === "existing" &&
        merchants.totalPages >= curPage &&
        !innerLoading
      ) {
        setInnerLoading(true);
        getMerchantsList({
          page: curPage,
          limit: 15,
          onSuccess: (res) => setInnerLoading(false),
        });
        setCurPage(curPage + 1);
      } else if (
        selTab === "pending" &&
        pending.totalPages >= curPage &&
        !innerLoading
      ) {
        setInnerLoading(true);
        getPendingList({
          page: curPage,
          limit: 15,
          onSuccess: (res) => setInnerLoading(false),
        });
        setCurPage(curPage + 1);
      }
    }
  };

  useEffect(() => {
    getMerchantsList({
      page: 1,
      limit: 15,
    });
    getPendingList({
      page: 1,
      limit: 15,
    });
    if (history.location.state?.from === "add") setSelTab("pending");
  }, []);

  return (
    <div className="main-container">
      <div className="body-container finances">
        <div className="button-flex-container">
          <h2 style={{ marginRight: "2%" }}>MERCHANTS</h2>
          <div style={{ width: "50%", display: "flex" }}>
            <Inputs.Button
              text={"EXISTING"}
              style={{ width: "24%", height: "40px" }}
              onClick={() => {
                setSelTab("existing");
                setInnerLoading(false);
                setCurPage(2);
              }}
              selected={selTab === "existing"}
            />
            <Inputs.Button
              text={"PENDING"}
              style={{ marginLeft: "2%", width: "24%", height: "40px" }}
              onClick={() => {
                setSelTab("pending");
                setCurPage(2);
                setInnerLoading(false);
                setQuery({
                  field: "",
                  pattern: "",
                  isPending: false,
                  page: 1,
                  limit: 100,
                });
              }}
              selected={selTab === "pending"}
            />
          </div>
          <div
            style={{
              width: "50%",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <Inputs.TextInput
              label="Search"
              style={{ width: "50%" }}
              value={query.pattern}
              onChange={(e) => setQuery({ ...query, pattern: e.target.value })}
            />
            <Inputs.Dropdown
              style={{ width: "40%" }}
              single
              customStyles={customStyles}
              optionsArray={[
                {
                  label: "Name of the merchant",
                  value: "name",
                },
                {
                  label: "Keywords",
                  value: "keywords",
                },
              ]}
              onChange={(e) => {
                setQuery({ ...query, field: e.value });
              }}
            />
            <div
              className="search-icon"
              onClick={() => {
                searchMerchants({
                  ...query,
                  isPending: selTab === "pending" ? true : false,
                });
              }}
            />
          </div>
        </div>
        {selTab === "existing" ? (
          <Scrollbars
            style={{ height: "91%", width: "100%", marginTop: "2%" }}
            onUpdate={handleUpdate}
            ref={tableRef}
            renderView={(props) => (
              <div {...props} style={{ ...props.style, overflowX: "hidden" }} />
            )}
            id={"scrollbar"}
          >
            <div className="table-header">
             
              {merchantsHeaderElements?.map((header, i) => {
                return (
                  <div
                    className="header-cont"
                    key={i}
                    style={{
                      width: "15%",
                      marginLeft: i === 0 && "0.5%",
                    }}
                  >
                    <div className="header">{header.name}</div>
                  </div>
                );
              })}
            </div>
            {merchants?.merchants?.map((merch, i) => {
              return (
                <div className="table-body-row" key={i}>
                  <div className="row-data" style={{ width: "15%" }}>
                    {merch?.name || "---"}
                  </div>
                  <div
                    className={`row-data ${merch?.logo?.length > 0 && "href"}`}
                    style={{ width: "15%" }}
                    onClick={() => {
                      if (merch?.logo?.length > 0) window.open(merch?.logo);
                    }}
                  >
                    {merch?.logo?.length > 0
                      ? merch?.logo.split("/")[3].slice(0, 25) + "..."
                      : "---"}
                  </div>
                  <div
                    className="row-data"
                    style={{ width: "66%", borderRight: "none" }}
                  >
                    {merch?.keywords.map((word) => word + ", ") || "---"}
                  </div>
                  <div className="row-data" style={{ width: "4%" }}>
                    <div
                      className="edit-merch-icon"
                      onClick={() => {
                        history.push(`/merchant/accepted/${merch?._id}`);
                      }}
                    />
                  </div>
                </div>
              );
            })}
            {innerLoading && (
              <div className="inner-loader-container">
                <Statistics.LoaderInline center />
              </div>
            )}
          </Scrollbars>
        ) : (
          <Scrollbars
            style={{ height: "91%", width: "100%", marginTop: "2%" }}
            onUpdate={handleUpdate}
            ref={tableRef}
            renderView={(props) => (
              <div {...props} style={{ ...props.style, overflowX: "hidden" }} />
            )}
            id={"scrollbar"}
          >
            <div className="table-header">
              <div
                className="header-cont"
                style={{
                  width: "20%",
                }}
              >
                <div className="header">Name of the merchant</div>
              </div>
              <div
                className="header-cont"
                style={{
                  width: "15%",
                }}
              >
                <div className="header">Logo</div>
              </div>
            </div>
            {pending?.pending?.map((pend, i) => {
              return (
                <div className="table-body-row" key={i}>
                  <div
                    className="row-data"
                    style={{ width: "20%"}}
                  >
                    {pend?.name || "---"}
                  </div>
                  <div
                    className={`row-data ${pend?.logo?.length > 0 && "href"}`}
                    style={{ width: "15%", borderRight: "none"  }}
                    onClick={() => {
                      if (pend?.logo?.length > 0) window.open(pend?.logo);
                    }}
                  >
                    {pend?.logo?.length > 0
                      ? pend?.logo.split("/")[3].slice(0, 25) + "..."
                      : "---"}
                  </div>
                  <div
                    className="row-data"
                    style={{ width: "74%", justifyContent: "flex-end" }}
                  >
                    <Inputs.Button
                      text={"DELETE"}
                      style={{ width: "12%" }}
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure?",
                          text: "You won't be able to revert this!",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#00d297",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Confirm",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            deletePending({
                              id: pend._id,
                              onSuccess: (res) => {
                                toast.success(
                                  `Merchant with {id: ${res._id}} deleted successfully`
                                );
                                setCurPage(2);
                                getPendingList({
                                  limit: 15,
                                  page: 1,
                                });
                                window.scrollTo({
                                  top: tableRef.offsetTop,
                                  left: 0,
                                  behavior: "smooth",
                                });
                              },
                            });
                          }
                        });
                      }}
                      className={"delete"}
                    />
                    <Inputs.Button
                      text={"ADD"}
                      style={{ marginLeft: "2%", width: "12%" }}
                      onClick={() => {
                        history.push(`/add-merchant/pending/${pend?._id}`);
                      }}
                      selected
                    />
                  </div>
                </div>
              );
            })}
            {innerLoading && (
              <div className="inner-loader-container">
                <Statistics.LoaderInline center />
              </div>
            )}
          </Scrollbars>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  merchants: state.merchants.merchants,
  pending: state.merchants.pending,
});
const mapDispatchToProps = (dispatch) => ({
  getMerchantsList: (payload) => dispatch(getMerchantsList(payload)),
  getPendingList: (payload) => dispatch(getPendingList(payload)),
  deletePending: (payload) => dispatch(deletePending(payload)),
  setCurrentMerchant: (payload) => dispatch(setCurrentMerchant(payload)),
  searchMerchants: (payload) => dispatch(searchMerchants(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Merchants);

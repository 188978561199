/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Inputs from "../../../components/Inputs";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { getArticle, getUserAutoComplete } from "../../../actions";
import { history } from "../../../config/stores";
import './styles.scss';

const customStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#0083E5", // Custom colour
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#0083E5" : "white",
    padding: 10,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: (window.innerWidth / 100) * 94.2,
    height: 40,
    display: "flex",
    border: "1px solid rgb(165, 164, 164)",
    borderRadius: "7px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const GroupOptions = [
  {
    label: "Owners",
    value: "Owners",
  },
  {
    label: "Landlords With Tenant",
    value: "Landlords With Tenant",
  },
  {
    label: "Landlords Without Tenant",
    value: "Landlords Without Tenant",
  },
  {
    label: "Tenants With Landlord",
    value: "Tenants With Landlord",
  },
  {
    label: "Tenants Without Landlord",
    value: "Tenants Without Landlord",
  },
  {
    label: "Users Without Property",
    value: "Users Without Property",
  },
];

const ReviewArticle = ({
  getArticle,
  article
}) => {
  const { articleId } = useParams()
  const [data, setData] = useState({
    title: {
      en: "",
      bg: "",
      de: "",
      it: ""
    },
    text: {
      en: "",
      bg: "",
      de: "",
      it: ""
    },
    coverPhoto: ""
  });
  const { register, control, watch, handleSubmit, setValue, formState: { errors } } = useForm({
    defaultValues: {
      title: {
        en: "",
        bg: "",
        de: "",
        it: ""
      },
      text: {
        en: "",
        bg: "",
        de: "",
        it: ""
      },
      coverPhoto: "",
      //leadToAction: false,
    },
  });

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast.error("Missing info", {
        autoClose: 3000,
      });
    }
  }, [errors]);

  useEffect(() => {
    if (articleId) getArticle({ articleId })
  }, [articleId]);

  useEffect(() => {
    setData(article)
  }, [article]);

  return (
    <div className="main-container article">
      <div className="inner-header-container">
        <div className="left-part">
          <div className="close-icon" onClick={() => history.push("/website")} />
          <h2 className="inner-title">Review article</h2>
        </div>
        <Inputs.Button
          text="EDIT"
          style={{ width: "8%" }}
          selected
          onClick={() => history.push(`/website/edit-article/${articleId}`)}
        />
      </div>
      <div className="body-container">

        <Inputs.TextInput
          label={"Title"}
          style={{ display: "block" }}
          disabled
          value={data?.title?.bg}
        />

        <Inputs.RichText
          label={"Article"}
          style={{ display: "block", height: "86%" }}
          inputStyle={{ height: "95%" }}
          value={data?.text?.bg}
          disabled
          light
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  article: state.website.article,
});

const mapDispatchToProps = (dispatch) => ({
  getArticle: (payload) => dispatch(getArticle(payload)),
  getUserAutoComplete: (payload) => dispatch(getUserAutoComplete(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewArticle);

/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { getNeighborsPolls } from "../../../../actions";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";
import { connect } from "react-redux";
import Statistics from "../../../Statistics";
const headerElements = [
  {
    name: "Date",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Time",
    query: { filter: "Time", options: [] },
  },
  {
    name: "Created By",
    query: { filter: "Invitation sent by", options: [] },
  },
  {
    name: "Possible answers",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Voted",
    query: { filter: "Invitation sent to", options: [] },
  },
  {
    name: "Status",
    query: { filter: "---", options: [] },
  },
  {
    name: "Country",
    query: { filter: "---", options: [] },
  },
  {
    name: "City",
    query: { filter: "---", options: [] },
  },
  {
    name: "Name",
    query: { filter: "---", options: [] },
  },
  {
    name: "Number of participants",
    query: { filter: "---", options: [] },
  },
];

const Polls = ({ getNeighborsPolls, polls, period }) => {
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();
  const [innerLoading, setInnerLoading] = useState(false);

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.99 && polls.totalPages >= curPage && !innerLoading) {
      setInnerLoading(true);
      getNeighborsPolls({
        page: curPage,
        limit: 20,
        onSuccess: (res) => setInnerLoading(false),
      });
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    getNeighborsPolls({
      page: 1,
      limit: 20,
    });
  }, []);

  useEffect(() => {
    if (period.startDate !== "") {
      getNeighborsPolls({
        page: 1,
        limit: 20,
        ...period,
      });
      setCurPage(2);
    }
  }, [period]);

  return (
    <Scrollbars
      style={{ height: "100%", width: "100%" }}
      onUpdate={handleUpdate}
      ref={tableRef}
      id={"scrollbar"}
    >
      <div className="table-header">
        {headerElements?.map((header, i) => {
          return (
            <div
              className="header-cont"
              key={i}
              style={{
                width: i === 8 ? "15%" : "9%",
              }}
            >
              <div className="header">{header.name}</div>
            </div>
          );
        })}
      </div>
      {polls?.docs?.map((poll, i) => {
        return (
          <div className="table-body-row" key={i}>
            <div className="row-data" style={{ width: "9%" }}>
              {moment(poll?.createdAt).format("DD.MM.YYYY")}
            </div>
            <div className="row-data" style={{ width: "9%" }}>
              {moment(poll?.createdAt).format("HH:mm")}
            </div>
            <div className="row-data" style={{ width: "9.3%" }}>
              {poll?.author?.fullName || "---"}
            </div>
            <div className="row-data" style={{ width: "9%" }}>
              {poll?.optionsCount || "---"}
            </div>
            <div className="row-data" style={{ width: "9%" }}>
              {poll?.votesCount || "---"}
            </div>
            <div className="row-data" style={{ width: "9%" }}>
              {poll?.isClosed === true ? "Closed" : "Opened" || "---"}
            </div>
            <div className="row-data" style={{ width: "9%" }}>
              {poll?.community?.country || "---"}
            </div>
            <div className="row-data" style={{ width: "9%" }}>
              {poll?.community?.city || "---"}
            </div>
            <div className="row-data" style={{ width: "15%" }}>
              {poll?.title || "---"}
            </div>
            <div className="row-data" style={{ width: "9%" }}>
              {poll?.community?.membersCount || "---"}
            </div>
          </div>
        );
      })}
      {innerLoading && (
        <div className="inner-loader-container">
          <Statistics.LoaderInline center />
        </div>
      )}
    </Scrollbars>
  );
};
const mapStateToProps = (state) => ({
  polls: state.app.polls,
});
const mapDispatchToProps = (dispatch) => ({
  getNeighborsPolls: (payload) => dispatch(getNeighborsPolls(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Polls);

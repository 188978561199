/* eslint-disable */
import React, { useEffect, useState } from "react";
import Statistics from "..";
import { getAppSessions, getStatisticsSuccess } from "../../../actions";
import "./styles.scss";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import moment from "moment";
import Inputs from "../../Inputs";


const options = [
  {
    label: "Last 24 hours",
    value: moment().subtract(1, "days").startOf("day").toString(),
  },
  {
    label: "Last 7 days",
    value: moment().subtract(7, "days").startOf("day").toString(),
  },
  {
    label: "Last 31 days",
    value: moment().subtract(31, "days").startOf("day").toString(),
  },
  {
    label: "All",
    value: null,
  },
];


const AppSessions = ({ getAppSessions, appSessions }) => {
  useEffect(() => {
    getStatisticsSuccess({ type: "appSessions", loading: true });
    getAppSessions();
  }, []);

  const [selPeriod, setSelPeriod] = useState({
    startDate: "",
    endDate: moment().toString(),
  });


  useEffect(() => {
    if (selPeriod.startDate !== "") {
      getAppSessions({
        ...selPeriod,
      });
    }
  }, [selPeriod]);


  return (
    <div
      className="statistics-sessions-container col"
      style={{ overflow: "auto" }}
    >
      <div
        className="statistics-sessions-header row"
        style={{
          backgroundColor: "#F7F8FF",
          borderColor: "#F7F8FF",
          margin: "0px",
          marginTop: "10px",
          marginBottom: "20px",
          justifyContent: "flex-start",
        }}
      >
        <div className="indicator blue" />
        <h2
          style={{ paddingBottom: "0px", marginLeft: "10px", color: "black",display:"flex",width:"100%" }}
        >
          <span style={{width:"90%"}}>
            {appSessions?.totalSessions}
            <b style={{marginLeft:"10px"}}>TOTAL IN APP SESSIONS</b>
          </span>
          <div style={{ display: "flex" }}>
            {" "}
            <p
              style={{
                fontSize: "12px",
                whiteSpace: "nowrap",
                margin: "0px 10px",
              }}
            >
              {options.filter((opt) => opt.value === selPeriod.startDate)
                .length > 0
                ? options.filter((opt) => opt.value === selPeriod.startDate)[0]
                    .label
                : "All"}
            </p>
            <Popup
              className="popup-header-options-container"
              trigger={<div className={`settings-icon`} />}
              position="bottom right"
            >
              {(close) => (
                <Inputs.RadioButtons
                  options={options}
                  column
                  value={selPeriod.startDate}
                  onChange={(period) => {
                    setSelPeriod({
                      ...selPeriod,
                      startDate: period?.value === null ? null : period,
                    });
                    close();
                  }}
                />
              )}
            </Popup>
          </div>
        </h2>
      </div>
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <div className="statistics-info-box" style={{ height: "unset" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div className="indicator gray" />
            <h4
              className="statistics-info-box-text"
              style={{ whiteSpace: "normal", fontSize: "1.25rem" }}
            >
              {appSessions?.uniqueUsers} UNIQUE ACTIVE USERS
            </h4>
          </div>
        </div>
        <div className="statistics-info-box" style={{ height: "unset" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div className="indicator gray" />
            <h4
              className="statistics-info-box-text"
              style={{ whiteSpace: "normal", fontSize: "1.25rem" }}
            >
              {appSessions?.average?.toFixed(0)} AVERAGE USER SESSIONS
            </h4>
          </div>
        </div>
      </div>

      <div
        className="statistics-sessions-content"
        style={{ overflow: "unset" }}
      >
        <div
          className="statistics-sessions-section col"
          style={{ marginBottom: "20px" }}
        >
          <h4>SESSION LENGTH</h4>
          {appSessions.loading ? (
            <Statistics.LoaderInline />
          ) : (
            <Statistics.Horizontal
              data={
                appSessions?.sessionsLength &&
                Object.keys(appSessions?.sessionsLength)?.map((key) => {
                  return {
                    value: key,
                    label: appSessions?.sessionsLength?.[key],
                  };
                })
              }
            />
          )}
        </div>
        <div
          className="statistics-sessions-section col"
          style={{ marginBottom: "20px" }}
        >
          <h4>BY USERS</h4>
          {appSessions.loading ? (
            <Statistics.LoaderInline />
          ) : (
            <Statistics.Horizontal
              data={
                appSessions?.countByRole &&
                Object.keys(appSessions?.countByRole)?.map((key) => {
                  return {
                    value: key,
                    label: appSessions?.countByRole?.[key],
                  };
                })
              }
            />
          )}
        </div>
        <div
          className="statistics-sessions-section col"
          style={{ marginBottom: "20px" }}
        >
          <h4>TIMES OPENED</h4>
          {appSessions.loading ? (
            <Statistics.LoaderInline />
          ) : (
            <Statistics.Horizontal
              data={
                appSessions?.timesOpened &&
                Object.keys(appSessions?.timesOpened)?.map((key) => {
                  return {
                    value: key,
                    label: appSessions?.timesOpened?.[key],
                  };
                })
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  appSessions: state.dashboard.appSessions,
});
const mapDispatchToProps = (dispatch) => ({
  getAppSessions: (payload) => dispatch(getAppSessions(payload)),
  getStatisticsSuccess: (payload) => dispatch(getStatisticsSuccess(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSessions);

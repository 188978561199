/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";

const Button = ({ text, style, disabled, onClick, selected, className,cancel }) => {
  return (
    <div
      className={`button-container ${className} ${selected && "selected"} ${
        disabled && "disabled"
      } ${
        cancel && "cancel"
      }`}
      style={style}
      onClick={onClick}
    >
      <b style={{ textTransform: "uppercase" }}>{text}</b>
    </div>
  );
};
export default Button;

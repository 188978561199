/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { getNotifications } from "../../../actions";
import { Scrollbars } from "react-custom-scrollbars";
import "./styles.scss";
import { connect } from "react-redux";
import moment from "moment";
import { history } from "../../../config/stores";

const Notifications = ({ getNotifications, notifications, totalPages }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const tableRef = useRef();
  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && totalPages >= currentPage) {
      tableRef.current.scrollTop(30);
      getNotifications({
        pageNumber: currentPage,
        pageSize: 20,
      });
      setCurrentPage(currentPage + 1);
    }
  };
  useEffect(() => {
    getNotifications({
      page: 1,
      limit: 20,
    });
    setCurrentPage(currentPage + 1);
  }, []);

  return (
    <div
      className="statistics-sessions-container col"
      style={{ overflow: "hidden auto" }}
    >
      <h2
        style={{
          paddingBottom: "0px",
          marginLeft: "10px",
          color: "black",
          fontSize: "1.5rem",
          marginTop: "10px",
        }}
      >
        <b>NOTIFICATIONS</b>
      </h2>
      <Scrollbars
        style={{ height: "94%", width: "100%" }}
        onUpdate={handleUpdate}
        renderView={(props) => (
          <div {...props} style={{ ...props.style, overflowX: "hidden" }} />
        )}
        ref={tableRef}
        id={"scrollbar"}
      >
        {notifications?.map((not, i) => {
          return (
            <div className="card-container" key={i}>
              <h5 className="card-title">{not?.title}</h5>
              <div className="card-secondary-header">
                <p className="card-text">
                  Sent on: <b>{moment(not?.createdAt).format("DD.MM.YYYY")}</b>
                </p>
                <div className="divider vertical" />
                <p className="card-text smaller">
                  Clicks: <b>---</b>
                </p>
                <div className="divider vertical" />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p className="card-text">
                    Sent to: <b>{not?.receiversCount} users</b>
                  </p>
                  <div
                    className="info-icon black"
                    style={{ width: "20px", marginLeft: "3px" }}
                    onClick={() =>
                      history.push(`/notification/${not?.customId}/receivers`)
                    }
                  />
                </div>
              </div>
              <div className="divider" />
              <div
                className="card-text"
                style={{ width: "100%" }}
                dangerouslySetInnerHTML={{ __html: not?.text }}
              ></div>
              <div className="divider" />
              <div className="card-footer">
                <p className="card-text">
                  Target:<b> {not?.target?.map((tar) => tar + " ")}</b>
                </p>
                <div className="divider vertical" />
                <p className="card-text">
                  Lead to action:<b> ---</b>
                </p>
              </div>
            </div>
          );
        })}
      </Scrollbars>
      <div
        className="add-notification"
        onClick={() => history.push("/notification-send")}
      >
        <div className="plus-icon" />
        <h4 style={{ marginLeft: "3%" }}>SEND OUT CUSTOM NOTIFICATION</h4>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  notifications: state.dashboard.notifications,
  totalPages: state.dashboard.totalPages,
});
const mapDispatchToProps = (dispatch) => ({
  getNotifications: (payload) => dispatch(getNotifications(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);

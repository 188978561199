/* eslint-disable */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getProperties, getStats } from "../../actions";
import AppGrids from "../../components/AppGrids";
import Popup from "reactjs-popup";
import "./styles.scss";
import Inputs from "../../components/Inputs";
import moment from "moment";

const cardTypes = [
  {
    header: "Mutual property management",
    firstLine: "Total mutual property management",
    secondLine: "Total Users in mutual property management",
    firstField: "acceptedMutualManagementCount",
    secondField: "usersInMutualManagement",
    tab: "mutual-management",
  },
  {
    header: "Landlord - tenant relationship",
    firstLine: "Total number of relationships",
    secondLine: "Total users in a relationship",
    firstField: "acceptedCoManagementCount",
    secondField: "usersInCoManagement",
    tab: "land-tenant",
  },
  {
    header: "Rent payments",
    firstLine: "Total number of paid rents",
    secondLine: "Total number of users paying rent",
    firstField: "rentPaymentsCount",
    secondField: "usersPayingRentCount",
    tab: "rent-payment",
  },
  {
    header: "Utility bills",
    firstLine: "Total number of Utility bills payments",
    secondLine: "Total number of users paying Utility bills",
    firstField: "paymentsCount",
    secondField: "usersPayingBillsCount",
    tab: "utility-bills",
  },
  {
    header: "Items",
    firstLine: "Total number of created items",
    secondLine: "Total number of users creating items",
    firstField: "filesCount",
    secondField: "usersCreatingFiles",
    tab: "items",
  },
  {
    header: "Services",
    firstLine: "Total number of requested services",
    secondLine: "Total number of users requesting services",
    firstField: "serviceSearchesCount",
    secondField: "usersSearchingServices",
    tab: "services",
  },
  {
    header: "Calendar",
    firstLine: "Total number of created calendar events",
    secondLine: "Total number of users creating events",
    firstField: "propertyEventsCount",
    secondField: "usersCreatingPropertyEvents",
    tab: "property-calendar",
  },
  /* {
    header: "Cases",
    firstLine: "Total number of created cases",
    secondLine: "Total number of users creating cases",
    firstField: "casesCount",
    secondField: "usersCreatingCases",
    tab: "cases",
  },*/
  {
    header: "Wallets",
    firstLine: "Total number of created wallets",
    secondLine: "Total number of synced bank accounts",
    thirdLine: "Total number of users creating wallets",
    fourthLine: "Total number of users syncing bank accounts",
    firstField: "walletsCount",
    secondField: "bankAccountsCount",
    thirdField: "usersCreatingWallets",
    fourthField: "usersCreatingBankAccounts",
    tab: "wallets",
  },
  {
    separator: true,
  },
  {
    header: "Boards",
    firstLine: "Total number of board messages",
    secondLine: "Total number of users creating board messages",
    firstField: "noticesCount",
    secondField: "usersCreatingNotices",
    tab: "notices",
  },
  {
    header: "Calendar",
    firstLine: "Total number of created events",
    secondLine: "Total number of users with created events",
    firstField: "communityEventsCount",
    secondField: "usersCreatingCommunityEvents",
    tab: "community-calendar",
  },
  {
    header: "Voting polls",
    firstLine: "Total number of Voting polls",
    secondLine:
      "Total number of users creating and participating in Voting polls",
    firstField: "pollsCount",
    secondField: "usersCreatingPolls/usersVoting",
    tab: "voting-polls",
  },
  {
    header: "Files",
    firstLine: "Total number of files",
    secondLine:
      "Total number of users who created files",
    firstField: "filesCount",
    secondField: "usersCreatingFiles",
    tab: "files",
  },
  {
    header: "Services",
    firstLine: "Total number of services searches",
    secondLine:
      "Users in mutual management",
    firstField: "communityServiceSearchesCount",
    secondField: "usersInMutualManagement",
    tab: "community-services",
  },
  {
    header: "Meetings",
    firstLine: "Total number of meetings",
    secondLine:
      "Users creating meetings",
    firstField: "communityMeetingsCount",
    secondField: "communityUsersCreatingMeetings",
    tab: "community-meetings",
  },
  {
    header: "Wallets",
    firstLine: "Total number of wallets",
    secondLine: "",
    firstField: "communityWalletsCount",
    secondField: "",
    tab: "community-wallets",
  },
  // {
  //   header: "Payments",
  //   firstLine: "Total number of made payments",
  //   secondLine:
  //     "Total number of users making payments (initializing and paying)",
  //   firstField: "fundraisersCount",
  //   secondField: "usersCreatingFundraisers",
  //   tab: "payments",
  // },
];

const App = ({ stats, getStats }) => {
  const [selTab, setSelTab] = useState("mutual-management");
  const [selPeriod, setSelPeriod] = useState({
    startDate: null,
    endDate: moment().toString(),
  });

  const options = [
    {
      label: "Last 24 hours",
      value: moment().subtract(1, "days").startOf("day").toString(),
    },
    {
      label: "Last 7 days",
      value: moment().subtract(7, "days").startOf("day").toString(),
    },
    {
      label: "Last 31 days",
      value: moment().subtract(31, "days").startOf("day").toString(),
    },
    {
      label: "All",
      value: null,
    },
  ];

  useEffect(() => {
    getStats({
      onSuccess: (res) => {},
    });
  }, []);

  return (
    <div className="main-container">
      <div
        style={{
          height: "25%",
          width: "100%",
          padding: "10px",
          display: "flex",
          overflowX: "scroll",
          overflowY: "hidden",
        }}
      >
        {cardTypes.map((card,i) => {
          return (
            <React.Fragment key={i}>
              {card.separator ? (
                <div className="separator">NEIGHBOURS</div>
              ) : (
                <div
                  className={`card-grid ${card.tab === "wallets" && "bigger"}`}
                >
                  <div
                    className={`card-header ${
                      card.tab === selTab && "selected"
                    }`}
                    onClick={() => {
                      setSelTab(card.tab);
                    }}
                  >
                    <h2
                      className={`${
                        card.tab === selTab ? "font-bold" : "font-semiBold"
                      }`}
                      style={{
                        textTransform: "uppercase",
                        width: "93%",
                        marginLeft: "5px",
                      }}
                    >
                      {card.header}
                    </h2>
                    <p style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                      {options.filter(
                        (opt) => opt.value === selPeriod.startDate
                      ).length > 0
                        ? options.filter(
                            (opt) => opt.value === selPeriod.startDate
                          )[0].label
                        : "All"}
                    </p>
                    <Popup
                      className="popup-header-options-container"
                      trigger={
                        <div
                          className={`settings-icon ${
                            card.tab === selTab && "selected"
                          }`}
                        />
                      }
                      position="bottom right"
                    >
                      {(close) => (
                        <Inputs.RadioButtons
                          options={options}
                          column
                          value={selPeriod.startDate}
                          onChange={(period) => {
                            setSelPeriod({
                              ...selPeriod,
                              startDate: period?.value === null ? null : period,
                            });
                            close();
                          }}
                        />
                      )}
                    </Popup>
                  </div>
                  <div
                    className={`card-body ${
                      card.tab === "wallets" && "bigger"
                    }`}
                  >
                    <div
                      className={`card-row ${
                        card.tab === "wallets" && "semi-width"
                      }`}
                    >
                      <div className="indicator blue" />
                      <p className="row-data">
                        <b>{stats?.[card?.firstField] || "---"} </b>
                        {card.firstLine}
                      </p>
                    </div>
                    {card?.secondField && 
                    <div
                      className={`card-row ${
                        card.tab === "wallets" && "semi-width"
                      }`}
                    >
                     <div className="indicator blue" />
                      <p className="row-data">
                        <b>
                          {card?.secondField?.includes("/")
                            ? stats?.[card?.secondField.split("/")[0]] +
                              stats?.[card?.secondField.split("/")[1]]
                            : stats?.[card?.secondField] || "---"}{" "}
                        </b>
                        {card.secondLine}
                      </p>
                    </div>}
                    {card.tab === "wallets" && (
                      <>
                        <div
                          className={`card-row ${
                            card.tab === "wallets" && "semi-width"
                          }`}
                        >
                          <div className="indicator gray" />
                          <p className="row-data">
                            <b>{stats?.[card?.thirdField] || "---"} </b>
                            {card.thirdLine}
                          </p>
                        </div>
                        <div
                          className={`card-row ${
                            card.tab === "wallets" && "semi-width"
                          }`}
                        >
                          <div className="indicator gray" />
                          <p className="row-data">
                            <b>{stats?.[card?.fourthField] || "---"} </b>
                            {card.fourthLine}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
      <div className="body-container" style={{ height: "73%" }}>
        {selTab === "mutual-management" ? (
          <AppGrids.Mutual period={selPeriod} />
        ) : selTab === "rent-payment" ? (
          <AppGrids.RentPayments period={selPeriod} />
        ) : selTab === "utility-bills" ? (
          <AppGrids.UtilityBills period={selPeriod} />
        ) : selTab === "property-calendar" ? (
          (<AppGrids.CalendarEventsProperties
            period={selPeriod}
          /> /*: selTab === "cases" ? (
          <AppGrids.Cases period={selPeriod} />
        ) */ /*: selTab === "cases" ? (
          <AppGrids.Cases period={selPeriod} />
        ) */)
        ) : selTab === "wallets" ? (
          <AppGrids.Wallets period={selPeriod} />
        ) : selTab === "notices" ? (
          <AppGrids.Notices period={selPeriod} />
        ) : selTab === "community-calendar" ? (
          <AppGrids.CalendarCommunities period={selPeriod} />
        ) : selTab === "land-tenant" ? (
          <AppGrids.CoInvitations period={selPeriod} />
        ) : selTab === "items" ? (
          <AppGrids.Files period={selPeriod} />
        ) : selTab === "voting-polls" ? (
          <AppGrids.Polls period={selPeriod} />
        ) : selTab === "payments" ? (
          <AppGrids.Payments period={selPeriod} />
        )  : selTab === "files" ? (
          <AppGrids.FilesCommunities period={selPeriod} />
        )  : selTab === "community-services" ? (
          <AppGrids.ServicesCommunities period={selPeriod} />
        ) : selTab === "community-meetings" ? (
          <AppGrids.MeetingsCommunities period={selPeriod} />
        ) : selTab === "community-wallets" ? (
          <AppGrids.WalletsCommunities period={selPeriod} />
        ) : (
          selTab === "services" && <AppGrids.Services period={selPeriod} />
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  stats: state.app.stats,
});
const mapDispatchToProps = (dispatch) => ({
  getProperties: (payload) => dispatch(getProperties(payload)),
  getStats: (payload) => dispatch(getStats(payload))
});
export default connect(mapStateToProps, mapDispatchToProps)(App);

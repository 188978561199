/* eslint-disable */
import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { MerchantsStats } from "../actions";
import { API_URL } from "../config/settings";
import { Headers } from "../utilites/Headers";
import { toast } from "react-toastify";

export const getMerchantsListEpic = (action$) =>
  action$.pipe(
    ofType(MerchantsStats.GET_MERCHANTS_LIST),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/merchants/list`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: MerchantsStats.GET_MERCHANTS_LIST_SUCCESS,
              payload: response,
            });
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            toast.error(err?.response?.error)
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getPendingListEpic = (action$) =>
  action$.pipe(
    ofType(MerchantsStats.GET_PENDING),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/merchants/pending/list`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: MerchantsStats.GET_PENDING_SUCCESS,
              payload: response,
            });
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            toast.error(err?.response?.error)
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const searchMerchantsEpic = (action$) =>
  action$.pipe(
    ofType(MerchantsStats.SEARCH_MERCHANTS),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/merchants/search`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (payload.isPending) {
              obs.next({
                type: MerchantsStats.GET_PENDING_SUCCESS,
                payload: response,
                search: true,
              });
            } else {
              obs.next({
                type: MerchantsStats.GET_MERCHANTS_LIST_SUCCESS,
                payload: response,
              });
            }
            console.log(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            toast.error(err?.response?.error)
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getCurrentMerchantEpic = (action$) =>
  action$.pipe(
    ofType(MerchantsStats.GET_CURRENT_MERCHANT),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/merchants/by-id`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: MerchantsStats.GET_CURRENT_MERCHANT_SUCCESS,
              payload: response,
            });
            payload.onSuccess(response.merchant);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            toast.error(err?.response?.error)
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const addPendingEpic = (action$) =>
  action$.pipe(
    ofType(MerchantsStats.ADD_PENDING),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/merchants`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            payload.onSuccess(response);

            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error(err?.response?.error)
            obs.complete();
          })
        )
      )
    )
  );
export const deletePendingEpic = (action$) =>
  action$.pipe(
    ofType(MerchantsStats.DELETE_PENDING),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/merchants/pending/${payload.id}`,
        method: "DELETE",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error(err?.response?.error)
            obs.complete();
          })
        )
      )
    )
  );
export const editMerchantEpic = (action$) =>
  action$.pipe(
    ofType(MerchantsStats.EDIT_MERCHANT),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/merchants/${payload.id}`,
        method: "PUT",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error(err?.response?.error)
            obs.complete();
          })
        )
      )
    )
  );
export const deleteMerchantEpic = (action$) =>
  action$.pipe(
    ofType(MerchantsStats.DELETE_MERCHANT),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/merchants/${payload.id}`,
        method: "DELETE",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error(err?.response?.error)
            obs.complete();
          })
        )
      )
    )
  );

/* eslint-disable */
import React, { useEffect } from "react";
import { history } from "./config/stores";
import { useDispatch } from "react-redux";
import { Router } from "react-router-dom";
import { User } from "./utilites/User";
import Routes from "./components/routes/Routes";
import { loginSuccessFromLocalStorage } from "./actions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-quill/dist/quill.snow.css'
import "./App.scss";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    User.isAuthenticated
      ? loginSuccessFromLocalStorage(User.getUser(), User.getToken())
      : history.push("/login");
  }, [User.isAuthenticated]);

  return (
    <Router history={history}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes />
    </Router>
  );
};

export default App;

/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import { connect } from "react-redux";
import { logOut } from "../../actions";
import "./styles.scss";
import { User } from "../../utilites/User";

function useOutsideAlerter(ref) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        document.getElementById("drowdown-content").style.display = "none";
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const Header = ({ ref, logOut }) => {
  const location = useLocation().pathname;
  const [showDropdown, setShowDropdown] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div className="header-container">
      <div className="left-header-container">
        <div className="logo" onClick={() => history.push("/")} />
      </div>

      <div className={`middle-header-container`}>
        <div
          className={`header-element ${location === "/" && "selected"}`}
          onClick={() => history.push("/")}
        >
          <div
            className={`header-inner-element ${location === "/" && "selected"}`}
          >
            DASHBOARD
          </div>
        </div>
        <div
          className={`header-element ${location === "/app" && "selected"}`}
          onClick={() => history.push("/app")}
        >
          <div
            className={`header-inner-element ${
              location === "/app" && "selected"
            }`}
          >
            APP
          </div>
        </div>
        <div
          className={`header-element ${location === "/website" && "selected"}`}
          onClick={() => history.push("/website")}
        >
          <div
            className={`header-inner-element ${
              location === "/website" && "selected"
            }`}
          >
            WEBSITE
          </div>
        </div>
        {/*  <div
          className={`header-element ${location === "/website" && "selected"}`}
          onClick={() => history.push("/website")}
        >
          <div
            className={`header-inner-element ${
              location === "/website" && "selected"
            }`}
          >
            WEBSITE
          </div>
        </div>
        <div
          className={`header-element ${location === "/finances" && "selected"}`}
          onClick={() => history.push("/finances")}
        >
          <div
            className={`header-inner-element ${
              location === "/finances" && "selected"
            }`}
          >
            FINANCES
          </div>
        </div>*/}
      </div>

      <div className="right-container">
        <div
          className={`dropdown ${showDropdown && "show"}`}
          onClick={() => {
            if (showDropdown) setShowDropdown(false);
            else setShowDropdown(true);
          }}
        ></div>
        {showDropdown && (
          <>
            <div
              className="dropdown-content"
              ref={wrapperRef}
              id="drowdown-content"
            >
              <div className="profile">
                <div className="text">Menu</div>
                <div
                  className="exit"
                  onClick={() => {
                    setShowDropdown(false);
                  }}
                ></div>
              </div>
              <div className="info">
                <div className="avatar" />
                <div
                  style={{
                    height: "100%",
                    width: "91%",
                    padding: "0.6vw",
                    marginLeft: "3%",
                  }}
                >
                  {" "}
                  <div className="name">
                    <b>{User.getUser().fullName}</b>
                  </div>
                  <div className="phone-number">{User.getUser().email}</div>
                </div>
              </div>
              <div
                className="user-management"
                onClick={() => {
                  history.push("/user-management");
                  setShowDropdown(false);
                }}
              >
                <div className="user-management-text">User managment</div>
                <div className="user-management-icon" />
              </div>
              <div
                className="user-management"
                style={{margin:"10px 8px"}}
                onClick={() => {
                  history.push("/merchants");
                  setShowDropdown(false);
                }}
              >
                <div className="user-management-text">Merchants</div>
                <div className="merchants-icon" />
              </div>
              <div
                className="log-out"
                onClick={() => {
                  logOut();
                  history.push("/login");
                }}
              >
                <div className="log-out-text">Log out</div>
                <div className="log-out-icon"></div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  token: state.login.token,
});
const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(logOut()),
});
export default connect(null, mapDispatchToProps)(Header);

/* eslint-disable */
import React, { useEffect, useState } from "react";
import Statistics from "..";
import {
  getStatisticsSessions,
  getStatisticsSuccess,
  getActiveUsers,
} from "../../../actions";
import "./styles.scss";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import moment from "moment";
import Inputs from "../../Inputs";

const infoBoxes = [
  {
    label: "Landlords with tenant",
    value: "landlordsWithTenant",
  },
  {
    label: "Landlords without tenant",
    value: "landlordsWithoutTenant",
  },
  {
    label: "Tenants",
    value: "tenant",
  },
  {
    label: "Tenants without landlord",
    value: "tenantsWithoutLandlord",
  },
  {
    label: "Owners",
    value: "owners",
  },
  {
    label: "Users without property",
    value: "usersWithoutProperty",
  },
];

const options = [
  {
    label: "Last 24 hours",
    value: moment().subtract(1, "days").startOf("day").toString(),
  },
  {
    label: "Last 7 days",
    value: moment().subtract(7, "days").startOf("day").toString(),
  },
  {
    label: "Last 31 days",
    value: moment().subtract(31, "days").startOf("day").toString(),
  },
  {
    label: "All",
    value: null,
  },
];

const Registrations = ({
  getStatisticsSuccess,
  getStatisticsSessions,
  sessions,
}) => {
  const [selected, setSelected] = useState("country");
  const [selectedWallet, setSelectedWallet] = useState("wallet");
  const [selPeriod, setSelPeriod] = useState({
    startDate: null,
    endDate: moment().toString(),
  });

  useEffect(() => {
    getStatisticsSuccess({ type: "sessions", loading: true });
    getStatisticsSessions();
  }, []);

  useEffect(() => {
    if (selPeriod.startDate !== "") {
      getStatisticsSessions({
        ...selPeriod,
      });
    }
  }, [selPeriod]);


  return (
    <div
      className="statistics-sessions-container col"
      style={{ overflow: "auto" }}
    >
      <div
        className="statistics-sessions-header row"
        style={{
          backgroundColor: "#002C78",
          borderColor: "#002C78",
          justifyContent: "flex-start",
          margin: "0px",
          marginTop: "10px",
        }}
      >
        <div className="indicator" />
        <h2
          style={{
            paddingBottom: "0px",
            marginLeft: "10px",
            display: "flex",
            width: "98%",
            justifyContent: "space-between",
          }}
        >
          <span>
            {sessions.usersCount} <b>REGISTERED USERS</b>
          </span>
        </h2>
      </div>
      <div
        className="statistics-sessions-header secondery-header"
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        {infoBoxes?.map((box, i) => {
          return (
            <div className="statistics-info-box" key={i}>
              <div style={{ display: "flex", height: "50%" }}>
                <div className="indicator gray" />
                <h4 className="statistics-info-box-text">
                  {i === infoBoxes.length - 1
                    ? sessions?.[box.value]
                    : sessions?.countByRole?.[box.value]}
                </h4>
              </div>
              <h4
                className="statistics-info-box-text"
                style={{
                  fontSize: "0.95rem",
                  margin: "10px 0px",
                  width: "100%",
                  textTransform: "uppercase",
                }}
              >
                {box.label}
              </h4>
            </div>
          );
        })}
      </div>
      <div
        className="statistics-sessions-content"
        style={{ overflow: "unset" }}
      >
        <div
          className="statistics-sessions-section col"
          style={{ marginBottom: "20px" }}
        >
          <h4>LOCATION</h4>
          <div className="selector-container">
            <div
              className={`select ${selected === "country" && "selected"}`}
              onClick={() => setSelected("country")}
            >
              BY COUNTRY
            </div>
            <div
              className={`select ${selected === "city" && "selected"}`}
              onClick={() => setSelected("city")}
            >
              BY CITY
            </div>
          </div>
          {sessions.loading ? (
            <Statistics.LoaderInline />
          ) : (
            <>
              {selected === "country" ? (
                <Statistics.Horizontal
                  data={sessions?.usersByCountry?.map((user) => {
                    return {
                      value: user.country,
                      label: user.count,
                    };
                  })}
                />
              ) : (
                <Statistics.Horizontal
                  data={sessions?.usersByCity?.map((user) => {
                    return {
                      value: user.city,
                      label: user.count,
                    };
                  })}
                />
              )}
            </>
          )}
        </div>
        <div
          className="statistics-sessions-section col"
          style={{ marginBottom: "20px" }}
        >
          <h4>LANGUAGE</h4>
          {sessions.loading ? (
            <Statistics.LoaderInline />
          ) : (
            <>
              <Statistics.Horizontal
                data={sessions?.languagesUsage?.map((lang) => {
                  return {
                    value: lang.language,
                    label: lang.count,
                  };
                })}
              />
            </>
          )}
        </div>
        <div
          className="statistics-sessions-section row"
          style={{ marginBottom: "20px" }}
        >
          <div
            className="statistics-info-box smaller"
            style={{ width: "100%" }}
          >
            <div style={{ display: "flex", height: "100%" }}>
              <div className="indicator gray" />
              <h4
                className="statistics-info-box-text"
                style={{ fontSize: "1.25rem" }}
              >
                {sessions?.allowedNotifications} NOTIFICATIONS
              </h4>
            </div>
          </div>
        </div>
        {/* <div className="statistics-sessions-section col"  style={{marginBottom:"20px"}}>
          <h4>SUBSCRIPTION</h4>
          {sessions.loading ? (
            <Statistics.LoaderInline />
          ) : (
            <>
              <Statistics.Pie
                colors={["#9325FF", "#00D297", "#002C78"]}
                data={[
                  { label: sessions?.unsubscribedUsers, value: "FREE" },
                  { label: sessions?.monthlySubscriptions, value: "MONTHLY" },
                  { label: sessions?.yearlySubscriptions, value: "ANUAL" },
                ]}
              />
            </>
          )}
        </div> */}

        <div
          className="statistics-sessions-section col"
          style={{ marginBottom: "20px" }}
        >
          <h4>WITH ADDED PROPERTIES</h4>
          {sessions.loading ? (
            <Statistics.LoaderInline />
          ) : (
            <>
              <Statistics.Horizontal
                data={sessions?.usersByPropertyCount?.map((users) => {
                  return {
                    value: users.range + " properties",
                    label: users.count,
                  };
                })}
              />
            </>
          )}
        </div>
        <div
          className="statistics-sessions-section col"
          style={{ marginBottom: "20px" }}
        >
          <h4>PARTICIPATING IN NEIGHBOURHOOD COMMUNITIES</h4>
          {sessions.loading ? (
            <Statistics.LoaderInline />
          ) : (
            <>
              <Statistics.Horizontal
                data={sessions?.usersByCommunitiesCount?.map((users) => {
                  return {
                    value: users.range + " communities",
                    label: users.count,
                  };
                })}
              />
            </>
          )}
        </div>
        <div
          className="statistics-sessions-section col"
          style={{ marginBottom: "20px" }}
        >
          <h4>USING FINANCIAL MODULE</h4>
          <div className="selector-container">
            <div
              className={`select ${selectedWallet === "wallet" && "selected"}`}
              onClick={() => setSelectedWallet("wallet")}
            >
              CREATED WALLETS
            </div>
            <div
              className={`select ${selectedWallet === "account" && "selected"}`}
              onClick={() => setSelectedWallet("account")}
            >
              REGISTERED BANK ACCOUNTS
            </div>
          </div>
          {sessions.loading ? (
            <Statistics.LoaderInline />
          ) : (
            <>
              <Statistics.Horizontal
                data={
                  selectedWallet === "wallet"
                    ? sessions?.usersByWalletsCount?.map((users) => {
                        return {
                          value: users.range + " wallets",
                          label: users.count,
                        };
                      })
                    : sessions?.usersByBankAccountsCount?.map((users) => {
                        return {
                          value: users.range + " accounts",
                          label: users.count,
                        };
                      })
                }
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sessions: state.dashboard.sessions,
  activeUsers: state.dashboard.activeUsers,
});
const mapDispatchToProps = (dispatch) => ({
  getStatisticsSessions: (payload) => dispatch(getStatisticsSessions(payload)),
  getActiveUsers: (payload) => dispatch(getActiveUsers(payload)),
  getStatisticsSuccess: (payload) => dispatch(getStatisticsSuccess(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Registrations);

/* eslint-disable */
import React, { useState } from "react";
import { logIn } from "../../actions";
import { connect } from "react-redux";
import Inputs from "../../components/Inputs";
import "./styles.scss";

const Login = ({ logIn }) => {
  const [payload, setPayload] = useState({
    email: "",
    password: "",
  });

  return (
    <div className="login-page-container">
      <div className="left-login-container">
        <div className="login-logo" />
        <h3 style={{ fontSize: "23px" }}>LOG IN</h3>
        <Inputs.TextInput
          label={"Email"}
          compulsory
          style={{
            width: "95%",
            marginLeft: "1%",
            padding: "1vw",
            marginTop: "2%",
          }}
          inputStyle={{ border: "0.5px solid #002C78" }}
          value={payload.email}
          onChange={(e) => {
            setPayload({ ...payload, email: e.target.value });
          }}
        />
        <Inputs.TextInput
          label={"Password"}
          password
          compulsory
          style={{ width: "95%", marginLeft: "1%", padding: "1vw" }}
          inputStyle={{ border: "0.5px solid #002C78" }}
          suffix
          value={payload.password}
          onChange={(e) => {
            setPayload({ ...payload, password: e.target.value });
          }}
        />
        <Inputs.Button
          onClick={() => logIn(payload)}
          text="LOG IN"
          selected
          style={{ width: "90%", marginTop: "9%" }}
        />
      </div>
      <div className="right-login-container" />
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  logIn: (payload) => dispatch(logIn(payload)),
});

export default connect(null, mapDispatchToProps)(Login);

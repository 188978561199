/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { getNeighboursFiles } from "../../../../actions";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";
import { connect } from "react-redux";
import Statistics from "../../../Statistics";

const headerElements = [
  {
    name: "Date",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Time",
    query: { filter: "Time", options: [] },
  },
//   {
//     name: "User",
//     query: { filter: "Invitation sent by", options: [] },
//   },
  {
    name: "Name",
    query: { filter: "Role", options: [] },
    width: '18%'
  },
  { name: "Type", query: { filter: "Role", options: [] }, width: '8%' },
  {
    name: "Name of folder",
    query: { filter: "Invitation sent to", options: [] },
  },
  {
    name: "Country",
    query: { filter: "---", options: [] },
  },
  {
    name: "City",
    query: { filter: "City", options: [] },
  },
  {
    name: "Address",
    query: { filter: "Address", options: [] },
    width: "15%"
  },
  {
    name: "Nickname",
    query: { filter: "Nickname", options: [] },
    width: "8%"
  },
  {
    name: "Community members",
    query: { filter: "CommunityMembersCount", options: [] },
    width: '2%'
  },
//   {
//     name: "Type",
//     query: { filter: "Type", options: [] },
//   },
//   {
//     name: "Status",
//     query: { filter: "Status", options: [] },
//   },
];

const FilesCommunities = ({ getNeighboursFiles, files, period }) => {
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();
  const [innerLoading, setInnerLoading] = useState(false);

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && files.totalPages >= curPage && !innerLoading) {
      setInnerLoading(true)
      getNeighboursFiles({
        page: curPage,
        limit: 20,
        onSuccess: (res) => setInnerLoading(false),
      });
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    getNeighboursFiles({
      page: 1,
      limit: 20,
    });
  }, []);

  useEffect(() => {
    if (period.startDate !== "") {
      getNeighboursFiles({
        page: 1,
        limit: 20,
        ...period,
      });
      setCurPage(2);
    }
  }, [period]);

  return (
    <Scrollbars
      style={{ height: "100%", width: "100%" }}
      onUpdate={handleUpdate}
      ref={tableRef}
      id={"scrollbar"}
    >
      <div className="table-header">
        {headerElements?.map((header, i) => {
          return (
            <div
              className="header-cont"
              key={i}
              style={{
                width: header?.width ? header?.width : i === 8 ? "15%" : i === 3 ? "17%" : "8%",
              }}
            >
              <div className="header">{header.name}</div>
            </div>
          );
        })}
      </div>
      {files?.docs?.map((file, i) => {
        return (
          <div className="table-body-row" key={i}>
            <div className="row-data" style={{ width: "8%" }}>
              {file?.createdAt ? moment(file?.createdAt).format("DD.MM.YYYY") : "---"}
            </div>
            <div className="row-data" style={{ width: "8%" }}>
              {file?.createdAt ? moment(file?.createdAt).format("HH:mm") : "---"}
            </div>
            {/* <div className="row-data" style={{ width: "8%" }}>
              {file?.author?.fullName || "---"}
            </div> */}
            <div className="row-data overflow width-8" style={{ width: "18%" }}>
              {file?.name || "---"}
            </div>
            <div className="row-data" style={{ width: "8%" }}>
              {file?.type || "---"}
            </div>
            <div className="row-data" style={{ width: "8%" }}>
              {file?.parentDir?.name || "---"}
            </div>
            <div className="row-data" style={{ width: "8%" }}>
              {file?.community?.country || "---"}
            </div>
            <div className="row-data" style={{ width: "8%" }}>
              {file?.community?.city || "---"}
            </div>
            <div className="row-data" style={{ width: "15%" }}>
              {file?.community?.address || "---"}
            </div>
            {/* new */}
            <div className="row-data" style={{ width: "8%" }}>
              {file?.community?.nickname || "---"}
            </div>
            <div className="row-data" style={{ width: "2%" }}>
              {file?.community?.communityMembersCount || "---"}
            </div>
            {/* new */}
            
            {/* files - items */}
            {/* <div className="row-data" style={{ width: "8%" }}>
              {file?.community?.propertyType || "---"}
            </div> */}
            {/* <div className="row-data" style={{ width: "8%" }}>
              {file?.community?.isRented === true ? "Rented" : "Not Rented"}
            </div> */}
            {/* files - items */}
          </div>
        );
      })}
      {innerLoading && (
        <div className="inner-loader-container">
          <Statistics.LoaderInline center />
        </div>
      )}
    </Scrollbars>
  );
};
const mapStateToProps = (state) => ({
  files: state.app.communityFiles,
});
const mapDispatchToProps = (dispatch) => ({
  getNeighboursFiles: (payload) => dispatch(getNeighboursFiles(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(FilesCommunities);

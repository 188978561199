import { AppStats } from "../actions";

const initialState = {
  invitations: { docs: [], totalPages: 0 },
  coInvitations: { docs: [], totalPages: 0 },
  payments: { docs: [], totalPages: 0 },
  utilityBills: { docs: [], totalPages: 0 },
  files: { docs: [], totalPages: 0 },
  services: { docs: [], totalPages: 0 },
  events: { docs: [], totalPages: 0 },
  cases: { docs: [], totalPages: 0 },
  wallets: { docs: [], totalPages: 0 },
  notices: { docs: [], totalPages: 0 },
  communityCalendar: { docs: [], totalPages: 0 },
  communityFiles: { docs: [], totalPages: 0 },
  communityServices: { docs: [], totalPages: 0 },
  communityMeetings: { docs: [], totalPages: 0 },
  communityWallets: { docs: [], totalPages: 0 },
  polls: { docs: [], totalPages: 0 },
  fundraisers: { docs: [], totalPages: 0 },
  stats: {},
};

const handleNewDocs = (state, payload, key) => {
  if (payload?.page === 1) return payload.docs;
  const _docs = payload.docs.filter(
    (newDoc) => !state[key].docs.find((oldDoc) => newDoc._id === oldDoc._id)
  );
  return [...state?.[key]?.docs, ..._docs];
};

const handleNewUtilityBills = (state, payload, key) => {
  if (payload.page === 1) return payload.docs;

  const _docs = payload?.docs.filter(
    (newDoc) => !state?.utilityBills.docs.find((oldDoc) => newDoc._id === oldDoc._id)
  );
  return [...state?.utilityBills.docs, ..._docs];
};

const app = (state = initialState, { type, payload, key } = {}) => {
  switch (type) {
    case AppStats.GET_STATS_SUCCESS:
      return {
        ...state,
        stats: { ...payload },
      };
    case AppStats.GET_MUTUAL_MANAGEMENT_SUCCESS:
      return {
        ...state,
        invitations: {
          docs: handleNewDocs(state, payload, key),
          totalPages: payload.totalPages,
        },
      };
    case AppStats.CO_MANAGEMENT_STATUS_SUCCESS:
      return {
        ...state,
        coInvitations: {
          docs: handleNewDocs(state, payload, key),
          totalPages: payload.totalPages,
        },
      };
    case AppStats.GET_RENT_PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: {
          docs: handleNewDocs(state, payload, key),
          totalPages: payload.totalPages,
        },
      };
    case AppStats.GET_UTILITY_BILLS_PAYMENTS_SUCCESS:
      return {
        ...state,
        utilityBills: {
          docs: handleNewUtilityBills(state, payload, "payments"),
          totalPages: payload.totalPages,
        },
      };
    case AppStats.GET_FILES_SUCCESS:
      return {
        ...state,
        files: {
          docs: handleNewDocs(state, payload, key),
          totalPages: payload.totalPages,
        },
      };
    case AppStats.GET_SERVICES_SUCCESS:
      return {
        ...state,
        services: {
          docs: handleNewDocs(state, payload, key),
          totalPages: payload.totalPages,
        },
      };
    case AppStats.GET_CALENDAR_EVENTS_SUCCESS:
      return {
        ...state,
        events: {
          docs: handleNewDocs(state, payload, key),
          totalPages: payload.totalPages,
        },
      };
    case AppStats.GET_CASES_SUCCESS:
      return {
        ...state,
        cases: {
          docs: handleNewDocs(state, payload, key),
          totalPages: payload.totalPages,
        },
      };
    case AppStats.GET_WALLETS_SUCCESS:
      return {
        ...state,
        wallets: {
          docs: handleNewDocs(state, payload, key),
          totalPages: payload.totalPages,
        },
      };
    case AppStats.GET_NEIGHBORS_NOTICES_SUCCESS:
      return {
        ...state,
        notices: {
          docs: handleNewDocs(state, payload, key),
          totalPages: payload.totalPages,
        },
      };
      case AppStats.GET_NEIGHBORS_CALENDAR_SUCCESS:
        return {
          ...state,
          communityCalendar: {
            docs: handleNewDocs(state, payload, key),
            totalPages: payload.totalPages,
          },
        };
      case AppStats.GET_NEIGHBORS_FILES_SUCCESS:
        return {
          ...state,
          communityFiles: {
            docs: handleNewDocs(state, payload, key),
            totalPages: payload.totalPages,
          },
        };
      case AppStats.GET_NEIGHBORS_SERVICES_SUCCESS:
        return {
          ...state,
          communityServices: {
            docs: handleNewDocs(state, payload, key),
            totalPages: payload.totalPages,
          },
        };
      case AppStats.GET_NEIGHBORS_MEETINGS_SUCCESS:
        return {
          ...state,
          communityMeetings: {
            docs: handleNewDocs(state, payload, key),
            totalPages: payload.totalPages,
          },
        };
      case AppStats.GET_NEIGHBORS_WALLETS_SUCCESS:
        return {
          ...state,
          communityWallets: {
            docs: handleNewDocs(state, payload, key),
            totalPages: payload.totalPages,
          },
        };
    case AppStats.GET_NEIGHBORS_POLLS_SUCCESS:
      return {
        ...state,
        polls: {
          docs: handleNewDocs(state, payload, key),
          totalPages: payload.totalPages,
        },
      };
    case AppStats.GET_NEIGHBORS_PAYMENTS_SUCCESS:
      return {
        ...state,
        fundraisers: {
          docs: handleNewDocs(state, payload, key),
          totalPages: payload.totalPages,
        },
      };
    default:
      return state;
  }
};

export default app;

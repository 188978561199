import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import starter from "./starter";
import login from "./auth";
import dashboard from "./dashboard";
import app from "./app";
import finances from "./finances";
import users from "./users";
import merchants from "./merchants";
import website from "./website";

export default combineReducers({
  starter,
  login,
  dashboard,
  app,
  website,
  finances,
  users,
  merchants,
  routerReducer,
});

/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { getFiles } from "../../../actions";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";
import { connect } from "react-redux";
import Statistics from "../../Statistics";

const headerElements = [
  {
    name: "Date",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Time",
    query: { filter: "Time", options: [] },
  },
  {
    name: "User",
    query: { filter: "Invitation sent by", options: [] },
  },
  {
    name: "Name",
    query: { filter: "Role", options: [] },
  },
  { name: "Type", query: { filter: "Role", options: [] } },
  {
    name: "Name of folder",
    query: { filter: "Invitation sent to", options: [] },
  },
  {
    name: "Country",
    query: { filter: "---", options: [] },
  },
  {
    name: "City",
    query: { filter: "City", options: [] },
  },
  {
    name: "Address",
    query: { filter: "Address", options: [] },
  },
  {
    name: "Type",
    query: { filter: "Type", options: [] },
  },
  {
    name: "Status",
    query: { filter: "Status", options: [] },
  },
];

const Files = ({ getFiles, files, period }) => {
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();
  const [innerLoading, setInnerLoading] = useState(false);

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && files.totalPages >= curPage && !innerLoading) {
      setInnerLoading(true)
      getFiles({
        page: curPage,
        limit: 20,
        onSuccess: (res) => setInnerLoading(false),
      });
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    getFiles({
      page: 1,
      limit: 20,
    });
  }, []);

  useEffect(() => {
    if (period.startDate !== "") {
      getFiles({
        page: 1,
        limit: 20,
        ...period,
      });
      setCurPage(2);
    }
  }, [period]);
  return (
    <Scrollbars
      style={{ height: "100%", width: "100%" }}
      onUpdate={handleUpdate}
      ref={tableRef}
      id={"scrollbar"}
    >
      <div className="table-header">
        {headerElements?.map((header, i) => {
          return (
            <div
              className="header-cont"
              key={i}
              style={{
                width: i === 8 ? "15%" : i === 3 ? "14%" : "8%",
              }}
            >
              <div className="header">{header.name}</div>
            </div>
          );
        })}
      </div>
      {files?.docs?.map((file, i) => {
        return (
          <div className="table-body-row" key={i}>
            <div className="row-data" style={{ width: "8%" }}>
              {file?.createdAt ? moment(file?.createdAt).format("DD.MM.YYYY") : "---"}
            </div>
            <div className="row-data" style={{ width: "8%" }}>
              {file?.createdAt ? moment(file?.createdAt).format("HH:mm") : "---"}
            </div>
            <div className="row-data" style={{ width: "8%" }}>
              {file?.author?.fullName || "---"}
            </div>
            <div className="row-data" style={{ width: "14%" }}>
              {file?.name || "---"}
            </div>
            <div className="row-data" style={{ width: "8%" }}>
              {file?.type || "---"}
            </div>
            <div className="row-data" style={{ width: "8%" }}>
              {file?.parentDir || "---"}
            </div>
            <div className="row-data" style={{ width: "8%" }}>
              {file?.property?.country || "---"}
            </div>
            <div className="row-data" style={{ width: "8%" }}>
              {file?.property?.city || "---"}
            </div>
            <div className="row-data" style={{ width: "15%" }}>
              {file?.property?.address || "---"}
            </div>
            <div className="row-data" style={{ width: "8%" }}>
              {file?.property?.propertyType || "---"}
            </div>
            <div className="row-data" style={{ width: "8%" }}>
              {file?.property?.isRented === true ? "Rented" : "Not Rented"}
            </div>
          </div>
        );
      })}
      {innerLoading && (
        <div className="inner-loader-container">
          <Statistics.LoaderInline center />
        </div>
      )}
    </Scrollbars>
  );
};
const mapStateToProps = (state) => ({
  files: state.app.files,
});
const mapDispatchToProps = (dispatch) => ({
  getFiles: (payload) => dispatch(getFiles(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Files);

/* eslint-disable */
import React, { useEffect, useState } from "react";
import Statistics from "..";
import { getCommunitiesStats, getStatisticsSuccess } from "../../../actions";
import "./styles.scss";
import { connect } from "react-redux";

const communitiesShortcuts = [
  {
    key: "communitiesByMembersCount",
    label: "PARTICIPANTS",
  },
  {
    key: "communitiesByNoticesCount",
    label: "BOARD MESSAGES",
  },
  {
    key: "communitiesByCalendarEventsCount",
    label: "CALENDAR",
  },
  {
    key: "communitiesByPollsCount",
    label: "VOTING POLLS",
  },
  {
    key: "communitiesByMeetingsCount",
    label: "MEETINGS",
  },
  {
    key: "communitiesByFilesCount",
    label: "FILES",
  },
  // {
  //   key: "communitiesByFundraisersCount",
  //   label: "REQUESTED PAYMENTS",
  // },
];

const Communities = ({ getCommunitiesStats, communities }) => {
  const [selected, setSelected] = useState("country");

  useEffect(() => {
    getStatisticsSuccess({ type: "communities", loading: true });
    getCommunitiesStats();
  }, []);

  return (
    <div
      className="statistics-sessions-container col"
      style={{ overflow: "auto" }}
    >
      <div
        className="statistics-sessions-header row"
        style={{
          backgroundColor: "#002C78",
          borderColor: "#002C78",
          margin:"0px",
          marginTop:"10px",
          marginBottom:"20px",
          justifyContent: "flex-start",
        }}
      >
        <div className="indicator" />
        <h2 style={{ paddingBottom: "0px", marginLeft: "10px" }}>
          <span>{communities?.communitiesCount}</span>{" "}
          <b>NEIGHBOURHOOD COMMUNITIES</b>
        </h2>
      </div>
      <div
        className="statistics-sessions-content"
        style={{ overflow: "unset" }}
      >
        <div className="statistics-sessions-section col" style={{marginBottom:"20px"}}>
          <h4>LOCATION</h4>
          <div className="selector-container">
            <div
              className={`select ${selected === "country" && "selected"}`}
              onClick={() => setSelected("country")}
            >
              BY COUNTRY
            </div>
            <div
              className={`select ${selected === "city" && "selected"}`}
              onClick={() => setSelected("city")}
            >
              BY CITY
            </div>
          </div>
          {communities.loading ? (
            <Statistics.LoaderInline />
          ) : (
            <>
              {selected === "country" ? (
                <Statistics.Horizontal
                  data={communities?.communitiesByCountry?.map((prop) => {
                    return {
                      value: prop.country,
                      label: prop.count,
                    };
                  })}
                />
              ) : (
                <Statistics.Horizontal
                  data={communities?.communitiesByCity?.map((prop) => {
                    return {
                      value: prop.city,
                      label: prop.count,
                    };
                  })}
                />
              )}
            </>
          )}
        </div>
        {communitiesShortcuts.map((pair, i) => {
          return (
            <div className="statistics-sessions-section col" key={i} style={{marginBottom:"20px"}}>
              <h4>{pair.label}</h4>
              {communities.loading ? (
                <Statistics.LoaderInline />
              ) : (
                <Statistics.Horizontal
                  data={communities?.[pair.key]?.map((prop) => {
                    return {
                      value: prop.range,
                      label: prop.count,
                    };
                  })}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  communities: state.dashboard.communities,
});
const mapDispatchToProps = (dispatch) => ({
  getCommunitiesStats: (payload) => dispatch(getCommunitiesStats(payload)),
  getStatisticsSuccess: (payload) => dispatch(getStatisticsSuccess(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Communities);

import { default as Sessions } from "./Sessions";
import { default as Properties } from "./Properties";
import { default as Communities } from "./Communities";
import { default as Registrations } from "./Registrations";
import { default as PaymentTypes } from "./PaymentTypes";
import { default as AppSessions } from "./AppSessions";
import { default as AppGrowth } from "./AppGrowth";
import { default as Notifications } from "./Notifications";
import { default as LoaderInline } from "./LoaderInline";
import { default as Horizontal } from "./Horizontal";
import { default as BarChart } from "./BarChart";
import { default as SelectPeriod } from "./SelectPeriod";
import { default as Pie } from "./Pie";
const Statistics = {
  LoaderInline,
  Sessions,
  Registrations,
  Properties,
  PaymentTypes,
  Communities,
  AppSessions,
  AppGrowth,
  Notifications,
  Horizontal,
  SelectPeriod,
  BarChart,
  Pie,
};

export default Statistics;

export const authTypes = {
  LOG_IN: "auth/LOG_IN",
  LOG_IN_SUCCESS: "auth/LOG_IN_SUCCESS",
  LOGIN_SUCCESS_LOCAL_STORAGE: "login/LOGIN_SUCCESS_LOCAL_STORAGE",
  LOG_OUT: "auth/LOG_OUT",
  LOG_OUT_SUCCESS: "auth/LOG_OUT_SUCCESS",
};

export const logIn = (payload) => ({
  type: authTypes.LOG_IN,
  payload,
});

export const loginSuccessFromLocalStorage = (payload) => ({
  type: authTypes.LOGIN_SUCCESS_LOCAL_STORAGE,
  payload,
});

export const logOut = () => ({
  type: authTypes.LOG_OUT,
});

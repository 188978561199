import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { FinancesStats } from "../actions";
import { API_URL } from "../config/settings";
import { Headers } from "../utilites/Headers";

export const getGeneratedFinancesEpic = (action$) =>
  action$.pipe(
    ofType(FinancesStats.GET_GENERATED_INCOME),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/income/generated`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: FinancesStats.GET_GENERATED_INCOME_SUCCESS,
              payload: response.payments,
              key: "payments",
              total: response.totalIncome,
              lastUpdate: response.lastUpdate,
            });
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );

export const getPotentialIncomeEpic = (action$) =>
  action$.pipe(
    ofType(FinancesStats.GET_POTENTIAL_INCOME),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/income/potential`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: FinancesStats.GET_POTENTIAL_INCOME_SUCCESS,
              payload: response,
            });
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );

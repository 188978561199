/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { getUsers } from "../../actions";
import "./styles.scss";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";
import Inputs from "../../components/Inputs";
import { history } from "../../config/stores";

const headerElements = [
  {
    name: "User",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Email",
    query: { filter: "Time", options: [] },
  },
  {
    name: "Added on",
    query: { filter: "Invitation sent by", options: [] },
  },
];

const Users = ({ getUsers, users }) => {
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && users.totalPages >= curPage) {
      tableRef.current.scrollTop(30);
      getUsers({
        page: curPage,
        limit: 20,
      });
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    getUsers({
      page: 1,
      limit: 20,
    });
  }, []);

  return (
    <div className="main-container">
      <div
        className="body-container"
        style={{ height: "99%", marginTop: "0%" }}
      >
        <div className="inner-header-container" style={{ boxShadow: "none" }}>
          <h2>USER MANAGEMENT</h2>
          <Inputs.Button
            text="ADD"
            selected
            style={{ width: "8%", marginRight: "1%" }}
            onClick={() => history.push("/user-management/add-user")}
          />
        </div>
        <Scrollbars
          style={{ height: "92%", width: "100%", marginTop: "1%" }}
          onUpdate={handleUpdate}
          ref={tableRef}
          renderView={(props) => (
            <div {...props} style={{ ...props.style, overflowX: "hidden" }} />
          )}
          id={"scrollbar"}
        >
          <div className="table-header">
            {headerElements?.map((header, i) => {
              return (
                <div
                  className="header-cont"
                  key={i}
                  style={{
                    width: i === 1 ? "19%" : "13%",
                  }}
                >
                  <div className="header">{header.name}</div>
                </div>
              );
            })}
          </div>
          {users?.docs?.map((user, i) => {
            return (
              <div className="table-body-row" key={i}>
                <div className="row-data" style={{ width: "13%" }}>
                  {user?.fullName}
                </div>
                <div className="row-data" style={{ width: "19%",textTransform:"none" }}>
                  {user?.email}
                </div>
                <div className="row-data" style={{ width: "13%" }}>
                  {moment(user?.createdAt).format("DD.MM.YYYY")}
                </div>
              </div>
            );
          })}
        </Scrollbars>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  users: state.users.users,
});
const mapDispatchToProps = (dispatch) => ({
  getUsers: (payload) => dispatch(getUsers(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Users);

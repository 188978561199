/* eslint-disable */
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import { getProperties } from "../../../actions";
import { history } from "../../../config/stores";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import { MAPBOX_API_KEY } from "../../../config/settings";
import Geocode from "react-geocode";
const headerElements = [
  {
    name: "Country",
    query: { filter: "country", options: [] },
  },
  {
    name: "City",
    query: { filter: "City", options: [] },
  },
  {
    name: "Type",
    query: { filter: "Type", options: [] },
  },
  {
    name: "Status",
    query: { filter: "Status", options: [] },
  },
  {
    name: "Added on",
    query: { filter: "_added", options: [] },
  },
  {
    name: "Added by",
    query: { filter: "---", options: [] },
  },
  {
    name: "Role",
    query: { filter: "---", options: [] },
  },
  {
    name: "Address",
    query: { filter: "---", options: [] },
  },
];

const PropertiesGrid = ({
  getProperties,
  totalPagesProperties,
  propertiesArray,
  propertiesWithCoordinates,
}) => {
  const [selType, setSelType] = useState("grid");

  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();
  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.98 && totalPagesProperties >= curPage) {
      tableRef.current.scrollTop(30);
      getProperties({
        page: curPage,
        limit: 20,
        onSuccess: (res) => {},
      });
      setCurPage(curPage + 1);
    }
  };

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(23.3154911);
  const [lat, setLat] = useState(42.6904114);
  const [zoom, setZoom] = useState(12);
  Geocode.setApiKey("AIzaSyALipb3WN32r90_zyf2wk-vNrEod_JdR5Q");
  Geocode.setLanguage("bg");

  useEffect(() => {
    getProperties({
      page: 1,
      limit: 20,
      onSuccess: (res) => {
        res.docs.map((prop) => {});
      },
    });
  }, []);

  useEffect(() => {
    mapboxgl.accessToken = MAPBOX_API_KEY;
    if (selType === "grid") return; // initialize map only on map button
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v11",
      center: [lng, lat],
      zoom: zoom,
    });
    if (map.current) {
      /*propertiesWithCoordinates.map((prop) => {
        const el = document.createElement("div");
        el.className = "marker";
        new mapboxgl.Marker(el)
          .setLngLat([
            prop.location.coordinates[0],
            prop.location.coordinates[1],
          ])
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }) // add popups
              .setHTML(`
                        <h3 style="padding-top:5px">${prop.address}</h3>    
                      `)
          )
          .addTo(map.current);
      });*/
      propertiesArray.map((prop) => {
        const el = document.createElement("div");
        el.className = "marker";
        Geocode.fromAddress(prop.address).then((response) => {
          new mapboxgl.Marker(el)
            .setLngLat(response.results[0].geometry.location)
            .setPopup(
              new mapboxgl.Popup({ offset: 25 }) // add popups
                .setHTML(`
                            <h3 style="padding-top:5px">${prop.address}</h3>    
                          `)
            )
            .addTo(map.current);
        });
      });
    }
  }, [selType]);

  return (
    <div className="main-container">
      <div className="inner-header-container">
        <div className="left-part" style={{ width: "93%" }}>
          <div className="close-icon" onClick={() => history.push("/")} />
          <h2 className="inner-title">Added properties</h2>
        </div>
        <div className={`icon-container ${selType === "grid" && "selected"}`}>
          <div
            className={`list-icon ${selType === "grid" && "selected"}`}
            onClick={() => setSelType("grid")}
          />
        </div>
        <div className={`icon-container ${selType === "map" && "selected"}`}>
          <div
            className={`map-icon ${selType === "map" && "selected"}`}
            onClick={() => setSelType("map")}
          />
        </div>
      </div>
      <div className={`body-container ${selType === "map" && "body-map"}`}>
        {selType === "map" ? (
          <>
            <div ref={mapContainer} className="map-container" />
          </>
        ) : (
          <Scrollbars
            style={{ height: "100%", width: "100%" }}
            onUpdate={handleUpdate}
            ref={tableRef}
            id={"scrollbar"}
            renderView={(props) => (
              <div {...props} style={{ ...props.style, overflowX: "hidden" }} />
            )}
          >
            <div className="table-header">
              {headerElements?.map((header, i) => {
                return (
                  <div className="header-cont" key={i} style={{ width: "11%" }}>
                    <div className="header">{header.name}</div>
                  </div>
                );
              })}
            </div>
            {propertiesArray.map((prop, i) => {
              return (
                <div className="table-body-row" key={i}>
                  <div className="row-data" style={{ width: "11%" }}>
                    {prop?.country || "---"}
                  </div>
                  <div className="row-data" style={{ width: "11%" }}>
                    {prop?.city || "---"}
                  </div>
                  <div className="row-data" style={{ width: "11%" }}>
                    {prop?.propertyType || "---"}
                  </div>
                  <div className="row-data" style={{ width: "11%" }}>
                    {prop?.isRented === true ? "Rented" : "Not rented"}
                  </div>
                  <div className="row-data" style={{ width: "11%" }}>
                    {moment(prop?.createdAt).format("DD.MM.YYYY")}
                  </div>
                  <div className="row-data" style={{ width: "11%" }}>
                    {prop?.addedBy || "---"}
                  </div>
                  <div className="row-data" style={{ width: "11%" }}>
                    {prop?.owners ? "Owner" : "Landlord"}
                  </div>
                  <div className="row-data" style={{ width: "20%" }}>
                    {prop?.address}
                  </div>
                </div>
              );
            })}
          </Scrollbars>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  propertiesArray: state.dashboard.propertiesArray,
  totalPagesProperties: state.dashboard.totalPagesProperties,
  propertiesWithCoordinates: state.dashboard.propertiesWithCoordinates,
});
const mapDispatchToProps = (dispatch) => ({
  getProperties: (payload) => dispatch(getProperties(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PropertiesGrid);

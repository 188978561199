/* eslint-disable */
import React from "react";
import "./styles.scss";

const ModalComponent = ({ children, open }) => {
  return (
    <div className={`modal-component ${open && "show"} `}>
      {children}
    </div>
  );
};
export default ModalComponent;

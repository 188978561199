/* eslint-disable */
import React from "react";
import ReactECharts from "echarts-for-react";
import "./styles.scss";

const Pie = ({ data, type, colors,height }) => {
  return (
    <div className="graph-pie-container" style={{ width: "100%" }}>
      <ReactECharts
        style={{ height: height ? "200px" : "183px" }}
        option={{
          color: colors,
          legend: {
            orient: "vertical",
            left: "right",
            top: "0",
          },
          series: [
            {
              type: "pie",
              radius: ["55%", "80%"],
              data: data?.map(({ value, label }) => ({
                value: label,
                name: value,
              })),
            },
          ],
          tooltip: {},
        }}
      />
    </div>
  );
};

export default Pie;

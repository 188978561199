export const StatisticsStats = {
  GET_STATISTICS_SUCCESS: "statistics/GET_STATISTICS_SUCCESS",
  GET_STATISTICS_SESSIONS: "statistics/GET_STATISTICS_SESSIONS",
  GET_ACTIVE_USERS: "statistics/GET_ACTIVE_USERS",
  GET_PROPERTIES_STATS: "statistics/GET_PROPERTIES_STATS",
  GET_COMMUNITIES_STATS: "statistics/GET_COMMUNITIES_STATS",
  GET_WALLETS_STATS: "statistics/GET_WALLETS_STATS",
  GET_BANK_ACCOUNT_STATS: "statistics/GET_BANK_ACCOUNT_STATS",
  GET_DOWNLOADS: "statistics/GET_DOWNLOADS",
  APP_SESSIONS: "statistics/APP_SESSIONS",
  APP_GROWTH_STATS: "statistics/APP_GROWTH_STATS",
  GET_NOTIFICATIONS: "statistics/GET_NOTIFICATIONS",
  GET_NOTIFICATIONS_SUCCESS: "statistics/GET_NOTIFICATIONS_SUCCESS",
  CREATE_NOTIFICATION: "statistics/CREATE_NOTIFICATION",
  GET_ACTIVE_USERS_LIST: "statistics/GET_ACTIVE_USERS_LIST",
  GET_ACTIVE_USERS_LIST_SUCCESS: "statistics/GET_ACTIVE_USERS_LIST_SUCCESS",
  GET_RECEIVERS: "statistics/GET_RECEIVERS",
  GET_RECEIVERS_SUCCESS: "statistics/GET_RECEIVERS_SUCCESS",
  USER_AUTOCOMPLETE: "statistics/USER_AUTOCOMPLETE",
  USER_AUTOCOMPLETE_SUCCESS: "statistics/USER_AUTOCOMPLETE_SUCCESS",
  GET_PROPERTIES: "statistics/GET_PROPERTIES",
  GET_PROPERTIES_SUCCESS: "statistics/GET_PROPERTIES_SUCCESS",
  GET_APP_DOWNLOADS: "statistics/GET_APP_DOWNLOADS",
};

export const getStatisticsSessions = (payload) => ({
  type: StatisticsStats.GET_STATISTICS_SESSIONS,
  ...payload,
});
export const getStatisticsSuccess = (payload) => ({
  type: StatisticsStats.GET_STATISTICS_SUCCESS,
  payload,
});
export const getActiveUsers = (payload) => ({
  type: StatisticsStats.GET_ACTIVE_USERS,
  payload,
});
export const getDownloads = (payload) => ({
  type: StatisticsStats.GET_DOWNLOADS,
  payload,
});
export const getPropertiesStats = (payload) => ({
  type: StatisticsStats.GET_PROPERTIES_STATS,
  payload,
});
export const getCommunitiesStats = (payload) => ({
  type: StatisticsStats.GET_COMMUNITIES_STATS,
  payload,
});
export const getWalletsStats = (payload) => ({
  type: StatisticsStats.GET_WALLETS_STATS,
  payload,
});
export const getBankAccountsStats = (payload) => ({
  type: StatisticsStats.GET_BANK_ACCOUNT_STATS,
  payload,
});
export const getAppSessions = (payload) => ({
  type: StatisticsStats.APP_SESSIONS,
  payload,
});
export const getAppGrowthStats = (payload) => ({
  type: StatisticsStats.APP_GROWTH_STATS,
  payload,
});
export const getNotifications = (payload) => ({
  type: StatisticsStats.GET_NOTIFICATIONS,
  payload,
});
export const createNotification = (payload) => ({
  type: StatisticsStats.CREATE_NOTIFICATION,
  payload,
});
export const getActiveUsersList = (payload) => ({
  type: StatisticsStats.GET_ACTIVE_USERS_LIST,
  payload,
});
export const getReceivers = (payload) => ({
  type: StatisticsStats.GET_RECEIVERS,
  payload,
});
export const getUserAutoComplete = (payload) => ({
  type: StatisticsStats.USER_AUTOCOMPLETE,
  payload,
});
export const getProperties = (payload) => ({
  type: StatisticsStats.GET_PROPERTIES,
  payload,
});
export const getAppDownloads = (payload) => ({
  type: StatisticsStats.GET_APP_DOWNLOADS,
  payload,
});

import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { WebsiteArticles } from "../actions";
import { API_URL } from "../config/settings";
import { Headers } from "../utilites/Headers";
import { toast } from "react-toastify";

export const getArticlesEpic = (action$) =>
  action$.pipe(
    ofType(WebsiteArticles.GET_ARTICLES),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/articles/list`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: WebsiteArticles.GET_ARTICLES_SUCCESS,
              payload: response.articles,
              key: "articles",
            });
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );

export const addArticleEpic = (action$) =>
  action$.pipe(
    ofType(WebsiteArticles.ADD_ARTICLE),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/articles`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            // toast.success("Article added successfully!");
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error(err?.response?.error)
            obs.complete();
          })
        )
      )
    )
  );

  export const editArticleEpic = (action$) =>
  action$.pipe(
    ofType(WebsiteArticles.EDIT_ARTICLE),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/articles/${payload?._id}`,
        method: "PUT",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: WebsiteArticles.EDIT_ARTICLE_SUCCESS,
              payload: response.article,
              key: "article",
            });
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );

  export const deleteArticleEpic = (action$) =>
  action$.pipe(
    ofType(WebsiteArticles.DELETE_ARTICLE),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/articles/${payload?.articleId}`,
        method: "DELETE",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: WebsiteArticles.DELETE_ARTICLE_SUCCESS,
              payload: response.article,
              key: "article",
            });
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );

  export const getSingleArticleEpic = (action$) =>
  action$.pipe(
    ofType(WebsiteArticles.GET_ARTICLE),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/articles/${payload?.articleId}`,
        method: "GET",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: WebsiteArticles.GET_ARTICLE_SUCCESS,
              payload: response.article,
              key: "article",
            });
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );

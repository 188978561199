/* eslint-disable */
import React, { useEffect } from "react";
import Inputs from "../../components/Inputs";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { addUser } from "../../actions";
import { history } from "../../config/stores";

const CreateUser = ({ addUser }) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
  });

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      if (watch().password.length < 6) {
        toast.error("Pasword length must be at least 6 characters");
      }
      else toast.error("Missing fields");
    }
  }, [errors]);

  return (
    <div className="main-container">
      <div className="inner-header-container">
        <div className="left-part">
          <div className="close-icon" onClick={() => history.push("/")} />
          <h2 className="inner-title">ADDING USER</h2>
        </div>
        <Inputs.Button
          text="ADD"
          style={{ width: "8%" }}
          selected
          onClick={handleSubmit((e) => {
            addUser({
              fullName: `${e.firstName} ${e.lastName}`,
              email: e.email,
              password: e.password,
              onSuccess: (res) => history.push("/user-management"),
            });
          })}
        />
      </div>
      <div className="body-container row" style={{ height: "20%" }}>
        <Inputs.TextInput
          label={"First name"}
          compulsory
          className={errors.firstName && "failed"}
          {...register(`firstName`, { required: true })}
        />
        <Inputs.TextInput
          label={"Last name"}
          compulsory
          className={errors.lastName && "failed"}
          {...register(`lastName`, { required: true })}
        />
        <Inputs.TextInput
          label={"Email"}
          compulsory
          className={errors.email && "failed"}
          {...register(`email`, { required: true })}
        />
        <Inputs.TextInput
          label={"Password"}
          compulsory
          className={errors.password && "failed"}
          {...register(`password`, { required: true, minLength: 6 })}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addUser: (payload) => dispatch(addUser(payload)),
});

export default connect(null, mapDispatchToProps)(CreateUser);

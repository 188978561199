/* eslint-disable */
import React, { useEffect, useState } from "react";
import Statistics from "..";
import {
  getPropertiesStats,
  getStatisticsSuccess,
  getActiveUsers,
} from "../../../actions";
import "./styles.scss";
import { connect } from "react-redux";
import { history } from "../../../config/stores";

const rentTypesShortcuts = [
  {
    key: "rentedOutProperties",
    label: "RENTED OUT PROPERTIES",
  },
  {
    key: "rentedProperties",
    label: "RENTED PROPERTIES",
  },

  {
    key: "sharedProperties",
    label: "OWNED TOGETHER WITH TENANTS",
  },
];

const Properties = ({ getPropertiesStats, properties, sessions }) => {
  const [selected, setSelected] = useState("country");

  useEffect(() => {
    getStatisticsSuccess({ type: "properties", loading: true });
    getPropertiesStats();
  }, []);

  return (
    <div
      className="statistics-sessions-container col"
      style={{ overflow: "auto" }}
    >
      <div
        className="statistics-sessions-header row"
        style={{
          backgroundColor: "#002C78",
          borderColor: "#002C78",
          margin:"0px",
          marginTop:"10px",
          marginBottom:"20px",
          justifyContent: "flex-start",
        }}
      >
        <div className="indicator" />
        <h2 style={{ paddingBottom: "0px", marginLeft: "10px", width: "99%" }}>
          <span>{properties?.propertiesCount}</span>{" "}
          <b>PROPERTIES IN THE APP</b>
        </h2>
        <div
          className="info-icon"
          onClick={() => history.push("/dashboard/properties")}
        />
      </div>
      <div
        className="statistics-sessions-content"
        style={{ overflow: "unset" }}
      >
        <div className="statistics-sessions-section col"  style={{marginBottom:"20px"}}>
          <h4>LOCATION</h4>
          <div className="selector-container">
            <div
              className={`select ${selected === "country" && "selected"}`}
              onClick={() => setSelected("country")}
            >
              BY COUNTRY
            </div>
            <div
              className={`select ${selected === "city" && "selected"}`}
              onClick={() => setSelected("city")}
            >
              BY CITY
            </div>
          </div>
          {sessions.loading ? (
            <Statistics.LoaderInline />
          ) : (
            <>
              {selected === "country" ? (
                <Statistics.Horizontal
                  data={properties?.groupedByCountry?.map((prop) => {
                    return {
                      value: prop.country,
                      label: prop.count,
                    };
                  })}
                />
              ) : (
                <Statistics.Horizontal
                  data={properties?.groupedByCity?.map((prop) => {
                    return {
                      value: prop.city,
                      label: prop.count,
                    };
                  })}
                />
              )}
            </>
          )}
        </div>
        <div className="statistics-sessions-section col"  style={{marginBottom:"20px"}}>
          <h4>TYPE</h4>
          {sessions.loading ? (
            <Statistics.LoaderInline />
          ) : (
            <Statistics.Horizontal
              data={properties?.groupedByType?.map((prop) => {
                return {
                  value: prop.type,
                  label: prop.count,
                };
              })}
            />
          )}
        </div>
        <div className="statistics-sessions-section col"  style={{marginBottom:"20px"}}>
          <h4>STATUS</h4>
          <div className="statistics-sessions-section col "  style={{marginBottom:"20px"}}>
            <div className="statistics-info-box smaller full-width">
              <div style={{ display: "flex", height: "100%" }}>
                <div className="indicator gray" />
                <h4 className="statistics-info-box-text" style={{fontSize:"1.25rem"}}>
                  {properties?.ownedProperties?.count} OWNED PROPERTIES
                </h4>
              </div>
            </div>
            {rentTypesShortcuts.map((pair, i) => {
              return (
                <div className="statistics-info-box bigger full-width" key={i}>
                  <div
                    style={{
                      display: "flex",
                      height: "18%",
                      border: "1px solid #b0d1fa",
                      borderRadius: "6px",
                      padding: "7px",
                    }}
                  >
                    <div className="indicator gray" />
                    <h4 className="statistics-info-box-text" style={{fontSize:"1.25rem"}}>
                      {properties?.[pair.key]?.count} {pair.label}
                    </h4>
                  </div>
                  <div style={{ padding: "10px" }}>
                    <h4 style={{ margin: "0.5rem 0" }}>MONTHLY RENT</h4>
                    {sessions.loading ? (
                      <Statistics.LoaderInline />
                    ) : (
                      <Statistics.Horizontal
                        data={properties?.[pair.key]?.groupedByRentRange?.map(
                          (prop) => {
                            return {
                              value: prop.range,
                              label: prop.count,
                            };
                          }
                        )}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sessions: state.dashboard.sessions,
  properties: state.dashboard.properties,
  activeUsers: state.dashboard.activeUsers,
});
const mapDispatchToProps = (dispatch) => ({
  getPropertiesStats: (payload) => dispatch(getPropertiesStats(payload)),
  getActiveUsers: (payload) => dispatch(getActiveUsers(payload)),
  getStatisticsSuccess: (payload) => dispatch(getStatisticsSuccess(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Properties);

export const MerchantsStats = {
  GET_MERCHANTS_LIST: "merchants/GET_MERCHANTS_LIST",
  GET_MERCHANTS_LIST_SUCCESS: "merchants/GET_MERCHANTS_LIST_SUCCESS",
  GET_PENDING: "merchants/GET_PENDING",
  GET_PENDING_SUCCESS: "merchants/GET_PENDING_SUCCESS",
  SEARCH_MERCHANTS: "merchants/SEARCH_MERCHANTS",
  SEARCH_MERCHANTS_SUCCESS: "merchants/SEARCH_MERCHANTS_SUCCESS",
  ADD_PENDING: "merchants/ADD_PENDING",
  DELETE_PENDING: "merchants/DELETE_PENDING",
  SET_CURRENT_MERCHANT: "merchants/SET_CURRENT_MERCHANT",
  GET_CURRENT_MERCHANT: "merchants/GET_CURRENT_MERCHANT",
  GET_CURRENT_MERCHANT_SUCCESS: "merchants/GET_CURRENT_MERCHANT_SUCCESS",
  EDIT_MERCHANT: "merchants/EDIT_MERCHANT",
  DELETE_MERCHANT: "merchants/DELETE_MERCHANT",
};
export const getMerchantsList = (payload) => ({
  type: MerchantsStats.GET_MERCHANTS_LIST,
  payload,
});
export const getPendingList = (payload) => ({
  type: MerchantsStats.GET_PENDING,
  payload,
});
export const searchMerchants = (payload) => ({
  type: MerchantsStats.SEARCH_MERCHANTS,
  payload,
});
export const getCurrentMerchant = (payload) => ({
  type: MerchantsStats.GET_CURRENT_MERCHANT,
  payload,
});
export const setCurrentMerchant = (payload) => ({
  type: MerchantsStats.SET_CURRENT_MERCHANT,
  payload,
});
export const addPending = (payload) => ({
  type: MerchantsStats.ADD_PENDING,
  payload,
});
export const deletePending = (payload) => ({
  type: MerchantsStats.DELETE_PENDING,
  payload,
});
export const editMerchant = (payload) => ({
  type: MerchantsStats.EDIT_MERCHANT,
  payload,
});
export const deleteMerchant = (payload) => ({
  type: MerchantsStats.DELETE_MERCHANT,
  payload,
});

/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { getNeighboursWallets } from "../../../../actions";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";
import { connect } from "react-redux";
import Statistics from "../../../Statistics";

const headerElements = [
  {
    name: "Date",
    query: { filter: "Date", options: [] },
    width: "8%"
  },
  {
    name: "Time",
    query: { filter: "Time", options: [] },
  },
  {
    name: "User",
    query: { filter: "User", options: [] },
    width: "12%"
  },
  {
    name: "Merchant",
    query: { filter: "Merchant", options: [] },
    width: "13%"
  },
  {
    name: "Debtor",
    query: { filter: "Debtor", options: [] },
    width: "13%"
  },
  {
    name: "Entry",
    query: { filter: "Entry", options: [] },
    width: "12%"
  },
  {
    name: "Type",
    query: { filter: "Invitation sent to", options: [] },
    width: "9%"
  },
  {
    name: "Amount",
    query: { filter: "Amount", options: [] },
    width: "8%"
  },
  {
    name: "Income sources",
    query: { filter: "Income sources", options: [] },
    width: "9%"
  },
  {
    name: "Expense sources",
    query: { filter: "Expense sources", options: [] },
    width: "9%"
  },
  {
    name: "Synced bank account",
    query: { filter: "Synced bank account", options: [] },
    width: "12%"
  },
  {
    name: "Community name",
    query: { filter: "Address", options: [] },
    width: "12%"
  },
  {
    name: "Country",
    query: { filter: "Address", options: [] },
    width: "9%"
  },
  {
    name: "City",
    query: { filter: "Address", options: [] },
    width: "9%"
  },
  {
    name: "Address",
    query: { filter: "Address", options: [] },
    width: "12%"
  }
];

const WalletsCommunities = ({ getNeighboursWallets, wallets, period }) => {
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();
  const [innerLoading, setInnerLoading] = useState(false);

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && wallets.totalPages >= curPage && !innerLoading) {
      setInnerLoading(true)
      getNeighboursWallets({
        page: curPage,
        limit: 20,
        onSuccess:(response) => setInnerLoading(false)
      });
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    getNeighboursWallets({
      page: 1,
      limit: 20,
    });
  }, []);

  useEffect(() => {
    if (period.startDate !== "") {
      getNeighboursWallets({
        page: 1,
        limit: 20,
        ...period,
      });
      setCurPage(2);
    }
  }, [period]);

  return (
    <Scrollbars
      style={{ height: "100%", width: "100%" }}
      onUpdate={handleUpdate}
      ref={tableRef}
      id={"scrollbar"}
    >
      <div className="table-header" style={{ width: "120%" }}>
        {headerElements?.map((header, i) => {
          return (
            <div
              className="header-cont"
              key={i}
              style={{
                width: header?.width ? header?.width : "9%",
              }}
            >
              <div className="header">{header.name}</div>
            </div>
          );
        })}
      </div>
      {wallets?.docs?.map((wallet, i) => {
        return (
          <div className="table-body-row" key={i} style={{ width: "120%" }}>
            <div className="row-data" style={{ width: "10%" }}>
              {wallet?.createdAt
                ? moment(wallet?.createdAt).format("DD.MM.YYYY")
                : "---"}
            </div>
            <div className="row-data" style={{ width: "10%" }}>
              {wallet?.createdAt
                ? moment(wallet?.createdAt).format("HH:mm")
                : "---"}
            </div>
            <div className="row-data"  style={{ width: "15%" }}>
              {wallet?.user?.fullName || "---"}
            </div>
            <div className="row-data"  style={{ width: "15%" }}>
              {wallet?.merchantName || "---"}
            </div>
            <div className="row-data"  style={{ width: "15%" }}>
              {wallet?.debtorName || "---"}
            </div>
            <div className="row-data" style={{ width: "15%" }}>
              {wallet?.entry || "---"}
            </div>
            <div className="row-data" style={{ width: "10%" }}>
              {wallet?.type || "---"}
            </div>
            <div className="row-data"  style={{ width: "10%" }}>
            {`${wallet?.amount || "---"} ${wallet?.currency || ""}`}
            </div>
            <div className="row-data"  style={{ width: "10%" }}>
              {wallet?.wallet?.incomeCategoriesCount || "---"}
            </div>
            <div className="row-data"  style={{ width: "10%" }}>
              {wallet?.wallet?.outcomeCategoriesCount || "---"}
            </div>
            <div className="row-data" style={{ width: "15%" }}>
              {wallet?.wallet?.bankAccountsCount || "---"}
            </div>
            <div className="row-data" style={{ width: "15%" }}>
              {wallet?.community?.nickname || "---"}
            </div>
            <div className="row-data" style={{ width: "10%" }}>
              {wallet?.community?.country || "---"}
            </div>
            <div className="row-data" style={{ width: "10%" }}>
              {wallet?.community?.city || "---"}
            </div>
            <div className="row-data" style={{ width: "15%" }}>
              {wallet?.community?.address || "---"}
            </div>
          </div>
        );
      })}
       {innerLoading && (
        <div className="inner-loader-container">
          <Statistics.LoaderInline center />
        </div>
      )}
    </Scrollbars>
  );
};
const mapStateToProps = (state) => ({
  wallets: state.app.communityWallets,
});
const mapDispatchToProps = (dispatch) => ({
  getNeighboursWallets: (payload) => dispatch(getNeighboursWallets(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(WalletsCommunities);

export const FinancesStats = {
  GET_GENERATED_INCOME: "app/GET_GENERATED_INCOME",
  GET_GENERATED_INCOME_SUCCESS: "app/GET_GENERATED_INCOME_SUCCESS",
  GET_POTENTIAL_INCOME: "app/GET_POTENTIAL_INCOME",
  GET_POTENTIAL_INCOME_SUCCESS: "app/GET_POTENTIAL_INCOME_SUCCESS",
};

export const getGeneratedIncome = (payload) => ({
    type: FinancesStats.GET_GENERATED_INCOME,
    payload,
  });
export const getPotentialIncome = (payload) => ({
  type: FinancesStats.GET_POTENTIAL_INCOME,
  payload,
});

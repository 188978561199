import { StatisticsStats } from "../actions";

const initialState = {
  sessions: { loading: false, period: "all" },
  activeUsers: { loading: false },
  properties: { loading: false },
  communities: { loading: false },
  wallets: { loading: false },
  bankAccounts: { loading: false },
  appSessions: { loading: false },
  growth: { loading: false },
  appDownloads: { loading: false },
  notifications: [],
  totalPages: 0,
  activeUsersList: [],
  receivers: [],
  autoCompleteUsers: [],
  propertiesArray: [],
  propertiesWithCoordinates: [],
  totalPagesProperties: 0,
};

const handleNewNotifications = (state, payload, page) => {
  let newState = { ...state };
  if (page === 1) return payload.docs;
  const _docs = payload.notifications.docs.filter(
    (newDoc) => !state.find((oldDoc) => newDoc._id === oldDoc._id)
  );
  newState = {
    ...payload,
    notifications: [...state.notiications, ..._docs],
  };
  return newState;
};

const handleNewProperites = (state, payload, page) => {
  let newState = { ...state };
  if (page === 1) {
    return payload;
  }
  const _docs = payload.filter(
    (newDoc) =>
      !state.propertiesArray.find((oldDoc) => newDoc._id === oldDoc._id)
  );
  newState = {
    ...payload,
    propertiesArray: [...state.propertiesArray, ..._docs],
  };
  return newState.propertiesArray;
};

const dashboard = (
  state = initialState,
  { type, payload, page, locationsWithCoordinates } = {}
) => {
  switch (type) {
    case StatisticsStats.GET_STATISTICS_SUCCESS:
      if (payload.docs && payload.page && payload.page > 1)
        return {
          ...state,
          [payload.type]: {
            ...(state?.[payload.type] || {}),
            ...payload,
            docs: [...(state?.[payload.type]?.docs || {}), ...payload.docs],
          },
        };
      else
        return {
          ...state,
          [payload.type]: { ...(state?.[payload.type] || {}), ...payload },
        };
    case StatisticsStats.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: handleNewNotifications(
          state.notifications,
          payload.notifications,
          payload.notifications.page
        ),
        totalPages: payload.notifications.totalPages,
      };
    case StatisticsStats.GET_PROPERTIES_SUCCESS:
      return {
        ...state,
        propertiesArray: handleNewProperites(state, payload.docs, payload.page),
        propertiesWithCoordinates: locationsWithCoordinates,
        totalPagesProperties: payload.totalPages,
      };
    case StatisticsStats.GET_ACTIVE_USERS_LIST_SUCCESS:
      return {
        ...state,
        activeUsersList: [...payload],
      };
    case StatisticsStats.GET_RECEIVERS_SUCCESS:
      return {
        ...state,
        receivers: [...payload.receivers.docs],
      };
    case StatisticsStats.USER_AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        autoCompleteUsers: [...payload],
      };
    default:
      return state;
  }
};

export default dashboard;

/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import { forwardRef } from "react";

const TextInput = forwardRef(
  (
    {
      label,
      compulsory,
      style,
      inputStyle,
      number,
      disabled,
      suffix,
      password,
      className,
      symbolSuffix,
      ...props
    },
    ref
  ) => {
    const [active, setActive] = useState(false);
    return (
      <div className="input-container" style={style}>
        {label && (
          <label style={{ display: "flex", width: "100%", fontSize: "1rem" }}>
            {label}{" "}
            {compulsory && (
              <span style={{ paddingLeft: "5px", color: "black" }}>*</span>
            )}
          </label>
        )}

        <div
          className={`prefix-input-container ${
            disabled && "disabled"
          } ${className}`}
          style={inputStyle}
        >
          <input
            className={`inner-input`}
            type={password ? "password" : number ? "number" : "text"}
            id={password && "input"}
            disabled={disabled}
            {...props}
            ref={ref}
          />
          {suffix && (
            <div
              className={`suffix ${active && "green"}`}
              onClick={() => {
                var x = document.getElementById("input");
                if (x.type === "password") {
                  x.type = "text";
                  setActive(true);
                } else {
                  x.type = "password";
                  setActive(false);
                }
              }}
            />
          )}
          {symbolSuffix && <div className="percentage">%</div>}
        </div>
      </div>
    );
  }
);

export default TextInput;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import { withRouter, Redirect } from "react-router-dom";
import { CreateUser, Login, Users } from "../../screens";
import { App } from "../../screens/App";
import { Website, AddArticle, ReviewArticle } from "../../screens";
import Dashboard from "../../screens/Dashboard/Dashboard";
import { Finances } from "../../screens/Finances";
import { MerchantOperation } from "../../screens/Merchants";
import Merchants from "../../screens/Merchants/Merchants";
import { User } from "../../utilites/User";
import Header from "../Header/Header";
import AddNotification from "../Statistics/Notifications/AddNotification";
import Receivers from "../Statistics/Notifications/Receivers";
import PropertiesGrid from "../Statistics/Properties/PropertiesGrid";
import ActiveUsersGrid from "../Statistics/Sessions/ActiveUsersGrid";

const AuthRoute = ({ children, ...rest }) => {
  return (
    <Route
      render={({ location }) =>
        !User.isAuthenticated ? (
          <Route {...rest} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: location } }} />
        )
      }
    />
  );
};

const PrivateRoute = ({ children, ...rest }) => {
  return (
    <Route
      render={({ location }) => {
        return User.isAuthenticated ? (
          <Route {...rest} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        );
      }}
    />
  );
};
class Routes extends Component {
  render() {
    return (
      <>
        {User.isAuthenticated ? <Header /> : ""}
        <div className="dashboard-wrap">
          <Switch>
            <AuthRoute exact path="/login" component={Login} />
            <PrivateRoute exact path="/" component={Dashboard} />
            <PrivateRoute
              exact
              path="/notification-send"
              component={AddNotification}
            />
            <PrivateRoute
              exact
              path="/active-users"
              component={ActiveUsersGrid}
            />
            <PrivateRoute path="/notification" component={Receivers} />
            <PrivateRoute
              exact
              path="/dashboard/properties"
              component={PropertiesGrid}
            />
            <PrivateRoute exact path="/app" component={App} />
            <PrivateRoute exact path="/website" component={Website} />
            <PrivateRoute exact path="/website/add-article" component={AddArticle} />
            <PrivateRoute exact path="/website/edit-article/:articleId" component={AddArticle} />
            <PrivateRoute exact path="/website/article/:articleId" component={ReviewArticle} />
            <PrivateRoute exact path="/finances" component={Finances} />
            <PrivateRoute exact path="/user-management" component={Users} />
            <PrivateRoute
              exact
              path="/user-management/add-user"
              component={CreateUser}
            />
            <PrivateRoute exact path="/merchants" component={Merchants} />
            <PrivateRoute path="/add-merchant" component={MerchantOperation} />
            <PrivateRoute path="/merchant" component={MerchantOperation} />
          </Switch>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { loading: state.starter.loading };
};

export default withRouter(connect(mapStateToProps)(Routes));

import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { authTypes } from "../actions";
import { API_URL } from "../config/settings";
import { Headers } from "../utilites/Headers";
import { User } from "../utilites/User";
import { history } from "../config/stores";
import { toast } from "react-toastify";

export const loginEpic = (action$) =>
  action$.pipe(
    ofType(authTypes.LOG_IN),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/admin/login`,
        method: "POST",
        headers: Headers.get(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            User.authenticate(response.token, {
              token: response.token,
              ...response?.user,
            });
            history.push("/");
            obs.next({
              type: authTypes.LOG_IN_SUCCESS,
              payload: response,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            toast.error(err?.response?.error);
            obs.complete();
          });
        })
      );
    })
  );
export const logOutEpic = (action$) =>
  action$.pipe(
    ofType(authTypes.LOG_OUT),
    switchMap(() =>
      ActionsObservable.create((obs) => {
        User.signout();
        toast.success("Logged out successfully");
        obs.next({
          type: authTypes.LOG_OUT_SUCCESS,
        });
      })
    )
  );

export const AppStats = {
  GET_STATS: "app/GET_STATS",
  GET_STATS_SUCCESS: "app/GET_STATS_SUCCESS",
  GET_MUTUAL_MANAGEMENT: "app/GET_MUTUAL_MANAGEMENT",
  GET_MUTUAL_MANAGEMENT_SUCCESS: "app/GET_MUTUAL_MANAGEMENT_SUCCESS",
  CO_MANAGEMENT_STATUS: "app/CO_MANAGEMENT_STATUS",
  CO_MANAGEMENT_STATUS_SUCCESS: "app/CO_MANAGEMENT_STATUS_SUCCESS",
  GET_RENT_PAYMENTS: "app/GET_RENT_PAYMENTS",
  GET_RENT_PAYMENTS_SUCCESS: "app/GET_RENT_PAYMENTS_SUCCESS",
  GET_UTILITY_BILLS_PAYMENTS: "app/GET_UTILITY_BILLS_PAYMENTS",
  GET_UTILITY_BILLS_PAYMENTS_SUCCESS: "app/GET_UTILITY_BILLS_PAYMENTS_SUCCESS",
  GET_FILES: "app/GET_FILES",
  GET_FILES_SUCCESS: "app/GET_FILES_SUCCESS",
  GET_SERVICES: "app/GET_SERVICES",
  GET_SERVICES_SUCCESS: "app/GET_SERVICES_SUCCESS",
  GET_CALENDAR_EVENTS: "app/GET_CALENDAR_EVENTS",
  GET_CALENDAR_EVENTS_SUCCESS: "app/GET_CALENDAR_EVENTS_SUCCESS",
  GET_CASES: "app/GET_CASES",
  GET_CASES_SUCCESS: "app/GET_CASES_SUCCESS",
  GET_WALLETS: "app/GET_WALLETS",
  GET_WALLETS_SUCCESS: "app/GET_WALLETS_SUCCESS",
  GET_NEIGHBORS_NOTICES: "app/GET_NEIGHBORS_NOTICES",
  GET_NEIGHBORS_NOTICES_SUCCESS: "app/GET_NEIGHBORS_NOTICES_SUCCESS",
  GET_NEIGHBORS_CALENDAR: "app/GET_NEIGHBORS_CALENDAR",
  GET_NEIGHBORS_CALENDAR_SUCCESS: "app/GET_NEIGHBORS_CALENDAR_SUCCESS",
  GET_NEIGHBORS_POLLS: "app/GET_NEIGHBORS_POLLS",
  GET_NEIGHBORS_POLLS_SUCCESS: "app/GET_NEIGHBORS_POLLS_SUCCESS",
  GET_NEIGHBORS_PAYMENTS: "app/GET_NEIGHBORS_PAYMENTS",
  GET_NEIGHBORS_PAYMENTS_SUCCESS: "app/GET_NEIGHBORS_PAYMENTS_SUCCESS",
  GET_NEIGHBORS_FILES: "app/GET_NEIGHBORS_FILES",
  GET_NEIGHBORS_FILES_SUCCESS: "app/GET_NEIGHBORS_FILES_SUCCESS",
  GET_NEIGHBORS_SERVICES: "app/GET_NEIGHBORS_SERVICES",
  GET_NEIGHBORS_SERVICES_SUCCESS: "app/GET_NEIGHBORS_SERVICES_SUCCESS",
  GET_NEIGHBORS_MEETINGS: "app/GET_NEIGHBORS_MEETINGS",
  GET_NEIGHBORS_MEETINGS_SUCCESS: "app/GET_NEIGHBORS_MEETINGS_SUCCESS",
  GET_NEIGHBORS_WALLETS: "app/GET_NEIGHBORS_WALLETS",
  GET_NEIGHBORS_WALLETS_SUCCESS: "app/GET_NEIGHBORS_WALLETS_SUCCESS",
  PAY_UTILITY_BILL: "app/PAY_UTILITY_BILL",
  UPLOAD_RECEIPT: "app/UPLOAD_RECEIPT",
};
export const getStats = (payload) => ({
  type: AppStats.GET_STATS,
  payload,
});
export const getMutualManagement = (payload) => ({
  type: AppStats.GET_MUTUAL_MANAGEMENT,
  payload,
});
export const getCoManagement = (payload) => ({
  type: AppStats.CO_MANAGEMENT_STATUS,
  payload,
});
export const getRentPayments = (payload) => ({
  type: AppStats.GET_RENT_PAYMENTS,
  payload,
});
export const getUtilityBillsPayments = (payload) => ({
  type: AppStats.GET_UTILITY_BILLS_PAYMENTS,
  payload,
});
export const getFiles = (payload) => ({
  type: AppStats.GET_FILES,
  payload,
});
export const getServices = (payload) => ({
  type: AppStats.GET_SERVICES,
  payload,
});
export const getCalendarEvents = (payload) => ({
  type: AppStats.GET_CALENDAR_EVENTS,
  payload,
});
export const getCases = (payload) => ({
  type: AppStats.GET_CASES,
  payload,
});
export const getWallets = (payload) => ({
  type: AppStats.GET_WALLETS,
  payload,
});
export const getNeighborsNotices = (payload) => ({
  type: AppStats.GET_NEIGHBORS_NOTICES,
  payload,
});
export const getNeighborsCalendar = (payload) => ({
  type: AppStats.GET_NEIGHBORS_CALENDAR,
  payload,
});
export const getNeighborsPolls = (payload) => ({
  type: AppStats.GET_NEIGHBORS_POLLS,
  payload,
});
export const getNeighborsPayments = (payload) => ({
  type: AppStats.GET_NEIGHBORS_PAYMENTS,
  payload,
});
export const getNeighboursFiles = (payload) => ({
  type: AppStats.GET_NEIGHBORS_FILES,
  payload,
});
export const getNeighboursServices = (payload) => ({
  type: AppStats.GET_NEIGHBORS_SERVICES,
  payload,
});
export const getNeighboursMeetings = (payload) => ({
  type: AppStats.GET_NEIGHBORS_MEETINGS,
  payload,
});
export const getNeighboursWallets = (payload) => ({
  type: AppStats.GET_NEIGHBORS_WALLETS,
  payload,
});
export const payUtilityBill = (payload) => ({
  type: AppStats.PAY_UTILITY_BILL,
  payload,
});
export const uploadReceipt = (payload) => ({
  type: AppStats.UPLOAD_RECEIPT,
  payload,
});

import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { AppStats } from "../actions";
import { API_URL } from "../config/settings";
import { Headers } from "../utilites/Headers";
import { toast } from "react-toastify";

export const getStatsEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_STATS),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/stats/count`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_STATS_SUCCESS,
              payload: response,
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );

export const getMutualManagementsEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_MUTUAL_MANAGEMENT),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/stats/mutual-management`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_MUTUAL_MANAGEMENT_SUCCESS,
              payload: response.invitations,
              key: "invitations",
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const coManagementEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.CO_MANAGEMENT_STATUS),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/stats/co-management`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.CO_MANAGEMENT_STATUS_SUCCESS,
              payload: response.invitations,
              key: "coInvitations",
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getRentPayentsEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_RENT_PAYMENTS),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/stats/rent-payments`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_RENT_PAYMENTS_SUCCESS,
              payload: response.payments,
              key: "payments",
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getUtilityBillsPaymentsEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_UTILITY_BILLS_PAYMENTS),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/stats/utility-bill-payments`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_UTILITY_BILLS_PAYMENTS_SUCCESS,
              payload: response.payments,
              key: "payments",
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getFilesEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_FILES),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/stats/files`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_FILES_SUCCESS,
              payload: response.files,
              key: "files",
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getServicesEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_SERVICES),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/stats/services`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_SERVICES_SUCCESS,
              payload: response.services,
              key: "services",
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getCalendarEventsEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_CALENDAR_EVENTS),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/stats/property-calendar`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_CALENDAR_EVENTS_SUCCESS,
              payload: response.events,
              key: "events",
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getCasesEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_CASES),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/stats/cases`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_CASES_SUCCESS,
              payload: response.cases,
              key: "cases",
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getWalletsEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_WALLETS),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/stats/wallets`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_WALLETS_SUCCESS,
              payload: response.transactions,
              key: "wallets",
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getNeighborsNoticesEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_NEIGHBORS_NOTICES),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/stats/community-notices`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_NEIGHBORS_NOTICES_SUCCESS,
              payload: response.notices,
              key: "notices",
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getNeighborsFilesEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_NEIGHBORS_FILES),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/stats/community-files`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_NEIGHBORS_FILES_SUCCESS,
              payload: response.files,
              key: "communityFiles",
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getNeighborsServicesEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_NEIGHBORS_SERVICES),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/stats/community-services`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_NEIGHBORS_SERVICES_SUCCESS,
              payload: response.services,
              key: "communityServices",
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getNeighborsMeetingsEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_NEIGHBORS_MEETINGS),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/stats/community-meetings`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_NEIGHBORS_MEETINGS_SUCCESS,
              payload: response.meetings,
              key: "communityMeetings",
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getNeighborsWalletsEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_NEIGHBORS_WALLETS),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/stats/community-wallets`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_NEIGHBORS_WALLETS_SUCCESS,
              payload: response.transactions,
              key: "communityWallets",
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getNeighborsCalendarEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_NEIGHBORS_CALENDAR),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/stats/community-calendar`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_NEIGHBORS_CALENDAR_SUCCESS,
              payload: response.events,
              key: "communityCalendar",
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getNeighborsPollsEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_NEIGHBORS_POLLS),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/stats/community-polls`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_NEIGHBORS_POLLS_SUCCESS,
              payload: response.polls,
              key: "polls",
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getNeighborsPaymentsEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_NEIGHBORS_PAYMENTS),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/stats/community-fundraisers`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_NEIGHBORS_PAYMENTS_SUCCESS,
              payload: response.fundraisers,
              key: "fundraisers",
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const payUtilityBillEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.PAY_UTILITY_BILL),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/utility-bills/mark-as-paid`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            toast.error(err.response.error);
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );

export const uploadReceiptEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.UPLOAD_RECEIPT),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/admin/utility-bills/upload-receipt`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            toast.error(err.response.error);
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );

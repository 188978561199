/* eslint-disable */
import React, { useEffect, useState } from "react";
import Statistics from "..";
import {
  getStatisticsSessions,
  getStatisticsSuccess,
  getActiveUsers,
  getAppDownloads,
  getActiveUsersList,
} from "../../../actions";
import "./styles.scss";
import { connect } from "react-redux";
import { history } from "../../../config/stores";
import Popup from "reactjs-popup";
import moment from "moment";
import Inputs from "../../Inputs";


const options = [
  {
    label: "Last 24 hours",
    value: moment().subtract(1, "days").startOf("day").toString(),
  },
  {
    label: "Last 7 days",
    value: moment().subtract(7, "days").startOf("day").toString(),
  },
  {
    label: "Last 31 days",
    value: moment().subtract(31, "days").startOf("day").toString(),
  },
  {
    label: "All",
    value: null,
  },
];
const Sessions = ({
  getStatisticsSuccess,
  getStatisticsSessions,
  getActiveUsers,
  getAppDownloads,
  getActiveUsersList,
  sessions,
  activeUsers,
  activeUsersList,
  appDownloads,
}) => {
  const [selected, setSelected] = useState("country");

  const [selPeriod, setSelPeriod] = useState({
    startDate: null,
    endDate: moment().toString(),
  });
  const [filtered,setFiltered] = useState(false)


  useEffect(() => {
    if (selPeriod.startDate !== "") {
      getAppDownloads({
        ...selPeriod,
      });
      setFiltered(true)
    }
  }, [selPeriod]);

  useEffect(() => {
    getStatisticsSuccess({ type: "sessions", loading: true });
    getStatisticsSessions();
    getActiveUsers();
    getAppDownloads();
    getActiveUsersList({
      page: "1",
      limit: "100",
      onSuccess: (res) => {
      },
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="statistics-sessions-container col">
      <div
        className="statistics-sessions-header row"
        style={{
          backgroundColor:
            activeUsers?.activeUsersCount === 0 ? "#C7C7C7" : "#00D297",
          borderColor:
            activeUsers?.activeUsersCount === 0 ? "#C7C7C7" : "#00D297",
          margin: "0px",
          marginTop: "10px",
          marginBottom: "20px",
          justifyContent: "flex-start",
        }}
      >
        <div className="indicator" />
        <h2 style={{ paddingBottom: "0px", marginLeft: "10px", width: "96%" }}>
          {activeUsers?.activeUsersCount === 0 ? (
            "THERE ARE NO ACTIVE USERS"
          ) : (
            <>
              <span>{activeUsers.activeUsersCount}</span>{" "}
              <b>ACTIVE USERS RIGHT NOW</b>
            </>
          )}
        </h2>
        {activeUsers?.activeUsersCount > 0 && (
          <div
            className="info-icon"
            onClick={() => history.push("/active-users")}
          />
        )}
      </div>
      <div className="statistics-sessions-content">
        <div
          className="statistics-sessions-section col"
          style={{ marginBottom: "20px", height: "55%" }}
        >
          {activeUsers?.activeUsersCount > 0 ? (
            <>
              <h4>LOCATION</h4>
              <div className="selector-container">
                <div
                  className={`select ${selected === "country" && "selected"}`}
                  onClick={() => setSelected("country")}
                >
                  BY COUNTRY
                </div>
                <div
                  className={`select ${selected === "city" && "selected"}`}
                  onClick={() => setSelected("city")}
                >
                  BY CITY
                </div>
              </div>
              {sessions.loading || activeUsers.loading ? (
                <Statistics.LoaderInline />
              ) : (
                <>
                  {selected === "country" ? (
                    <Statistics.Horizontal
                      data={activeUsers?.usersByCountry?.map((user) => {
                        return {
                          value: user.country,
                          label: user.count,
                        };
                      })}
                    />
                  ) : (
                    <Statistics.Horizontal
                      data={activeUsers?.usersByCity?.map((user) => {
                        return {
                          value: user.city,
                          label: user.count,
                        };
                      })}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <div className="empty-state-container">
              <div className="empty-state-text">
                Shh! Our users are asleep now
              </div>
              <div className="empty-state-image" />
            </div>
          )}
        </div>

        <div className="statistics-sessions-section col">
          <div style={{ display: "flex", height: "100%",alignItems:"center" }}>
            <div className="indicator green" />
            <h4 className="statistics-info-box-text" style={{width:"95%"}}>
              {Number(appDownloads?.downloads?.[0]?.totalDownloads) +
                Number(appDownloads?.downloads?.[1]?.totalDownloads)}{" "}
              APP DOWNLOADS
            </h4>
            <div style={{ display: "flex" }}>
            {" "}
            <p
              style={{
                fontSize: "12px",
                whiteSpace: "nowrap",
                margin: "0px 10px",
              }}
            >
              {options.filter((opt) => opt.value === selPeriod.startDate)
                .length > 0
                ? options.filter((opt) => opt.value === selPeriod.startDate)[0]
                    .label
                : "All"}
            </p>
            <Popup
              className="popup-header-options-container"
              trigger={<div className={`settings-icon`} />}
              position="bottom right"
            >
              {(close) => (
                <Inputs.RadioButtons
                  options={options}
                  column
                  value={selPeriod.startDate}
                  onChange={(period) => {
                    setSelPeriod({
                      ...selPeriod,
                      startDate: period?.value === null ? null : period,
                    });
                    close();
                  }}
                />
              )}
            </Popup>
          </div>
          </div>
          {sessions.loading || activeUsers.loading ? (
            <Statistics.LoaderInline />
          ) : (
            <>
              <Statistics.Pie
                colors={["#002C78", "#00D297"]}
                height
                data={[
                  {
                    label: appDownloads?.downloads?.[0]?.totalDownloads,
                    value:
                      appDownloads?.downloads?.[0]?.operationSystem?.toUpperCase(),
                  },
                  {
                    label: appDownloads?.downloads?.[1]?.totalDownloads,
                    value:
                      appDownloads?.downloads?.[1]?.operationSystem?.toUpperCase(),
                  },
                ]}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sessions: state.dashboard.sessions,
  activeUsers: state.dashboard.activeUsers,
  appDownloads: state.dashboard.appDownloads,
  activeUsersList: state.dashboard.activeUsersList,
});
const mapDispatchToProps = (dispatch) => ({
  getStatisticsSessions: (payload) => dispatch(getStatisticsSessions(payload)),
  getActiveUsers: (payload) => dispatch(getActiveUsers(payload)),
  getAppDownloads: (payload) => dispatch(getAppDownloads(payload)),
  getStatisticsSuccess: (payload) => dispatch(getStatisticsSuccess(payload)),
  getActiveUsersList: (payload) => dispatch(getActiveUsersList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sessions);
